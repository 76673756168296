import React, { useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../FullScreenLoading';

const SofinnPrivateRoute: React.FC<{
  element: React.ReactElement;
  publicRedirect?: string;
}> = (props) => {
  const { t } = useTranslation('COMMON');
  const location = useLocation();
  const navigate = useNavigate();
  const [{ sofinnUser, appLoaded, isSofinnAuthenticated, sofinnToken }, dispatchAuthChange] = useAuth();
  const { element: PrivateElement, publicRedirect = '/cati-b2b/login' } = props;

  const handleRedirect = useCallback(() => {
    if (appLoaded) {
      if (isSofinnAuthenticated && sofinnToken) {
        return PrivateElement;
      } else if (!sofinnUser?.customer && !sofinnToken) {
        dispatchAuthChange({ type: AuthActionType.LogoutSofinn });
        return <Navigate replace to={publicRedirect} />;
      }
    }

    if (((!isSofinnAuthenticated || !appLoaded) && sofinnToken) || !sofinnToken) {
      dispatchAuthChange({ type: AuthActionType.LogoutSofinn });
      return <Navigate replace to={publicRedirect} />;
    }

    return <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />;
  }, [sofinnUser, location.pathname]);

  useEffect(() => {
    if (sofinnUser?.isSingleUsePassword) {
      navigate('/cati-b2b/reset-temporary-password');
    }
  }, [sofinnUser, location.pathname]);

  return handleRedirect();
};

export default SofinnPrivateRoute;
