import { StrapiDataType } from '../../interfaces/ICommons';
import { IOrderAttributes } from '../orders';

export { default as create, type IOrderItemCreate, type IOrderItemCreateResult } from './create';

export interface IOrderItem {
  id: number;
  productId: number;
  productCode: string;
  productEan?: string;
  productClassId?: number;
  productClassName?: string;
  productFamilyId?: number;
  productFamilyName?: string;
  productManufacturerId?: number;
  productManufacturerName?: string;
  productManufacturerLogo?: string;
  productThumbnail?: string;
  productName?: string;
  productStandardDescription?: string;
  productUsageDescription?: string;
  productAssemblyDescription?: string;
  quantity: number;
  price: number;
  discount: number;
  taxes: number;
  shipping: number;
}

export interface IPurchasedProduct extends IOrderItem {
  orders: StrapiDataType<IOrderAttributes>[];
}

export enum PurchasesType {
  own = 'own',
  customer = 'customer',
}
