import { useTranslation } from 'react-i18next';
import { PrimaryButtonFull } from '../../../../components/Buttons';
import { InputPresentational } from '../../../../components/Form';
import { useFormContext } from 'react-hook-form';
import { formatFormErrorMessage } from '../../../../lib/utils/formatters';
import { classNames } from '../../../../lib/utils';
import IResetTemporaryPasswordForm from './interfaces/resetPasswordInAppForm';

interface Props {
  onSubmit: (formValues: IResetTemporaryPasswordForm) => void;
  isLoading: boolean;
  className?: string;
}

const ResetTemporaryPasswordForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('');
  const { onSubmit, isLoading, className } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IResetTemporaryPasswordForm>();

  return (
    <form className={classNames('w-full lg:w-1/2 xl:w-1/3', className)} onSubmit={handleSubmit(onSubmit)}>
      <InputPresentational
        label={t('COMMON:PASSWORD')}
        id="newPassword"
        type="password"
        autoComplete="new-password"
        {...register('password')}
        hasError={!!errors.password?.message}
        helperText={t(formatFormErrorMessage(errors.password?.message))}
      />
      <InputPresentational
        label={t('RESET:REPEAT_PASSWORD')}
        id="repeatPassword"
        type="password"
        autoComplete="old-password"
        {...register('repeatPassword')}
        hasError={!!errors.repeatPassword?.message}
        helperText={t(formatFormErrorMessage(errors.repeatPassword?.message))}
      />
      <div className="my-4">
        <PrimaryButtonFull type="submit" loading={isLoading} text={t('SECURITY:CONFIRM')} />
      </div>
    </form>
  );
};

export default ResetTemporaryPasswordForm;
