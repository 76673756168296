import axios, { AxiosResponse } from 'axios';
import { IAddress } from '../../interfaces';

const { REACT_APP_ADDRESSES_URL } = process.env;

interface ICreateAddressResponse {
  id: number;
  attributes: Omit<IAddress, 'id'>;
}

const create = async (token: string, address: IAddress) => {
  const response = await axios.post<{ data: ICreateAddressResponse }>(
    REACT_APP_ADDRESSES_URL!,
    { data: address },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
