import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { OutlineButton } from '../Buttons/OutlineButton';
import { IProductStock } from '../../lib/fetch/products';
import { getProductOrderedQuantities } from '../ProductsTable/utils';
import { useAuth } from '../../providers/AuthProvider';

interface Props {
  isOpen: boolean;
  onAddToCart: (_quantity: number) => void;
  toggleModal: () => void;
  requestedQuantity: number;
  productStock: IProductStock;
  cartCurrentQuantity: number;
}

const StockExceededModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('PRODUCTS');
  const { isOpen, onAddToCart, toggleModal, requestedQuantity, productStock, cartCurrentQuantity } = props;
  const [{ user }] = useAuth();

  const {
    availableRequestedProductQuantity,
    orderedRedQuantity,
    orderedYellowQuantity,
    showRedProductsOption,
    showGreenProductsOption,
    showYellowProductsOption,
    greenQuantity,
    yellowQuantity,
  } = useMemo(
    () => getProductOrderedQuantities({ productStock, user, requestedQuantity, cartCurrentQuantity }),
    [productStock, user, requestedQuantity],
  );

  const onConfirm = (_quantity: number) => {
    onAddToCart(_quantity);
    toggleModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="relative w-full lg:max-w-[700px] lg-max-lg p-6 bg-white rounded-lg m-2 shadow-lg transition-all"
      ariaHideApp={false}
      contentLabel={t('OUT_OF_STOCK_MSG')}
    >
      <div className="flex flex-row">
        <ExclamationTriangleIcon className="flex h-5 w-5 text-[#B7791F] m-1" />

        <h2 className="text-lg font-semibold text-left text-[#B7791F] mb-4">{t('OUT_OF_STOCK_MSG')}</h2>
      </div>

      <div className="text text-gray-600 mb-10">
        <p>{t('OUT_OF_STOCK_DESCRIPTION')}</p>
        <ul className="list-disc ml-4">
          <li
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: t('REQUESTED_QUANTITY', { requestedQuantity }) }}
          />
          <li
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: t('AVAILABLE_QUANTITY', { availableQuantity: greenQuantity }) }}
          />
          {showYellowProductsOption && (
            <li
              className="text-sm"
              dangerouslySetInnerHTML={{
                __html: `${t('AVAILABLE_QUANTITY_OTHER_WAREHOUSES', { availableQuantityOtherWarehouses: yellowQuantity })}*`,
              }}
            ></li>
          )}
          {showRedProductsOption && (
            <li
              className="text-sm"
              dangerouslySetInnerHTML={{
                __html: `${t('NOT_AVAILABLE_QUANTITY', { redQuantity: orderedRedQuantity })}**`,
              }}
            />
          )}
        </ul>

        <div className="my-2">
          {showYellowProductsOption && (
            <p
              className="italic"
              dangerouslySetInnerHTML={{
                __html: `*${t('OTHER_WARHOUSES_STOCK_MSG_INFO', { orderedYellowQuantity })}`,
              }}
            />
          )}
          {showRedProductsOption && (
            <p
              className="italic"
              dangerouslySetInnerHTML={{
                __html: `**${t('NOT_AVAILABLE_PRODUCTS_MSG_INFO', { redQuantity: orderedRedQuantity })}`,
              }}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        {showGreenProductsOption && (
          <OutlineButton
            text={t('ADD_ONLY_AVAILABLE')}
            onClick={() => onConfirm(greenQuantity)}
            color="green"
            disabled={false}
            loading={false}
          />
        )}
        {showYellowProductsOption && (
          <OutlineButton
            text={t('ADD_FROM_OTHER_WAREHOUSES')}
            color="green"
            onClick={() => onConfirm(availableRequestedProductQuantity)}
            disabled={false}
            loading={false}
          />
        )}
        {showRedProductsOption && (
          <OutlineButton
            onClick={() => {
              onConfirm(requestedQuantity);
            }}
            text={t('ADD_ALL')}
            color="green"
            disabled={false}
            loading={false}
          />
        )}

        <button
          onClick={toggleModal}
          className="p-2 w-full rounded-md bg-gray-100 text-gray-800 text-sm  hover:bg-gray-200 uppercase  "
        >
          {t('COMMON:CANCEL')}
        </button>
      </div>
    </ReactModal>
  );
};

export default StockExceededModal;
