import AgentHeader from '../../components/Header/AgentHeader';
import AgentImpersonationHeader from '../../components/Header/AgentImpersonationHeader';
import CustomersList from './Components/CustomersList';

const Agent = () => {
  return (
    <div className="min-h-screen">
      <AgentImpersonationHeader />
      <AgentHeader />
      <CustomersList />
    </div>
  );
};

export default Agent;
