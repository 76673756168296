type Params = {
  requestedQuantity: number;
  totalAvailableQuantity: number;
};

const getAvailableRequestedProductQuantity = (params: Params): number => {
  const { requestedQuantity, totalAvailableQuantity } = params;
  return Math.min(requestedQuantity, totalAvailableQuantity);
};

export default getAvailableRequestedProductQuantity;
