import axios from 'axios';
import { ISofinnUpdateProfileForm } from '../../../interfaces/ISofinn';

const instanceAxios = axios.create();
export interface ILoginResponse {}

const { REACT_APP_RESET_PASSWORD_SOFINN_URL } = process.env;

const updateProfileSofinn = async ({
  data,
  token,
}: {
  data: Omit<ISofinnUpdateProfileForm, 'confirmPassword'>;
  token: string | null;
}) => {
  return await instanceAxios.post<ILoginResponse>(REACT_APP_RESET_PASSWORD_SOFINN_URL!, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default updateProfileSofinn;
