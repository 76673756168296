import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { classNames } from '../../../lib/utils';

const AgentProfileMenu = () => {
  const { t } = useTranslation('MENU');
  const [{ user }, dispatchAuthChange] = useAuth();
  const { name = '', surname = '' } = user || {};
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="group block flex-shrink-0">
        <div className="flex items-center">
          <div className="hidden text-right lg:inline">
            <p className="text-xs font-medium text-gray-400 group-hover:text-gray-500">Hello,</p>
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
              {name} {surname}
            </p>
          </div>
          <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 lg:hidden">
            <span className="font-medium leading-none text-white">TW</span>
          </span>
          <ChevronDownIcon className="-mr-1 ml-2 hidden h-5 w-5 lg:inline" aria-hidden="true" />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute max-lg:left-0 lg:right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() =>
                  dispatchAuthChange({
                    type: AuthActionType.Logout,
                  })
                }
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
              >
                <ArrowRightStartOnRectangleIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {t('SIGN_OUT')}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AgentProfileMenu;
