import axios from 'axios';
import IUser from '../../interfaces/IUser';

const { REACT_APP_RVA_UNSET_AGENT_URL } = process.env;

const unsetAgent = async (token: string) => {
  const response = await axios.delete<IUser>(REACT_APP_RVA_UNSET_AGENT_URL!, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default unsetAgent;
