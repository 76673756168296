import moment from 'moment';

const FooterSofinn: React.FC = () => {
  return (
    <footer className="flex w-full bg-[#cdcdcd] px-[20px] py-[10px]" id="footer">
      <div className="flex justify content-between w-full">
        <div className="flex w-1/3">
          {/* <a href="https://www.sofinn.it" target="_blank" className="flex flex-col" rel="noreferrer">
            <div className="flex flex-row">
              <img src="/images/sofinn/sofinn-logo.png" className="h-[30px]" alt="logo sofinn" />
              <img
                src="/images/sofinn/sofinn-b2b-logo.png"
                className="h-[27px] mt-[3px] ml-2"
                alt="logo e-commerce sofinn"
              />
            </div>
            <p className="flex text-[#710d28] text-[13px]">Powered by Sofinn Italia</p>
          </a> */}
        </div>
        <div className="flex w-1/3 flex-col">
          <h5 className="text-center text-[14px]">03748340019</h5>
          <h6 className="text-center text-[12px]">BoA Version 3.0</h6>
        </div>
        <div className="flex w-1/3 flex-col text-[12px]">
          <h6 className=" text-end">{moment().locale('it').format('ddd D MMMM, YYYY')}</h6>
          <h6 className="text-end">3792722 visite da sab 31 dicembre, 2016</h6>
        </div>
      </div>
    </footer>
  );
};

export default FooterSofinn;
