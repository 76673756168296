import { Link } from 'react-router-dom';
import { IQuote } from '../../lib/fetch/quotes';
import moment from 'moment';
import { QuoteStatus } from '../../lib/enums';
import { useTranslation } from 'react-i18next';

interface IProductsList {
  productsList: IQuote[];
}

export const ProductsList = (props: IProductsList) => {
  const { productsList } = props;
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      {productsList.map((item) => (
        <div key={item.id} className="flex flex-col border border-gray-200 rounded-2xl overflow-hidden">
          <div className="bg-gray-100 w-full justify-center flex flex-col items-center border-b border-gray-200 p-4">
            <p className="text-lg font-semibold uppercase text-gray-800">{item.attributes.name}</p>
            <p className="text-sm text-gray-500">{item.attributes.description}</p>
          </div>
          <div className="w-full justify-around p-4 flex flex-row">
            <div>
              <p className="text-sm text-gray-500 uppercase">{t('QUOTE:CREATED_ON')}</p>
              <p className=" text-gray-600 font-medium uppercase">
                {moment(item.attributes.createdAt).format('DD MMM YYYY HH:mm')}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500 uppercase">{t('QUOTE:EDITED_ON')}</p>
              <p className=" text-gray-600 font-medium uppercase">
                {moment(item.attributes.updatedAt).format('DD MMM YYYY HH:mm')}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500 uppercase">{t('QUOTE:PIECES_NUMBER')}</p>

              <span className="  font-medium bg-red-100 text-xs px-3 py-1 rounded-full text-red-500">
                {item.attributes.items?.length}
              </span>
            </div>
            <div>
              <p className="text-sm text-gray-500 uppercase">{t('QUOTE:STATUS')}</p>
              {(() => {
                switch (item.attributes.status) {
                  case QuoteStatus.Completed:
                    return <span className="font-semibold text-green-500"> {t('QUOTE:COMPLETED')} </span>;

                  case QuoteStatus.Pending:
                    return <span className="font-semibold text-yellow-600"> {t('QUOTE:PENDING')} </span>;

                  case QuoteStatus.Archived:
                    return <span className="font-semibold text-gray-700"> {t('QUOTE:ARCHIVED')} </span>;
                  default:
                    return <span className="font-semibold text-gray-600"> - </span>;
                }
              })()}
            </div>
          </div>
          <div className="w-full justify-end p-4 border-t border-gray-200 flex flex-row">
            <Link to={`/products-list/${item.id}`} className="pl-2 text-left uppercase text-red-600 text-sm">
              {t('QUOTE:SEE_LIST')}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
