import { StrapiSingleEntityResponse } from '../../interfaces/ICommons';
import { IQuote } from '.';
import axios from 'axios';

const axiosInstance = axios.create();
const { REACT_APP_BACKEND_URL } = process.env;

const deleteQuote = async (token: string, id: string | number) => {
  const response = await axiosInstance.delete<StrapiSingleEntityResponse<IQuote>>(
    `${REACT_APP_BACKEND_URL!}/quotes/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export default deleteQuote;
