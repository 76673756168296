import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';

interface IPiecesBadge {
  quantity?: number;
}

const PiecesBadge: FC<IPiecesBadge> = (props) => {
  const { quantity = 0 } = props;
  const [{ user }] = useAuth();

  return quantity ? (
    <div>
      <div className="inline-flex items-center">
        <div className="mr-2 h-5 w-5 rounded-full bg-green-4_5to1 flex items-center justify-center text-white text-xs">
          V
        </div>
        {user!.displayQuantity ? (
          <Trans i18nKey={'PRODUCTS:PIECES'} count={quantity}>
            {' '}
            <span className="mr-1 text-lg font-medium text-green-4_5to1">{{ quantity } as any}</span>{' '}
            <span className="text-sm font-medium text-green-4_5to1">pieces</span>
          </Trans>
        ) : (
          <span className="text-sm font-medium text-green-4_5to1">disponibile</span>
        )}
      </div>
    </div>
  ) : null;
};

export default PiecesBadge;
