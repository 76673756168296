import { EyeIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CartsFilters from './CartsFilters';
import axios from 'axios';
import ICart from '../../../lib/interfaces/ICart';

const { REACT_APP_RVA_GET_CARTS_URL } = process.env;

const CartList = () => {
  const [data, setData] = useState<ICart[]>([]);
  const [filters, setFilters] = useState({
    name: '',
    approved: '',
  });
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const [{ token }] = useAuth();

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchData();
    }, 1000);

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  const fetchData = async () => {
    try {
      const response = await axios.get(REACT_APP_RVA_GET_CARTS_URL!, {
        params: filters,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data ?? []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  const goToCart = (cart: ICart) => {
    navigate('/agent-cart', { state: { cart } });
  };

  return (
    <div className="overflow-x-auto m-8">
      <CartsFilters filters={filters} onFilterChange={handleFilterChange} />
      <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
        <thead className="bg-gray-50 text-gray-500">
          <tr className="rounded">
            <th className="px-4 py-2">COMPANY</th>
            <th className="px-4 py-2">AGENT</th>
            <th className="px-4 py-2">SHIP TO</th>
            <th className="px-4 py-2">STATE</th>
            <th className="px-4 py-2">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="border">
              <td className="px-4 py-2 text-center">
                <p>{item.customer?.name}</p>
              </td>
              <td className="px-4 py-2 text-sm text-gray-400 text-center">
                <p>{item.agent?.name}</p>
              </td>
              <td className="px-4 py-2 text-sm text-gray-400">
                <div className="flex justify-center my-2">
                  {item.shipping_address?.addressFirstLine ||
                  item.shipping_address?.postalCode ||
                  item.shipping_address?.municipality ? (
                    <MapPinIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                  ) : (
                    <></>
                  )}
                  {`${item.shipping_address?.addressFirstLine ?? ' '}, ${item.shipping_address?.postalCode ?? ''}, ${item.shipping_address?.municipality ?? ''}`.replaceAll(
                    ' ,',
                    '',
                  )}
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-center">
                {item.approved === true ? (
                  <>
                    <span className="text-green-500 text-2xl mr-2">●</span>
                    <span className="text-green-500">Approvato</span>
                  </>
                ) : item.approved === false ? (
                  <>
                    <span className="text-red-500 text-2xl mr-2">●</span>
                    <span className="text-red-500">Rifiutato</span>
                  </>
                ) : (
                  <>
                    <span className="text-yellow-500 text-2xl mr-2">●</span>
                    <span className="text-yellow-500">In attesa di approvazione</span>
                  </>
                )}
              </td>
              <td className="px-4 py-2 flex justify-center">
                <button className="bg-gray-200 text-black rounded-md p-2 mr-2" onClick={() => goToCart(item)}>
                  <EyeIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CartList;
