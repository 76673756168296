import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Buttons';
import Lottie from 'react-lottie-player';
import errorLottie from '../../lotties/error.json';

interface IOrderFail {
  onRetryPayment: () => void;
}

const OrderFail = (props: IOrderFail) => {
  const { onRetryPayment = () => {} } = props;
  const { t } = useTranslation('SHOPPING_CART');

  return (
    <div className="w-full bg-gray-50 px-12 pt-10 pb-20">
      <div className="flex w-full flex-col items-center justify-center">
        <Lottie loop animationData={errorLottie} play speed={0.2} style={{ width: 300, height: 300 }} />

        <h1 className="mb-8 text-center text-4xl font-bold text-red-700">{t('PAYMENT_FAIL_TITLE')}</h1>

        <p
          className="mb-8 text-center text-sm text-gray-600"
          dangerouslySetInnerHTML={{ __html: t('SHOPPING_CART:PAYMENT_FAIL_DESCRIPTION') }}
        />
        <PrimaryButton text={t('TRY_AGAIN')} onClick={onRetryPayment} />
      </div>
    </div>
  );
};

export default OrderFail;
