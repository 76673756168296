import React, { createContext, useContext, useReducer } from 'react';

export enum AlertType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export interface AlertAction {
  message?: string;
  open?: boolean;
  type?: AlertType;
}

interface AlertState {
  message: string;
  open: boolean;
  type: AlertType;
}

const GENERIC_ERROR_MESSAGE = 'COMMON:GENERIC_ERROR';
const CLOSE_TIMEOUT = 5 * 1000; // 5 sec
const RESET_TIMEOUT = 1000; // must be longer than closing transition

const INITIAL_STATE = Object.freeze({
  message: GENERIC_ERROR_MESSAGE,
  open: false,
  type: AlertType.Error,
});

const reducer = (state: AlertState, action: AlertAction = {}) => {
  const { message: currentMessage = GENERIC_ERROR_MESSAGE, type: currentType = AlertType.Error } = state;
  const { open = false, message: newMessage, type = currentType } = action;
  return {
    open,
    message: newMessage || currentMessage,
    type,
  };
};

const reset = () => ({ ...INITIAL_STATE });

const closeTimeouts: NodeJS.Timeout[] = [];

const delayedDispatch =
  (actualDispatch = (_?: AlertAction) => {}) =>
  (action: AlertAction) => {
    actualDispatch(action);
    const { open } = action;
    if (open) {
      // Clear previous timeout, if existing (see "else")
      if (closeTimeouts[2]) {
        clearTimeout(closeTimeouts[2]);
        delete closeTimeouts[2];
      }
      // If opening, close after a timeout
      closeTimeouts[0] && clearTimeout(closeTimeouts[0]);
      closeTimeouts[0] = setTimeout(() => actualDispatch(), CLOSE_TIMEOUT);
      // When closing, reset the error message after a longer timeout to avoid a glitch
      closeTimeouts[1] && clearTimeout(closeTimeouts[1]);
      closeTimeouts[1] = setTimeout(() => actualDispatch({ ...INITIAL_STATE }), CLOSE_TIMEOUT + RESET_TIMEOUT);
    }
    // If closing, reset the error message after a timeout to avoid a glitch
    else {
      actualDispatch();
      closeTimeouts[2] = setTimeout(() => actualDispatch({ ...INITIAL_STATE }), RESET_TIMEOUT);
    }
  };

const ErrorAlertContext = createContext<[AlertState, (_: AlertAction) => void]>([
  { ...INITIAL_STATE },
  delayedDispatch(),
]);

const AlertProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE }, reset);

  return <ErrorAlertContext.Provider value={[state, delayedDispatch(dispatch)]}>{children}</ErrorAlertContext.Provider>;
};

const useAlert = () => useContext(ErrorAlertContext);

export { AlertProvider, useAlert };
