import { useTranslation } from 'react-i18next';

export default function UnavailableBadge() {
  const { t } = useTranslation('PRODUCTS');

  return (
    <div>
      <span className="inline-flex items-center text-sm font-medium text-red-700">
        <div className="mr-2 h-5 w-5 rounded-full bg-red-700 flex items-center justify-center text-white text-xs">
          R
        </div>
        {t('UNAVAILABLE')}
      </span>
    </div>
  );
}
