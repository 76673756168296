type Params = {
  requestedQuantity: number;
  totalAvailableQuantity: number;
};

const getOrderedRedQuantity = (params: Params): number => {
  const { requestedQuantity, totalAvailableQuantity } = params;
  return Math.max(0, requestedQuantity - totalAvailableQuantity);
};

export default getOrderedRedQuantity;
