import { IProduct, IPromotion } from '../fetch/products';
import { IUser } from '../interfaces';

const MAX_PRODUCT_QUANTITY = 99;

const quantityInputProps = (product: IProduct, user?: IUser) => {
  const { attributes } = product;
  const { active, promotions, sales_package_sizes, price_list } = attributes;
  const { canBuyOutOfStock: canBuyOutOfStockUser = false, customer = null } = user || {};

  const canBuyOutOfStockCustomer = customer?.canBuyOutOfStock ?? false;

  const quantity =
    attributes.quantity ||
    ('attributes' in attributes ? (attributes as unknown as IProduct).attributes.quantity : undefined); // For some reason the attributes in the product come in a strange nested way from backend in /me

  const isUnavailable = !active;

  const allWarehousesQuantity = (quantity?.quantityDefaultWarehouse || 0) + (quantity?.quantityOtherWarehouses || 0);

  const isStockAvailable = allWarehousesQuantity > 0;

  const canBuyOutOfStock = canBuyOutOfStockUser && canBuyOutOfStockCustomer;
  const canBuy = !isUnavailable && (isStockAvailable || canBuyOutOfStock) && (price_list ?? 0) > 0;

  const promotion = promotions?.data?.[0];
  const package_size = sales_package_sizes || 1;
  const promotion_size = promotion ? Math.ceil(getPromotionMin(promotion) / package_size) * package_size : package_size; //Mi assicuro che il minimo sia sempre il multiplo del package size

  const maxValue = canBuy ? (canBuyOutOfStock ? MAX_PRODUCT_QUANTITY : allWarehousesQuantity) : 0;

  return {
    max: canBuy ? maxValue : 0,
    min: canBuy ? Math.min(package_size, allWarehousesQuantity) : 0,
    defaultValue: canBuy ? Math.min(promotion_size, allWarehousesQuantity) : 0,
    disabled: !canBuy,
    step: Math.min(package_size, allWarehousesQuantity),
  };
};

const getPromotionMin = (promotion: IPromotion) => {
  return promotion.extraScontoItem?.[0]?.minimoDi || promotion.promoTipoSuTipo?.[0]?.ogni || 1;
};

export default quantityInputProps;
