import { ElementType } from 'react';
import { Link } from 'react-router-dom';

interface IconButtonItem {
  icon: ElementType;
  label: string;
  link: string;
}

interface IconButtonGroupProps {
  items: IconButtonItem[];
}

const IconButtonGroup: React.FC<IconButtonGroupProps> = ({ items }) => {
  return (
    <div className="px-4 py-2 rounded-lg md:px-12 md:py-4">
      <div className="flex gap-4 md:gap-8 items-center justify-center flex-wrap">
        {items.map((item, index) => (
          <Link key={index} to={item.link} className="text-center w-16">
            <div className="bg-red-500 rounded-full p-3 text-white hover:bg-red-600 transition-colors w-12 h-12 flex items-center justify-center mx-auto">
              <item.icon className="w-6 h-6" />
            </div>
            <span className="text-xs mt-1 block md:block hidden">{item.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default IconButtonGroup;
