import moment from 'moment';
import { StrapiDataType } from '../../lib/interfaces/ICommons';
import { IPurchasedProduct } from '../../lib/fetch/orderItems';

interface Props {
  product: StrapiDataType<IPurchasedProduct>;
}

/**
 * Restituisce il componente react per la visualizzazione di un elemento nel riepilogo dei prodotti acquistati
 * @param props
 * @returns
 */
const PurchasedProductItem = (props: Props) => {
  const { product } = props;
  const lastOrder = product.attributes.orders[0];

  return (
    <div className="border flex border-b-300 justify-between">
      <p className="ml-4 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold text-gray-600">
        {product.attributes.productCode}
      </p>
      <p className="font-regular text-end mx-4 text-xs text-gray-400">
        {moment(lastOrder.attributes.date).format('L [-] HH:mm')}
      </p>
    </div>
  );
};

export default PurchasedProductItem;
