import { useFormContext } from 'react-hook-form';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import { ISofinnUpdateProfileForm } from '../../../lib/interfaces/ISofinn';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';
import { useAuth } from '../../../providers/AuthProvider';
import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import getTranslatedMessage from '../../../lib/utils/getTranslatedMessage';

interface Props {
  onUpdateSofinnProfile: (values: ISofinnUpdateProfileForm) => void;
  onRedirect: () => void;
  isError: boolean;
}

const SofinnUpdateProfileForm: React.FC<Props> = (props) => {
  const [{ sofinnUser }] = useAuth();
  const { onUpdateSofinnProfile, onRedirect, isError } = props;
  const { t } = useTranslation('UPDATE_SOFINN_PROFILE');

  const canUserSkipPwChangeProcedure = useMemo(() => moment(sofinnUser?.passwordExpiryDate).isAfter(moment.now()), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<ISofinnUpdateProfileForm, Omit<ISofinnUpdateProfileForm, 'confirmPassword'>>();

  return (
    <form
      onSubmit={handleSubmit(onUpdateSofinnProfile)}
      className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]"
    >
      <h4 className="uppercase">{t('CHANGE_PASSWORD')}</h4>
      <div className="grid gap-2 grid-cols-1 mt-4">
        <span className="text-[14px]">{t('PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('PASSWORD')}
          type="password"
          {...register('password')}
          hasError={!!errors.password}
          helperText={getTranslatedMessage({ t, message: errors.password?.message })}
        />
        <span className="text-[14px]">{t('REPEAT_NEW_PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('REPEAT_NEW_PASSWORD')}
          type="password"
          {...register('confirmPassword')}
          hasError={!!errors.confirmPassword}
          helperText={getTranslatedMessage({ t, message: errors.confirmPassword?.message })}
        />
      </div>
      {isError && (
        <div className="mt-2">
          <SofinnErrorBox errorMessage={t('ERROR_UPDATE_PROFILE')} className="pt-2" />
        </div>
      )}

      <div className="flex flex-row justify-between">
        <SofinnButton
          text={t('CANCEL')}
          type="button"
          onClick={() => onRedirect()}
          disabled={!canUserSkipPwChangeProcedure}
          className="max-w-min px-[20px] text-gray-800 hover:bg-[#dc2626]/75 bg-[#dc2626]"
        />
        <SofinnButton text={t('SEND')} className="max-w-min px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75" />
      </div>
    </form>
  );
};

export default SofinnUpdateProfileForm;
