import { useTranslation } from 'react-i18next';
import ResetTemporaryPasswordForm from './ResetTemporaryPasswordForm';
import IResetTemporaryPasswordForm from './interfaces/resetPasswordInAppForm';

interface Props {
  onSubmit: (formValues: IResetTemporaryPasswordForm) => void;
  isLoading: boolean;
}

const ResetTemporaryPasswordPresentational: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-center h-screen">
      <div className="my-auto flex lg:w-1/2 xl:w-1/3 flex-col justify-center">
        <h2 className="flex text-center text-2xl mb-10 mx-auto font-bold text-gray-800">
          {t('SECURITY:CHANGE_PASSWORD')}
        </h2>
        <ResetTemporaryPasswordForm className="w-full lg:w-full xl:w-full" {...props} />
      </div>
    </div>
  );
};

export default ResetTemporaryPasswordPresentational;
