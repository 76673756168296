import { useEffect, useReducer } from 'react';
import { RedirectAction } from '../lib/interfaces/IRedirect';
import { useAuth } from '../providers/AuthProvider';
import { IUser } from '../lib/interfaces';
import { useNavigate } from 'react-router-dom';

/**
 * questa funziona ritorna lo stto che gestisce il redirect dopo il reset password
 * I checks sono completati quando l'utente non ha più una password provvisoria
 *
 * @param state
 * @returns IRedirectAction
 */
const handleTemporaryPasswordReseted = (state: IRedirectAction): IRedirectState => {
  const { user } = state;
  let checksCompleted = false;
  let redirectUrl = '/';

  if (!user?.isSingleUsePassword) checksCompleted = true;

  return { message: 'TEMPORARY_PASSWORD_RESETED_MSG', checksCompleted, redirectUrl };
};

const redirectReducer = (state: IRedirectState, action: IRedirectAction): IRedirectState => {
  switch (action.type) {
    case RedirectAction.TEMPORARY_PASSWORD_RESETED:
      return handleTemporaryPasswordReseted(action);
    default:
      return state;
  }
};

interface IRedirectState {
  /**
   * - i messaggi sono le chiavi di traduzione nella cartela `/translations/[locale]` sotto la chiave "REDIRECT"
   */
  message: string;
  redirectUrl: string;
  checksCompleted: boolean;
}

interface IRedirectAction {
  type: RedirectAction | null;
  user: IUser | null;
}

const initialState: IRedirectState = {
  redirectUrl: '/',
  message: 'DEFAULT_MESSAGE',
  checksCompleted: false,
};

const useRedirect = (redirectAction: RedirectAction | null) => {
  const navigate = useNavigate();
  const [{ user }] = useAuth();
  const [state, dispatch] = useReducer(redirectReducer, initialState);

  useEffect(() => {
    if (user) {
      dispatch({ type: redirectAction, user });
    }
  }, [redirectAction, user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!state.checksCompleted) {
        //se i checks non vengono soddisfatti entro 10 secondi si fa un redirect alla route di default
        navigate(state.redirectUrl);
      }
    }, 10000);

    if (state.checksCompleted) {
      clearTimeout(timer);
      navigate(state.redirectUrl);
    }
  }, [state]);

  return { message: state.message };
};

export default useRedirect;
