import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../../../../lib/constants/regexp';

const resetPasswordInAppFormValidator = yup
  .object({
    oldPassword: yup.string().required('COMMON:REQUIRED'),
    newPassword: yup.string().required('COMMON:REQUIRED').matches(PASSWORD_REGEXP, 'SECURITY:WEAK_PASSWORD'),
    repeatNewPassword: yup.string().oneOf([yup.ref('newPassword'), undefined], 'SECURITY:PASSWORDS_MUST_COINCIDE'),
  })
  .required();

export default resetPasswordInAppFormValidator;
