import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDetailPresentational from './ProductDetailPresentational';
import { useAuth } from '../../providers/AuthProvider';
import { getCrossReferences, getOne as getProduct, IProduct } from '../../lib/fetch/products';
import i18n from '../../i18n';

const ProductDetail = () => {
  const [{ token }] = useAuth();
  const navigation = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct>();
  const [crossReferences, setCrossReferences] = useState<IProduct[]>([]);
  const [loadingCrossReferences, setLoadingCrossReferences] = useState(false);

  const lang = i18n.languages[0];

  const fetchCrossReferences = async () => {
    setLoadingCrossReferences(true);
    const { error, data: responseData } = await getCrossReferences(token!, product!.id, lang);
    setLoadingCrossReferences(false);
    if (error || !responseData?.data) {
      setCrossReferences([]);
    }
    setCrossReferences(responseData.data);
  };

  useEffect(() => {
    const productId = +id!;
    productId &&
      (async () => {
        setLoading(true);
        const {
          data: { data },
          error,
        } = await getProduct(token!, productId, lang);
        if (error || !data) {
          setLoading(false);
          navigation('/error', { replace: true });
        }
        setProduct(data);
        setLoading(false);
      })();
  }, [id]);

  useEffect(() => {
    if (!product) return;
    fetchCrossReferences();
  }, [product]);

  return (
    <>
      <ProductDetailPresentational
        loading={loading}
        product={product}
        crossReferences={crossReferences}
        loadingCrossReferences={loadingCrossReferences}
      />
    </>
  );
};

export default ProductDetail;
