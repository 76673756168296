import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../../lib/constants/regexp';

const sofinnResetPasswordFormValidator: any = yup
  .object({
    password: yup
      .string()
      .required('REQUIRED')
      .min(8, 'PASSWORD_LENGTH_ERROR')
      .matches(PASSWORD_REGEXP, 'INVALID_PASSWORD_ERROR'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'PASSWORDS_DONT_MATCH')
      .required('REQUIRED'),
  })
  .required();

export default sofinnResetPasswordFormValidator;
