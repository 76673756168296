import axios from 'axios';
import IUser from '../../interfaces/IUser';

const { REACT_APP_RVA_SET_AGENT_URL } = process.env;

const setAgent = async (token: string, agentId: number) => {
  const response = await axios.post<IUser>(
    REACT_APP_RVA_SET_AGENT_URL!,
    {
      agentId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default setAgent;
