import React, { ChangeEvent } from 'react';

interface CartsFiltersProps {
  filters: {
    name: string;
    approved: string;
  };
  onFilterChange: (filterName: string, value: string) => void;
}

const CartsFilters: React.FC<CartsFiltersProps> = ({ filters, onFilterChange }) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onFilterChange(e.target.name, e.target.value);
  };

  const filterField = (name: any, value: any, placeHolder: any) => (
    <>
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeHolder}
        className="bg-gray-100 text-black rounded-lg py-1 px-3 border-none"
      />
    </>
  );

  return (
    <div className="flex items-center my-4 justify-end">
      <span className="text-gray-500 mr-2">Filter by:</span>
      <div className="flex space-x-2">
        {filterField('name', filters.name, 'Business name')}
        <div className="bg-gray-100 text-black rounded-lg px-1 flex items-center">
          <select
            name="approved"
            value={filters.approved}
            onChange={handleInputChange}
            className="bg-transparent outline-none border-none"
          >
            <option value="">State</option>
            <option value="true">Approved</option>
            <option value="false">Disapproved</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CartsFilters;
