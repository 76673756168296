import en from './en.json';
import fr from './fr.json';
import it from './it.json';

const Translations = {
  en,
  fr,
  it,
};

export default Translations;
