import ImportantMessages from '../ImportantMessages';
import NewsCollapse from '../News/NewsCollapse';
import LinksCollapse from '../Links/LinksCollapse';
// import { IFrameBannerSearchSide } from '../IFrameBanner/SearchSide';
import { IFrameBannerProfileSide } from '../IFrameBanner/ProfileSide';
import { CatalogueCarrozzeriaButton } from '../CatalogueButton/CatalogueCarrozzeriaButton';
import { CatalogueMotoriniButton } from '../CatalogueButton/CatalogueMotoriniButton';
import PurchasedProductsCollapse from '../PurchasedProducts/PurchasedProductsCollapse';

export const Sidebar = () => {
  return (
    <div className="bg-white lg:w-64 lg:flex-shrink-0 xl:w-72">
      <div className="relative h-full pl-4 pr-4 pt-6 pb-0 sm:pl-4 lg:pb-6 lg:pl-0 lg:pr-0">
        <div className="h-full lg:w-64 xl:w-72">
          <ImportantMessages />
          <CatalogueCarrozzeriaButton />
          <CatalogueMotoriniButton />
          {/* TODO: handle important messages */}
          {/* TODO: handle chronology */}
          <NewsCollapse />
          {/* <IFrameBannerSearchSide /> */}
          <PurchasedProductsCollapse />
          <IFrameBannerProfileSide />
          <LinksCollapse />
        </div>
      </div>
    </div>
  );
};
