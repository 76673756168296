import { classNames } from '../../lib/utils';

interface Props {
  errorMessage?: string;
  className?: string;
}

const SofinnErrorBox: React.FC<Props> = (props) => {
  const { errorMessage, className } = props;
  return (
    <tr className="flex w-full border-[#ebccd1] border-r border-b  ">
      <td
        className={classNames(
          'flex w-full text-[12px] py-[2px] p-[3px] bg-[#f2dede] rounded-[4px] text-center justify-center',
          className,
        )}
      >
        <span className="text-[#ff0000] ">
          <b>ERROR:</b>
        </span>
        <span className="text-[#a94442] ml-1">{errorMessage}</span>
      </td>
    </tr>
  );
};

export default SofinnErrorBox;
