import { useFormContext } from 'react-hook-form';
import SubtotalPresentational from '../Subtotal/SubtotalPresentational';
import { IQuote } from '../../lib/fetch/quotes';
import { useEffect, useState } from 'react';

export const Summary = () => {
  const { watch } = useFormContext();

  const items = watch('items');

  const calculateTotal = (items: IQuote['attributes']['items']) => {
    return items?.reduce((total, item) => {
      const price = item.product.data.attributes.price_list;
      const quantity = item.quantity;

      if (price && quantity) {
        return total + price * quantity;
      }

      return total;
    }, 0);
  };
  const calculateTotalNet = (items: IQuote['attributes']['items']) => {
    return items?.reduce((total, item) => {
      const price = item.product.data.attributes.price_net;
      const quantity = item.quantity;

      if (price && quantity) {
        return total + price * quantity;
      }

      return total;
    }, 0);
  };

  const [totals, setTotals] = useState({
    subTotal: 0,
    subTotalNet: 0,
    total: 0,
    totalDiscount: 0,
    totalTaxes: 0,
    totalShipping: 0,
  });

  useEffect(() => {
    setTotals({
      ...totals,
      subTotal: calculateTotal(items) || 0,
      subTotalNet: calculateTotalNet(items) || 0,
      total: calculateTotalNet(items) || 0,
    });
  }, [JSON.stringify(items)]);

  return <SubtotalPresentational shippingAddress={null} totals={totals} />;
};
