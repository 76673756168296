import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from '../../lib/utils';

interface IPagination {
  currentItems: any[];
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

const Pagination: FC<IPagination> = (props) => {
  const {
    currentItems = [],
    onPageChange = () => {},
    // onPageSizeChange = () => {},
    page = 1,
    pageCount = 1,
    pageSize = 0,
    total = 0,
  } = props;
  const { t } = useTranslation('PAGINATION');
  const from = pageSize * (page - 1) + 1;
  const to = from + currentItems.length - 1;
  const hasNext = page < pageCount;
  const hasPrevious = page > 1;

  return (
    <nav
      className="mt-6 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-600">
          <Trans i18nKey="PAGINATION:SHOWING_FROM_TO_OF">
            Showing
            <span className="font-medium">{{ from } as any}</span>
            to
            <span className="font-medium">{{ to } as any}</span>
            of
            <span className="font-medium">{{ total } as any}</span>
            results
          </Trans>
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          type="button"
          disabled={!hasPrevious}
          onClick={() => onPageChange(page - 1)}
          className={classNames(
            'relative inline-flex items-center rounded-md border border-gray-100 bg-gray-100 px-4 py-2 text-sm font-medium',
            hasPrevious ? 'text-gray-600 hover:bg-gray-50' : 'text-gray-300',
          )}
        >
          {t('COMMON:PREV')}
        </button>
        <button
          type="button"
          disabled={!hasNext}
          onClick={() => onPageChange(page + 1)}
          className={classNames(
            'relative ml-3 inline-flex items-center rounded-md border border-gray-100 bg-gray-100 px-4 py-2 text-sm font-medium',
            hasNext ? 'text-gray-600 hover:bg-gray-50' : 'text-gray-300',
          )}
        >
          {t('COMMON:NEXT')}
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
