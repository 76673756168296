import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import unsetCustomer from '../../../lib/fetch/agent/unsetCustomer';

const CustomerImpersonationHeader = () => {
  const navigate = useNavigate();
  const [{ token, user }, dispatchAuthChange] = useAuth();

  const nome = `${user?.name} ${user?.surname}`.trim();
  const cliente = user?.customer?.name ?? '';

  const goBack = async () => {
    const { data: updatedUser } = await unsetCustomer(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    navigate('/agent');
  };

  return user?.role?.type === 'agente' || user?.role?.type === 'rva' ? (
    <div className="flex items-center justify-between lg:h-16 bg-red-600 text-white px-4">
      <button
        className="z-10 w-20 flex items-center space-x-2 bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 rounded"
        onClick={goBack}
      >
        <ArrowLeftStartOnRectangleIcon className="h-5 w-5" />
        <span>Esci</span>
      </button>
      <div className="text-center">
        <p>Ciao agente {nome}!</p>
        <p>Stai navigando come cliente {cliente}</p>
      </div>
      <div className="w-20"></div>
    </div>
  ) : (
    <></>
  );
};

export default CustomerImpersonationHeader;
