import { useTranslation } from 'react-i18next';

const StepsHeaderMobile = ({ currentStep, steps }: { currentStep: number; steps: readonly string[] }) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  return (
    <nav className="my-8 flex items-center justify-center" aria-label="Progress">
      <p className="text-sm font-medium">
        {t('MOBILE_HEADER_TITLE', {
          current: currentStep + 1,
          total: steps.length,
        })}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((stepName, index) => (
          <li key={stepName}>
            {currentStep > index ? (
              <span className="block h-2.5 w-2.5 rounded-full bg-red-600">
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : currentStep === index ? (
              <span className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-red-200" />
                </span>
                <span className="relative block h-2.5 w-2.5 rounded-full bg-red-600" aria-hidden="true" />
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : (
              <span className="block h-2.5 w-2.5 rounded-full bg-gray-200">
                <span className="sr-only">{t(stepName)}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepsHeaderMobile;
