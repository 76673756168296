import React from 'react';
import { classNames } from '../../lib/utils';
import Spinner from '../Spinner';

export const OutlineButton = ({
  text,
  icon,
  color,
  onClick,
  disabled,
  loading,
}: {
  text: string;
  icon?: React.ReactNode;
  color?: 'red' | 'yellow' | 'green';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const classes = classNames(
    'rounded-md border py-2 px-4 text-sm font-medium w-full flex items-center justify-center gap-2',
    color === 'red' && 'border-red-600 text-red-600 bg-red-50 hover:bg-red-100',
    color === 'yellow' && 'border-yellow-600 text-yellow-600 bg-yellow-50 hover:bg-yellow-100',
    color === 'green' && 'border-green-600 text-green-600 bg-green-50 hover:bg-green-100',
    !color && 'border-red-600 text-red-600 bg-white hover:bg-red-50',
    disabled && 'grayscale cursor-not-allowed opacity-50',
  );

  return (
    <button className={classes} onClick={onClick} disabled={disabled || loading}>
      {loading ? <Spinner style={{ color: 'red' }} /> : icon}
      {text}
    </button>
  );
};
