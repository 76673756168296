import axios from 'axios';
import IResetPasswordInAppForm from '../../../pages/Profile/components/Security/interfaces/resetPasswordInAppForm';
const instance = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

const resetInApp = async ({
  data,
  token,
}: {
  data: Omit<IResetPasswordInAppForm, 'repeatNewPassword'>;
  token: string | null;
}) => {
  const url = `${REACT_APP_BACKEND_URL}/users/reset-password`;
  const headers: any = {};

  if (token) headers.Authorization = `Bearer ${token}`;

  const response = await instance.post(url!, data, { headers });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default resetInApp;
