import React from 'react';
import ReactModal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import AgentPromotionsDetails from './AgentPromotionsDetails';
import { ICustomerForAgent } from '../../lib/interfaces/ICustomerForAgent';

interface IPromotionsDetailsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  promotionsDetailsProps: ICustomerForAgent | undefined;
}

const AgentPromotionsDetailsModal: React.FC<IPromotionsDetailsModalProps> = ({
  isOpen,
  onRequestClose,
  promotionsDetailsProps,
}) => {
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="overflow-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg w-11/12 h-5/6"
      >
        <button onClick={onRequestClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6" />
        </button>
        {/* @ts-ignore*/}
        <AgentPromotionsDetails {...promotionsDetailsProps} />
      </ReactModal>
    </div>
  );
};

export default AgentPromotionsDetailsModal;
