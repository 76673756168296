type Params = {
  requestedQuantity: number;
  yellowQuantity: number;
  greenQuantity: number;
};

const getOrderedYellowQuantity = (params: Params): number => {
  const { requestedQuantity, yellowQuantity, greenQuantity } = params;
  return requestedQuantity < yellowQuantity ? requestedQuantity - greenQuantity : yellowQuantity;
};

export default getOrderedYellowQuantity;
