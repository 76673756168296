import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Translations from './translations';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Translations,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
