import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../FullScreenLoading';
import { RoleType } from '../../lib/enums';
import { getIsUserRoleAllowed } from '../../lib/utils/routesUtils';

const PrivateRoute: React.FC<{
  element: React.ReactElement;
  publicRedirect?: string;
  hasUserRequiredOptions?: boolean;
  restrictedToRoles?: RoleType[];
}> = (props) => {
  const { t } = useTranslation('COMMON');
  const [{ user, appLoaded, isAuthenticated }] = useAuth();
  const { element: PrivateElement, publicRedirect = '/', hasUserRequiredOptions = true, restrictedToRoles } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.isSingleUsePassword) {
      navigate('/profile/reset-temporary-password');
    }
  }, [location.pathname, user]);

  const isUserRoleAllowed = getIsUserRoleAllowed(user!, restrictedToRoles);

  if (appLoaded) {
    return isAuthenticated && hasUserRequiredOptions && isUserRoleAllowed ? (
      PrivateElement
    ) : (
      <Navigate replace to={publicRedirect} />
    );
  }

  return <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />;
};

export default PrivateRoute;
