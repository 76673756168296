import { useSearchParams } from 'react-router-dom';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import { useMemo } from 'react';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';
import { useTranslation } from 'react-i18next';

interface Props {
  onRedirect: () => void;
}

const SofinnResetPassworFeedbackMessage: React.FC<Props> = (props) => {
  const { onRedirect } = props;
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const errorMessage = useMemo(() => searchParams.get('errorMessage'), [searchParams]);

  return (
    <div className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]">
      <div className="flex flex-col">
        {errorMessage ? (
          <SofinnErrorBox errorMessage={errorMessage} className="text-left justify-start" />
        ) : (
          <div className="flex w-full">{t('PASSWORD_CHANGED_SUCCESS_MSG')}</div>
        )}
        <SofinnButton
          text={t('GO_BACK')}
          onClick={() => onRedirect()}
          className="px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75"
        />
      </div>
    </div>
  );
};

export default SofinnResetPassworFeedbackMessage;
