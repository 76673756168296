import { To, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../lib/utils';

interface IBackButton {
  disabled?: boolean;
  text: string;
  to: To;
}

const BackButton = ({ disabled = false, text, to }: IBackButton) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => navigate(to)}
      className={classNames(
        'flex justify-center rounded-md border border-transparent bg-red-200 py-2 px-4 text-xs font-medium text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2',
        disabled ? 'cursor-not-allowed' : 'hover:bg-red-300 hover:text-red-700',
      )}
    >
      <ArrowLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      {text}
    </button>
  );
};

export default BackButton;
