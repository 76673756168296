import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { PrimaryButton } from '../../Buttons';
import { useShoppingCartOverlay } from '../../../providers/ShoppingCartOverlayProvider';

interface IFreeItemsDialog {
  termsText?: string;
  isOpen: boolean;
  closeModal: () => any;
}

const FreeItemsDialog = (props: IFreeItemsDialog) => {
  const { isOpen, closeModal } = props;
  const {
    termsText = 'SCONTO NATURA (o Cessione a Titolo di Sconto). Si prende atto della gestione promozionale tra la Cati Spa ed il cliente secondo quanto presente nella descrizione delle promozioni stesse e visibile all’atto dell’acquisto: Esempio ARTICOLO X 10 PEZZI + 2 in SCONTO NATURA',
  } = props;
  const [, dispatchOverlayChange] = useShoppingCartOverlay();
  const [accept, setAccept] = useState(false);

  const onAccept = () => {
    sessionStorage.setItem('accepted-free-cart-item-terms', 'true');
    closeModal();
    dispatchOverlayChange({ open: true });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Termini accettazione prodotti omaggio
                </Dialog.Title>
                <Dialog.Description>
                  <div className="mt-5 text-sm text-gray-700">{termsText ?? 'cxasx'}</div>
                </Dialog.Description>

                <div className="flex my-5">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                      onChange={() => setAccept(!accept)}
                      checked={accept}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      className="font-medium text-gray-700"
                      dangerouslySetInnerHTML={{ __html: 'Accettare per presa visione' }}
                      onClick={() => setAccept(!accept)}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <PrimaryButton text="Conferma" onClick={onAccept} disabled={!accept} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FreeItemsDialog;
