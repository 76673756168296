import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider/index';
import i18n from '../../i18n';
import { IAddToCartWithFeedback, addToCartWithFeedback } from '../../lib/utils/cart';
import { AlertType, useAlert } from '../../providers/AlertProvider/index';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider/index';
import { exactSearchCode } from '../../lib/fetch/products/search';
import { SORTING_OPTIONS } from '../ProductsTable/HorizontalFilter';
import { WhereToSearch } from '../../lib/enums/index';
import { aastoreEmbed } from './aastore.js';
import {
  EnumAastoreResponseStatus,
  EnumAastoreResponseType,
  IAastoreProduct,
  IAastoreResponse,
} from '../../lib/interfaces/IAastore';
import { getAastoreProductCode } from '../../lib/fetch/aastore';
import { loginAastore } from '../../lib/fetch/aastore';

export interface IAdvanceSearchModalProps {
  open: boolean;
  onClose: () => void;
  firstRenderAdvanceSearch: boolean;
  setFirstRenderAdvanceSearch: (value: boolean) => void;
}

const AdvanceSearchModal = (props: IAdvanceSearchModalProps) => {
  const { open, onClose, firstRenderAdvanceSearch, setFirstRenderAdvanceSearch } = props;
  const initialRef = useRef(null);
  const { t } = useTranslation('ADVANCE_SEARCH');
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const lang = i18n.languages[0];
  const [, dispatchAlertChange] = useAlert();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const [, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      loginAastore(user!, lang, token).then((res) => {
        if (res.data.status) {
          // Add script tag to load aastoreEmbed
          // const scriptTag = document.createElement('script');
          // scriptTag.type = 'text/javascript';
          // scriptTag.src = res.data.ws_url;
          // document.head.appendChild(scriptTag);

          const embd = new aastoreEmbed({ token: res.data.token, target: '#div_iframe' });

          if (!firstRenderAdvanceSearch) return;
          setFirstRenderAdvanceSearch(false);

          embd.addCallback(async (e: any) => {
            const response: IAastoreResponse = e.detail.content;

            if (
              response.type === EnumAastoreResponseType.ITEM &&
              response.status === EnumAastoreResponseStatus.SELECTION &&
              response.content
            ) {
              const product: IAastoreProduct = response.content;
              const { data: axiosData } = await getAastoreProductCode(token, { pcode: product.pcode, ls: product.ls });
              if (axiosData.data) {
                const productCode = axiosData.data;
                await addAastoreProductToCart(productCode);
              } else {
                dispatchAlertChange({
                  open: true,
                  message: t('PRODUCT_NOT_FOUND'),
                });
              }
            }
          });
        }
      });
    }
  }, [open]);

  const addAastoreProductToCart = async (productCode: string) => {
    const { data: axiosData } = await exactSearchCode(token!, {
      page: 1,
      pageSize: 1,
      search: productCode,
      sort: SORTING_OPTIONS[0].value,
      where: WhereToSearch.Code,
      lang,
    });
    const [product] = axiosData.data;

    const quantity = product?.attributes.sales_package_sizes ? Math.floor(product?.attributes.sales_package_sizes) : 1;

    await addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: [{ product: product, quantity: quantity }],
      setAdding: setLoading,
      t,
      token,
      user,
      lang,
      onClose:
        quantity > 1
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + quantity + ')',
              });
            }
          : () => {},
    } as IAddToCartWithFeedback);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" initialFocus={initialRef} onClose={() => {}}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block h-screen transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:w-full sm:p-6 sm:align-middle">
              <div className="h-full overflow-auto">
                <div className="ml-4 hidden lg:ml-0 lg:inline-flex w-full">
                  <div className="flex justify-between items-center w-full">
                    <img
                      className="h-12 w-auto max-w-none"
                      src="../images/cati-logo-red-resized.png"
                      alt="Cati Ecommerce"
                      title="Cati Ecommerce"
                    />
                    <div className="flex">
                      <button
                        className="flex items-center justify-center rounded-full border border-transparent mr-2 bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 lg:rounded-md lg:px-4"
                        onClick={() => onClose()}
                      >
                        <ShoppingCartIcon className="-ml-0.5 h-4 w-4 mr-2" aria-hidden="true" />
                        <span className="hidden lg:inline-block">{t('BACK')}</span>
                      </button>
                      <button className="display-none" ref={initialRef} />
                    </div>
                  </div>
                </div>
                {/*                 <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-600">
                  <a href="https://www.aastore.biz/" target="_blank" rel="noreferrer">
                    {t('TITLE')}
                  </a>
                </Dialog.Title> */}
                <div id="div_iframe" className="mt-4 h-full" />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AdvanceSearchModal;
