import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import SofinnQuestionResetPasswordPresentational from './SofinnQuestionResetPasswordPresentational';
import { useAuth } from '../../../providers/AuthProvider';
import redirectToSofinnV2 from '../../../lib/fetch/sofinn/auth/redirectV2';
import aknowledgeSofinnUserForNewCredentials from '../../../lib/fetch/sofinn/auth/aknowledgeSofinnUserForNewCredentials';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SofinnQuestionResetPassword: React.FC = () => {
  const [{ sofinnToken, sofinnUser }, dispatchAuthChange] = useAuth();
  const [resetError, setResetError] = useState(false);
  const navigate = useNavigate();
  const { mutate, isLoading, isError } = useMutation(() => aknowledgeSofinnUserForNewCredentials(String(sofinnToken)), {
    onError: () => setResetError(false),
  });
  const {
    i18n: { language },
  } = useTranslation('RESET_PASSWORD_SOFINN');

  moment.locale(language);
  useEffect(() => {
    mutate();
  }, [mutate]);

  const onResetError = () => {
    setResetError(false);
    mutate();
  };

  const handleRedirect = () => {
    redirectToSofinnV2({ sofinnToken: String(sofinnToken), dispatchAuthChange });
  };

  const onAcceptResetPassword = () => {
    navigate('/cati-b2b/reset-password');
  };

  const passwordExpiryDate = sofinnUser?.passwordExpiryDate ? new Date(sofinnUser.passwordExpiryDate) : new Date();

  const diffDays = moment(passwordExpiryDate).diff(moment(), 'days');
  return (
    <SofinnQuestionResetPasswordPresentational
      isLoading={isLoading}
      isError={isError}
      resetError={resetError}
      diffDays={diffDays}
      username={sofinnUser?.username || ''}
      onAcceptResetPassword={onAcceptResetPassword}
      onRedirect={handleRedirect}
      onResetError={onResetError}
    />
  );
};

export default SofinnQuestionResetPassword;
