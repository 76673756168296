import axios from 'axios';
import { IUser } from '../../interfaces';

const { REACT_APP_AGENT_GET_CUSTOMERS_URL } = process.env;

const getCustomers = async (token: string) => {
  const response = await axios.get<IUser>(REACT_APP_AGENT_GET_CUSTOMERS_URL!, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getCustomers;
