import { useTranslation } from 'react-i18next';

const DettagliSofinn: React.FC = () => {
  const { t } = useTranslation('LOGIN_SOFINN');

  return (
    <div className="mt-[20px] text-[11px] text-center">
      {/* <b className="flex flex-col justify-center">
        <span className="text-[#333]">{t('COPYRIGHT', { year: new Date().getFullYear() })}</span>
        <a href="https://www.sofinn.it" target="_blank" className="flex flex-col text-[#710d28]" rel="noreferrer">
          <div className="flex flex-row justify-center gap-1">
            <img src="/images/sofinn/sofinn-logo.png" className="h-[30px]" alt="logo sofinn" />
            <img src="/images/sofinn/sofinn-b2b-logo.png" className="h-[27px] mt-[3px]" alt="logo e-commerce sofinn" />
          </div>
          <p className="hover:text-[#521224]">{t('POWERED_BY')}</p>
        </a>

        <br />
        <br />
        <span className="text-[#333]">{t('BOA_VERSION', { version: '3.0' })}</span>
        <div className="text-[#710d28]">
          <a href="https://www.sofinn.it/contatti/" target="_blank" rel="noreferrer" className="hover:text-[#521224]">
            {t('SOFINN_ADDRESSES')}
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="https://www.sofinn.it/contatti/" target="_blank" rel="noreferrer" className="hover:text-[#521224]">
            {t('CONTACTS')}
          </a>
        </div>
      </b> */}
    </div>
  );
};

export default DettagliSofinn;
