import SofinnForgotPasswordFeedbackMessagePresentational from './SofinnForgotPasswordFeedbackMessagePresentational';
import { useNavigate } from 'react-router-dom';

const SofinnForgotPasswordFeedback: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/cati-b2b/login');
  };

  return <SofinnForgotPasswordFeedbackMessagePresentational onRedirect={handleRedirect} />;
};

export default SofinnForgotPasswordFeedback;
