import { IProductStock } from '../../../lib/fetch/products';
import { IUser } from '../../../lib/interfaces';
import getAvailableRequestedProductQuantity from './getAvailableRequestedProductQuantity';
import getCanBuyOutOfStock from './getCanBuyOutOfStock';
import getOrderedRedQuantity from './getOrderedRedQuantity';
import getOrderedYellowQuantity from './getOrderedYellowQuantity';

type Params = {
  productStock: IProductStock;
  requestedQuantity: number;
  cartCurrentQuantity: number;
  user?: IUser;
};

type ReturnType = {
  orderedYellowQuantity: number;
  orderedRedQuantity: number;
  availableRequestedProductQuantity: number;
  showGreenProductsOption: boolean;
  showYellowProductsOption: boolean;
  showRedProductsOption: boolean;
  greenQuantity: number;
  yellowQuantity: number;
};

const getProductOrderedQuantities = (params: Params): ReturnType => {
  const { productStock, requestedQuantity, user, cartCurrentQuantity } = params;

  const canBuyOutOfStock: boolean = getCanBuyOutOfStock({ user });
  const { quantityDefaultWarehouse: greenQuantity, quantityOtherWarehouses: yellowQuantity } = productStock;

  const totalAvailableQuantity: number = greenQuantity + yellowQuantity;

  /**
   * Se vengono richieste delle quantità "gialle" il limite massimo è dato dalla somma verdi+gialle
   */
  const availableRequestedProductQuantity: number = getAvailableRequestedProductQuantity({
    requestedQuantity,
    totalAvailableQuantity,
  });

  //la quantità di giallo ordinata tenendo conto della quantità già presente nel carrello
  const orderedYellowQuantity: number = getOrderedYellowQuantity({
    requestedQuantity: requestedQuantity + cartCurrentQuantity,
    yellowQuantity,
    greenQuantity,
  });

  //la quantità di rosso ordinata tenendo conto della quantità già presente nel carrello
  const orderedRedQuantity: number = getOrderedRedQuantity({
    requestedQuantity: requestedQuantity + cartCurrentQuantity,
    totalAvailableQuantity,
  });

  //visibilità pulsanti
  const showGreenProductsOption: boolean = greenQuantity > 0;
  const showYellowProductsOption: boolean = yellowQuantity > 0;
  const showRedProductsOption: boolean = orderedRedQuantity > 0 && canBuyOutOfStock;

  const result: ReturnType = {
    availableRequestedProductQuantity,
    orderedRedQuantity,
    orderedYellowQuantity,
    showRedProductsOption,
    showGreenProductsOption,
    showYellowProductsOption,
    greenQuantity,
    yellowQuantity,
  };

  return result;
};

export default getProductOrderedQuantities;
