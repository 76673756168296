import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { DeliveryType } from '../../../lib/interfaces/ICustomer';

interface IRadioGroupDeliveryBox {
  selectedDelivery: DeliveryType;
  shippingMode: DeliveryType;
  onDeliveryChange: (type: DeliveryType) => void;
}
const RadioGroupDeliveryBox: FC<IRadioGroupDeliveryBox> = (props) => {
  const { selectedDelivery, shippingMode, onDeliveryChange = (_type: string) => {} } = props;
  const { t } = useTranslation('SHOPPING_CART');

  return (
    <RadioGroup value={selectedDelivery} onChange={(type: DeliveryType) => onDeliveryChange(type)}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        {
          <RadioGroup.Option
            key={DeliveryType.Bench}
            value={DeliveryType.Bench}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-red-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white shadow-sm focus:outline-none',
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex w-full flex-col">
                  <div className="flex w-full items-center justify-between border-b px-4 py-3">
                    <RadioGroup.Label as="span" className="block text-base font-semibold text-gray-900">
                      {t('DELIVERY_TYPE_BENCH')}
                    </RadioGroup.Label>
                    <CheckCircleIcon
                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-red-600')}
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-red-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        }
        {shippingMode !== DeliveryType.Forced_bench && (
          <RadioGroup.Option
            key={DeliveryType.Courier}
            value={DeliveryType.Courier}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-red-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white shadow-sm focus:outline-none',
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex w-full flex-col">
                  <div className="flex w-full items-center justify-between border-b px-4 py-3">
                    <RadioGroup.Label as="span" className="block text-base font-semibold text-gray-900">
                      {t('DELIVERY_TYPE_COURIER')}
                    </RadioGroup.Label>
                    <CheckCircleIcon
                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-red-600')}
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-red-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        )}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupDeliveryBox;
