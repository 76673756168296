import axios from 'axios';
import { IUser } from '../../interfaces';

const { REACT_APP_AGENT_SET_CUSTOMER_URL } = process.env;

const setCustomer = async (token: string, customerId: number) => {
  const response = await axios.post<IUser>(
    REACT_APP_AGENT_SET_CUSTOMER_URL!,
    {
      customerId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default setCustomer;
