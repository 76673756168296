import { useEffect, useState } from 'react';
import FooterSofinn from '../../../components/Sofinn/FooterSofinn';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';
import { useTranslation } from 'react-i18next';

interface Props {
  username: string;
  handleClick: () => void;
  isLoading: boolean;
  isError?: boolean;
}

const SofinnInfoNewUsernamePresentational: React.FunctionComponent<Props> = (props) => {
  const { username, handleClick, isLoading, isError } = props;
  const { t } = useTranslation('LOGIN_SOFINN');

  //serve per dare la possibilità all'utente di riprovare.
  const [resetError, setResetError] = useState<boolean>(true);

  const onResetError = () => {
    setResetError(true);
  };

  useEffect(() => {
    if (isError) setResetError(false);
  }, [isError]);

  return (
    <div className="h-screen flex flex-col justify-center">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>
      </div>
      <div className="flex h-full justify-center align-middle">
        {!isError || resetError ? (
          <div className="flex h-[300px] w-full flex-col justify-center align-middle  md:mx-auto   border p-[15px] rounded-[3px] md:w-[400px] my-auto">
            <p className="" dangerouslySetInnerHTML={{ __html: t('INFO_NEW_USERNAME') }} />
            <p dangerouslySetInnerHTML={{ __html: t('NEW_USERNAME', { username }) }} />

            <SofinnButton disabled={isLoading} onClick={handleClick} text="Ho capito" className="w-24" />
          </div>
        ) : (
          <div className="flex h-[300px] w-full flex-col justify-center align-middle  md:mx-auto p-[15px] rounded-[3px] md:w-[400px] my-auto">
            <SofinnErrorBox errorMessage={t('ERROR_AKNOWLODGEMENT_NEW_USERNAME')} />
            <SofinnButton disabled={isLoading} onClick={onResetError} text={t('RETRY')} className="w-24" />
          </div>
        )}
      </div>

      <FooterSofinn />
    </div>
  );
};

export default SofinnInfoNewUsernamePresentational;
