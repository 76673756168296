import { FC, useState } from 'react';
import { ICartItem } from '../../../lib/fetch/cartItems';

interface IExcerptField {
  cartItem: ICartItem;
  readOnly?: boolean;
  onExcerptChange: (cartItem: ICartItem, excerpt: boolean) => void;
}

const ExcerptField: FC<IExcerptField> = ({ cartItem, onExcerptChange, readOnly = false }) => {
  const [excerpt, setExcerpt] = useState<boolean>(cartItem.excerpt ?? false);

  const handleChange = () => {
    const newExcerpt = !excerpt;
    setExcerpt(newExcerpt);
    onExcerptChange(cartItem, newExcerpt);
  };

  return (
    <input
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
      checked={excerpt}
      onChange={handleChange}
      disabled={cartItem.price === 0 || readOnly}
    />
  );
};

export default ExcerptField;
