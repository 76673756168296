import { FC } from 'react';
import FooterSofinn from '../../../components/Sofinn/FooterSofinn';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';
import { useTranslation } from 'react-i18next';

interface SofinnQuestionResetPasswordProps {
  isLoading: boolean;
  isError: boolean;
  resetError: boolean;
  diffDays: number;
  username: string;
  onAcceptResetPassword: () => void;
  onRedirect: () => void;
  onResetError: () => void;
}
const SofinnQuestionResetPasswordPresentational: FC<SofinnQuestionResetPasswordProps> = ({
  isLoading,
  isError,
  resetError,
  diffDays,
  username,
  onAcceptResetPassword,
  onRedirect,
  onResetError,
}) => {
  const { t } = useTranslation('RESET_PASSWORD_SOFINN');

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>
        {isError && !resetError ? (
          <div className="flex h-[300px] w-full flex-col justify-center align-middle md:mx-auto p-[15px] rounded-[3px] md:w-[400px] my-auto">
            <SofinnErrorBox errorMessage={t('ERROR_MESSAGE')} />
            <SofinnButton disabled={isLoading} onClick={onResetError} text={t('RETRY')} className="w-24" />
          </div>
        ) : (
          <div className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[800px] w-full my-[50px]">
            <h1 className="text-lg font-semibold mb-4">{t('GREETING')}</h1>
            <p className="mb-4">
              <span className="underline">{t('PASSWORD_EXPIRED')}</span>
            </p>
            <p className="mb-4">{t('CHOOSE_NEW_PASSWORD')}</p>
            <ul className="list-disc list-inside mb-4 space-y-1">
              <li>
                <strong>{t('REQUIREMENTS.MIN_CHARACTERS')}</strong>
              </li>
              <li>
                <strong>{t('REQUIREMENTS.UPPERCASE_LETTER')}</strong>
              </li>
              <li>
                <strong>{t('REQUIREMENTS.LOWERCASE_LETTER')}</strong>
              </li>
              <li>
                <strong>{t('REQUIREMENTS.NUMBER')}</strong>
              </li>
            </ul>
            {diffDays > 1 ? (
              <p className="mb-6">{t('EXPIRY_NOTICE_plural', { count: diffDays })}</p>
            ) : (
              <p className="mb-6">{t('EXPIRY_NOTICE', { count: diffDays })}</p>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html: t('NEW_USERNAME_NOTICE', { username }),
              }}
            />{' '}
            <div className="flex flex-row justify-between gap-16">
              <SofinnButton
                disabled={isLoading}
                onClick={onAcceptResetPassword}
                text={t('CHANGE_NOW')}
                className="flex-grow min-w-[150px] px-4 py-2 bg-[#dc2626] hover:bg-[#dc2626]/75 text-white text-center"
              />
              <SofinnButton
                disabled={isLoading}
                text={t('USE_OLD_PASSWORD')}
                onClick={onRedirect}
                className="flex-grow min-w-[150px] px-4 py-2 bg-[#dc2626] hover:bg-[#dc2626]/75 text-white text-center"
              />
            </div>
          </div>
        )}
      </div>
      <FooterSofinn />
    </div>
  );
};

export default SofinnQuestionResetPasswordPresentational;
