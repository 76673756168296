import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { AlertType } from '../../providers/AlertProvider';
import { classNames } from '../../lib/utils';

const FixedAlert = ({
  title = '',
  type,
  description = '',
}: {
  title?: string;
  type?: AlertType;
  description?: string;
}) => {
  return type ? (
    <div
      className={classNames(
        'rounded-md p-4',
        type === AlertType.Success && 'bg-green-50',
        type === AlertType.Error && 'bg-red-50',
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === AlertType.Success && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
          {type === AlertType.Error && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3
            className={classNames(
              'text-sm font-medium',
              type === AlertType.Success && 'text-green-800',
              type === AlertType.Error && 'text-red-800',
            )}
          >
            {title}
          </h3>
          <div
            className={classNames(
              'mt-2 text-sm',
              type === AlertType.Success && 'text-green-700',
              type === AlertType.Error && 'text-red-700',
            )}
          >
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default FixedAlert;
