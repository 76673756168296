import { IProduct } from '../../lib/fetch/products';

export interface IProductImport {
  code: string;
  quantity: number;
}

export interface IErrorImport {
  code: string;
  error: number;
  details?: any;
}

export interface IProductWithQuantity {
  product: IProduct;
  quantity: number;
}

export const mergeAndSumProducts = (products: IProductImport[]): IProductImport[] => {
  const mergedProducts: any = {};

  for (const product of products) {
    const { code, quantity } = product;

    if (!mergedProducts[code]) {
      mergedProducts[code] = {
        code: code,
        quantity: quantity,
      };
    } else {
      mergedProducts[code].quantity += quantity;
    }
  }

  return Object.values(mergedProducts);
};
