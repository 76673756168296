import * as t from 'io-ts';
import { ImageModel } from './Image';
import { LocalizationModel } from './Localization';

export const NewsPostAttributesModel = t.type({
  title: t.string,
  content: t.string,
  createdAt: t.string,
  updatedAt: t.string,
  date: t.string,
  locale: t.string,
  localizations: LocalizationModel,
  publishedAt: t.string,
  image: ImageModel,
});

export const NewsPostModel = t.type({
  id: t.number,
  attributes: NewsPostAttributesModel,
});

export const NewsPostCategoryModel = t.type({
  id: t.number,
  attributes: t.type({
    name: t.string,
    createdAt: t.string,
    updatedAt: t.string,
    news: t.type({
      data: t.array(
        t.type({
          id: t.number,
          attributes: t.type({
            title: t.string,
            content: t.string,
            createdAt: t.string,
            updatedAt: t.string,
            date: t.string,
            locale: t.string,
            publishedAt: t.string,
          }),
        }),
      ),
    }),
  }),
});

export type NewsPost = t.TypeOf<typeof NewsPostModel>;

export type NewsPostCategory = t.TypeOf<typeof NewsPostCategoryModel>;
