import axios, { AxiosResponse } from 'axios';
import { OrderStatus } from '../../enums';

const { REACT_APP_ORDERS_URL } = process.env;

const updateStatus = async (token: string, orderId: number, status: OrderStatus) => {
  const response = await axios.put(
    `${REACT_APP_ORDERS_URL!}/${orderId}`,
    { data: { status } },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default updateStatus;
