import AgentProfileMenu from './AgentProfileMenu';

const AgentHeader = () => {
  return (
    <div className="sticky top-0 z-10 bg-white">
      <header className="relative bg-white">
        <nav aria-label="Top" className="max-full mx-auto px-4 py-2 shadow-md lg:py-0">
          <div>
            <div className="flex flex-col items-center lg:h-16 lg:flex-row">
              {/* Logo */}
              <div className="ml-4 hidden lg:ml-0 lg:inline-flex">
                <img
                  className="h-32 w-auto max-w-none"
                  src="../images/cati-logo-red.png"
                  alt="Cati Ecommerce"
                  title="Cati Ecommerce"
                />
              </div>

              {/* Client and Carts buttons */}
              <div className="mx-auto flex flex-row gap-x-6"></div>

              <div className="mr-auto mt-3 flex items-center lg:mr-0 lg:mt-0">
                <AgentProfileMenu />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default AgentHeader;
