interface filterFilesRules {
  acceptedTypes?: readonly string[];
  allowDuplicates?: boolean;
  maxSizeMB?: number;
  unsupportedFileCallback?: (file: File, messageKey: string) => void;
}

const filterFiles = (newFiles: File[], rules: filterFilesRules, currentFiles?: File[]): File[] => {
  const { acceptedTypes, allowDuplicates, maxSizeMB = 0, unsupportedFileCallback: _unsupportedFileCallback } = rules;
  const maxSize = maxSizeMB * 1024 * 1024;
  const unsupportedFileCallback =
    _unsupportedFileCallback && typeof _unsupportedFileCallback === 'function' ? _unsupportedFileCallback : () => {};
  return newFiles.filter((file) => {
    const { name, type, size } = file;
    if (!allowDuplicates && currentFiles && currentFiles.length) {
      const isAlreadySelected = currentFiles.some(({ name: currentFileName, size: currentFileSize }) => {
        return currentFileName === name && currentFileSize === size;
      });
      if (isAlreadySelected) {
        unsupportedFileCallback(file, 'STEP4_ALREADY_SELECTED');
        return false;
      }
    }
    let isSizeValid = true;
    let isTypeValid = true;
    if (maxSizeMB && maxSizeMB > 0) isSizeValid = size <= maxSize;
    if (acceptedTypes && acceptedTypes.length) isTypeValid = acceptedTypes.includes(type);
    const isValid = isSizeValid && isTypeValid;
    if (isValid) return true;

    if (!isSizeValid) unsupportedFileCallback(file, 'STEP4_UNSUPPORTED_SIZE');
    else if (!isTypeValid) unsupportedFileCallback(file, 'STEP4_UNSUPPORTED_TYPE');
    return false;
  });
};

export default filterFiles;
