interface IAastoreStocks {
  id: number;
  content: {
    qty: string;
  };
}

export interface IAastoreProduct {
  ls: string;
  pcode: string;
  ls_txt: string;
  descr: string;
  price: string;
  net_price: string;
  stocks?: IAastoreStocks[];
}

export interface IAastoreResponse {
  requestId: string;
  type: string;
  status: string;
  timestamp: number;
  content?: IAastoreProduct;
}

export enum EnumAastoreResponseStatus {
  SELECTION = 'selection',
  FRONTEND_LOADED = 'frontend_loaded',
}

export enum EnumAastoreResponseType {
  INIT = 'init',
  ITEM = 'item',
}

export interface IAastoreLogin {
  status: string;
  token: string;
  ws_url: string;
  ext_id: string | null;
}
