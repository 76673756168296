import { useEffect, useReducer, useState } from 'react';
import { IProduct, IProductAttributes } from '../lib/fetch/products';
import {
  IDeleteCartItemWithFeedback,
  IFlushCartWithFeedback,
  isProductInCart,
  IUpdateCartWithFeedback,
  updateCartItem,
} from '../lib/utils/cart';
import { cartUtils, IAddToCartWithFeedback } from '../lib/utils';
import { useAuth } from '../providers/AuthProvider';
import { AlertType, useAlert } from '../providers/AlertProvider';
import { useShoppingCartOverlay } from '../providers/ShoppingCartOverlayProvider';
import { useTranslation } from 'react-i18next';
import { IProductQuantity } from '../components/ProductsTable';
import i18n from '../i18n';
import { AddToCartParams, CartAction, CartActionType } from '../providers/CartProvider/interfaces';
import { useMutation, useQuery } from 'react-query';
import findProductStock from '../lib/fetch/products/findProductStock';
import { getCanUserOrder } from '../components/ProductsTable/utils';
import { quantityInputProps as _quantityInputProps } from '../lib/utils';
import { me } from '../lib/fetch/auth';
import { CartState } from '../providers/CartProvider/interfaces';
import { ICartItem } from '../lib/fetch/cartItems';
import { search as callSearchProducts } from '../lib/fetch/products';
import { SORTING_OPTIONS } from '../components/ProductsTable/HorizontalFilter';
import { WhereToSearch } from '../lib/enums';
import { v4 as uuidv4 } from 'uuid';

export const INITIAL_CART_STATE: CartState = {
  isLoading: false,
  cart: null,
  totalQuantity: 0,
  setCart: () => {},
};
const reducer = (state: CartState, action: CartAction): CartState => {
  const { type, cart = null, isLoading = false } = action;
  switch (type) {
    case CartActionType.EmptyCart:
      return {
        ...INITIAL_CART_STATE,
      };
    case CartActionType.SetCart:
      return {
        ...state,
        cart,
        totalQuantity:
          cart?.cart_items.reduce((acc, { quantity = 0, free }) => {
            // Don't count free products
            if (free) return acc;
            return acc + quantity || 0;
          }, 0) || 0,
      };
    case CartActionType.SetLoading:
      return {
        ...state,
        isLoading,
      };
    default:
      return { ...state };
  }
};

const useCartUtilities = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [{ open: isOverlayOpen }, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const { t } = useTranslation('PRODUCTS');
  const lang = i18n.languages[0];
  const [cartState, dispatchStateCart] = useReducer(reducer, INITIAL_CART_STATE);

  const [isInCartModalOpen, setIsInCartModalOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const [searching, setSearching] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [cartCurrentQuantity, setCartCurrentQuantity] = useState<number>(0);
  const [displayAddToCartModal, setDisplayAddToCartModal] = useState(false);
  const [isStocExceededModalOpen, setIsStocExceededModalOpen] = useState(false);

  const toggleIsInCartModal = () => setIsInCartModalOpen((prev) => !prev);
  const toggleStocExceededModalOpen = () => setIsStocExceededModalOpen((v) => !v);

  const { mutateAsync: mutateProductStock, data: productStock } = useMutation('findProductStock', findProductStock, {
    onSuccess: (data) => {
      if (!currentProduct) return;
      const quantityInputProps = _quantityInputProps(currentProduct, user);
      const disabled = quantityInputProps.disabled;
      const { quantityDefaultWarehouse: greenQuantity } = data;
      const canUserOrder = getCanUserOrder({ user, product: currentProduct, quantity: currentQuantity });

      //se l'utente non può ordinare interrompo qui
      if (!canUserOrder || disabled) return;

      //la modale con le informazioni sullo stock va aperta soltanto quando la quantità richiesta supera
      //la quantità "verde"
      if (currentQuantity > greenQuantity) {
        return toggleStocExceededModalOpen();
      }

      if (isProductInCart(user!, currentProduct) && displayAddToCartModal) {
        return toggleIsInCartModal();
      }
      addProductToCart(currentProduct, currentQuantity);
    },
    onError: () => {
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
    },
  });

  useEffect(() => {
    if (!currentProduct) return;
    mutateProductStock({ token, id: currentProduct.id });
  }, [currentProduct, currentQuantity]);
  //Serve per avere la quantità nel carrello del prodotto per un messaggio della giacenza più accurato
  useEffect(() => {
    if (!currentProduct || !user) return;
    setCartCurrentQuantity(user.cart?.cart_items.find((item) => item.product.id === currentProduct.id)?.quantity || 0);
  }, [user, currentProduct, currentQuantity]);

  const onAddToCartStockExceeded = (quantity: number) => {
    if (!currentProduct) return;
    if (isProductInCart(user!, currentProduct) && displayAddToCartModal) {
      toggleIsInCartModal();
      return;
    }
    addProductToCart(currentProduct, quantity);
  };

  const resetCurrentProductAndQuantity = () => {
    setCurrentProduct(null);
    setCurrentQuantity(0);
  };

  const addProductToCart = async (
    product: IProduct,
    quantity: number,
    setAddingState: (adding: boolean) => void = setAdding,
  ) => {
    let quantityForced = quantity;
    const minQuantity = product?.attributes?.sales_package_sizes || 1;

    if (
      product?.attributes?.sales_package_sizes &&
      Math.floor(product?.attributes?.sales_package_sizes) !== 1 &&
      quantity % product.attributes.sales_package_sizes !== 0
    ) {
      if (quantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (quantity % minQuantity !== 0) {
        quantityForced = Math.ceil(quantity / minQuantity) * minQuantity;
      }
    }
    //L'aggiunta del prodotto al carrello viene effettuata solo se la quantità è diversa da quella già presente perché passa dallo useEffect
    resetCurrentProductAndQuantity();

    await cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: [{ product, quantity: quantityForced }],
      setAdding: setAddingState,
      t,
      token,
      user,
      lang: t('LANGUAGE'),
      onClose:
        quantityForced > quantity
          ? () =>
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ` (${minQuantity})`,
              })
          : undefined,
    } as IAddToCartWithFeedback);
    fetchCart();
  };

  const onAddToCart = async (params: AddToCartParams) => {
    const { product, quantity, displayAddToCartModal = false } = params;
    const uuidFictitious = uuidv4();
    setCurrentProduct({ ...product, uuidFictitious });
    setCurrentQuantity(quantity);
    setDisplayAddToCartModal(displayAddToCartModal);
  };

  const handleConfirm = async () => {
    toggleIsInCartModal();
    if (currentProduct && currentQuantity) {
      await addProductToCart(currentProduct, currentQuantity);
    }
  };

  const onAddAll = async (products: IProduct[], productQuantities: IProductQuantity[]) => {
    setAdding(true);
    const productsWithQuantity = products
      .filter((product) => product?.attributes?.price_list !== 0)
      .map((product) => {
        const quantity =
          productQuantities.find((q) => q.id === product.id)?.quantity || product?.attributes?.sales_package_sizes || 1;
        return { product, quantity };
      });

    await cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: productsWithQuantity,
      setAdding,
      t,
      token,
      user,
      lang,
      onClose: () => setAdding(false),
    } as IAddToCartWithFeedback);
    fetchCart();
  };

  const onFlushCart = async () => {
    await cartUtils.flushCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      setLoading: setAdding,
      t,
      token,
      user,
      lang,
    } as IFlushCartWithFeedback);
  };

  const { mutateAsync: mutateFlushCart, isLoading: isFlushingCart } = useMutation('flushCart', onFlushCart, {
    onSuccess: () => fetchCart(),
    onError: () => {
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
    },
  });

  const onItemRemove = async (cartItem: ICartItem) => {
    await cartUtils.deleteCartItemWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      setLoading: setAdding,
      t,
      token,
      user,
    } as IDeleteCartItemWithFeedback);
  };

  const { mutateAsync: mutateItemRemove, isLoading: isRemovingItem } = useMutation('deleteCartItem', onItemRemove, {
    onSuccess: () => fetchCart(),
    onError: () => {
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:REMOVE_ERROR'),
      });
    },
  });

  interface UpdateCartItemParams {
    cartItem: ICartItem;
    newQuantity: number;
  }

  const onItemQuantityChange = async ({ cartItem, newQuantity }: UpdateCartItemParams) => {
    const product = cartItem.product;
    let quantityForced = newQuantity;
    const minQuantity = product?.sales_package_sizes || 1;
    if (
      product?.sales_package_sizes &&
      Math.floor(product?.sales_package_sizes) !== 1 &&
      newQuantity % product.sales_package_sizes !== 0
    ) {
      if (newQuantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (newQuantity % minQuantity !== 0) {
        quantityForced = Math.ceil(newQuantity / minQuantity) * minQuantity;
      }
    }

    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: quantityForced,
      setLoading: setAdding,
      t,
      token,
      user,
      lang,
      onClose:
        quantityForced > newQuantity
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + minQuantity + ')',
              });
            }
          : () => {},
    } as IUpdateCartWithFeedback);
  };

  const { mutateAsync: mutateItemQuantityChange, isLoading: isUpdatingItem } = useMutation(
    'updateCartItem',
    onItemQuantityChange,
    {
      onSuccess: () => fetchCart(),
      onError: () => {
        resetCurrentProductAndQuantity();
        dispatchAlertChange({
          type: AlertType.Error,
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      },
    },
  );

  const { refetch: fetchCart, isFetching: isFetchingCart } = useQuery(['fetchCart', token], () => me(token!, lang), {
    onSuccess: (data) => {
      dispatchStateCart({ type: CartActionType.SetCart, cart: data.data.cart });
    },
    onError: () => {
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
      fetchCart();
    },
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatchStateCart({
      type: CartActionType.SetLoading,
      isLoading: isFetchingCart || isFlushingCart || isRemovingItem || isUpdatingItem || searching,
    });
  }, [isFetchingCart, isFlushingCart, isRemovingItem, isUpdatingItem, searching]);

  const [fistRender, setFirstRender] = useState(true);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState<boolean>(false);
  const toggleTermsModal = () => setIsTermsDialogOpen(!isTermsDialogOpen);

  const showTermsModal = () => {
    if (fistRender) {
      setFirstRender(false);
      return;
    }
    const hasFreeCartItem = cartState.cart?.cart_items?.some((cartItem) => cartItem.free);
    const hasAcceptedTerms = Boolean(sessionStorage.getItem('accepted-free-cart-item-terms'));

    if (hasAcceptedTerms) return dispatchCartOverlayChange({ open: true });

    if (!hasFreeCartItem) return dispatchCartOverlayChange({ open: true });

    toggleTermsModal();
  };

  useEffect(() => {
    showTermsModal();
  }, [user]);

  function findFirstPromoTerms(cartItems: ICartItem[]): string | undefined {
    const cartItem = cartItems.find(
      (item) =>
        item.promotions &&
        item.promotions.data &&
        item.promotions.data.some((promo) => {
          return promo.promoTipoSuTipo;
        }),
    );

    if (cartItem) {
      const promo = cartItem.promotions.data.find((promo) => promo.promoTipoSuTipo);

      if (promo && promo.promoTipoSuTipo && promo.promoTipoSuTipo.length > 0) {
        const config = promo.promoTipoSuTipo[0];

        return config?.terms;
      }
    }

    return undefined;
  }

  const onSearchAddToCart = async (search: string) => {
    if (!search) return;
    setSearching(true);
    const { data: axiosData, error } = await callSearchProducts(token!, {
      page: 1,
      pageSize: 2, // We just want to know if there is more than ONE product.
      search,
      sort: SORTING_OPTIONS[0].value,
      where: WhereToSearch.Code,
      lang,
    });
    if (error || !axiosData) {
      setSearching(false);
      return dispatchAlertChange({ open: true });
    }
    const { data = [] } = axiosData;
    if (!data.length) {
      setSearching(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:NO_RESULT_FOR_CODE'),
      });
    }
    if (data.length > 1) {
      setSearching(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:ADD_TO_CART_MULTIPLE_ERROR'),
      });
    }
    dispatchCartOverlayChange({ open: false });
    const [product] = data;
    try {
      await onAddToCart({ product, quantity: 1, displayAddToCartModal: true });
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('ADD_TO_CART_ERROR'),
      });
    } finally {
      setSearching(false);
    }
  };

  const onExcerptChange = async (cartItem: ICartItem, excerpt: boolean) => {
    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: cartItem.quantity,
      excerpt: excerpt,
      setLoading: setAdding,
      t,
      token,
      user,
      lang,
    } as IUpdateCartWithFeedback);
  };

  const { mutate: updateCartItemMutation } = useMutation(
    'updateCartItemMutation',
    (params: {
      token: string;
      cartItem: ICartItem;
      product: IProduct;
      quantity: number;
      excerpt: boolean;
      selected: boolean;
    }) => {
      return updateCartItem(
        params.token,
        params.cartItem,
        params.product,
        params.quantity,
        params.excerpt,
        params.selected,
      );
    },
    {
      onSuccess: () => {
        fetchCart();
      },
      onError: () => {
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      },
    },
  );
  const onSelectedForOderChange = async (cartItem: ICartItem, selected: boolean) => {
    const product: IProduct = {
      id: cartItem.product.id,
      attributes: {
        ...cartItem.product,
        promotions: cartItem.promotions,
      } as unknown as IProductAttributes,
    };
    updateCartItemMutation({
      token: String(token),
      cartItem,
      product,
      quantity: cartItem.quantity,
      excerpt: cartItem.excerpt,
      selected,
    });
  };

  const firstPromoTerms = findFirstPromoTerms(cartState.cart?.cart_items || []);
  return {
    isInCartModalOpen,
    adding,
    toggleIsInCartModal,
    onAddToCart,
    handleConfirm,
    onAddAll,
    currentProduct,
    currentQuantity,
    onAddToCartStockExceeded,
    isStocExceededModalOpen,
    toggleStocExceededModalOpen,
    productStock,
    cartCurrentQuantity,
    cartState,
    fetchCart,
    dispatchStateCart,
    onFlushCart: mutateFlushCart,
    onItemRemove: mutateItemRemove,
    onItemQuantityChange: mutateItemQuantityChange,
    dispatchOverlayChange: dispatchCartOverlayChange,
    isOverlayOpen,
    toggleTermsModal,
    isTermsDialogOpen,
    firstPromoTerms,
    onSearchAddToCart,
    onExcerptChange,
    onSelectedForOderChange,
  };
};

export default useCartUtilities;
