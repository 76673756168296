import axios from 'axios';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import { either } from 'fp-ts';
import { PaginatedResponse } from '../models/PaginatedResponse';

export function decodeOrThrow<A, O>(type: t.Type<A, O>, value: unknown): A {
  const decoded = type.decode(value);
  if (either.isLeft(decoded)) {
    throw new Error(PathReporter.report(decoded).join('\n'));
  }
  return decoded.right;
}

export async function list<T>(url: string, token: string | null, model: t.Type<T>) {
  const response = await axios.get<PaginatedResponse<T[]>>(url, {
    headers: {
      Authorization: `Bearer ${token || ''}`,
    },
  });

  return {
    data: decodeOrThrow(t.array(model), response.data.data),
    meta: response.data.meta,
  };
}
