import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useAlert } from '../../providers/AlertProvider';
import BackButton from '../../components/Buttons/BackButton';
import StepsHeader, { StepsHeaderMobile } from '../../components/StepsHeader';
import { Step1, Step2, Step3 } from '../../components/Signup';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import LightPrimaryButton from '../../components/Buttons/LightPrimaryButton';
import { IUser } from '../../lib/interfaces';

interface IEditProfilePresentational {
  user: IUser;
  loading: boolean;
  onEdit: (_: IUser) => void;
}

const STEPS = Object.freeze([Step1, Step2, Step3]);

const STEPS_TITLES = Object.freeze(['STEP1_TITLE', 'STEP2_TITLE', 'STEP3_TITLE']);

const EditProfilePresentational = (props: IEditProfilePresentational) => {
  const { user, loading = false, onEdit = (_: IUser) => {} } = props;
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const formMethods = useForm<IUser>({
    defaultValues: user,
  });
  const { reset, handleSubmit } = formMethods;
  const [, dispatchAlertChange] = useAlert();
  const [currentStep, setCurrentStep] = useState(0);
  const isFirstStep = !currentStep;
  const isLastStep = currentStep === STEPS.length - 1;

  useEffect(() => {
    if (!user) return;
    reset(user);
  }, [user]);

  const getStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step1 isEditing />;
      case 1:
        return <Step2 isEditing />;
      case 2:
        return <Step3 isEditing />;
    }
  };

  return (
    <>
      <div className="min-h-screen py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-7xl">
            <div className="mb-10">
              <BackButton text={t('COMMON:BACK')} to="/profile" disabled={loading} />
            </div>
            <img
              className="mx-auto h-32 w-auto"
              src="../images/cati-logo-red.png"
              alt="Cati Ecommerce"
              title="Cati Ecommerce"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('PROFILE_EDIT:TITLE')}</h2>

            <div className="hidden lg:block">
              <StepsHeader currentStep={currentStep} steps={STEPS_TITLES} />
            </div>
            <div className="block lg:hidden">
              <StepsHeaderMobile currentStep={currentStep} steps={STEPS_TITLES} />
            </div>

            <div>
              <FormProvider {...formMethods}>{getStep(currentStep)}</FormProvider>

              <div className="mt-8 flex items-center justify-between">
                <LightPrimaryButton
                  text={t('COMMON:PREV')}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  disabled={isFirstStep || loading}
                />
                <PrimaryButton
                  loading={loading}
                  disabled={loading}
                  text={t(isLastStep ? 'PROFILE_EDIT:BTN_SAVE' : 'COMMON:NEXT')}
                  onClick={handleSubmit(
                    (formData) => (isLastStep ? onEdit(formData) : setCurrentStep(currentStep + 1)),
                    () =>
                      dispatchAlertChange({
                        open: true,
                        message: t('VALIDATION_ERROR'),
                      }),
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfilePresentational;
