import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { useSettings } from '../../../providers/SettingsProvider';
import { CheckAll } from '../../Checkbox';
import ShoppingCartRow from './ShoppingCardRow';
import { useCart } from '../../../providers/CartProvider';

interface IShoppingCartTable {
  cartItems: ICartItem[];
  readOnly?: boolean;
}

const ShoppingCartTable: FC<IShoppingCartTable> = (props) => {
  const { cartItems = [], readOnly = false } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();
  const { onSelectedForOderChange } = useCart();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const isAllChecked = cartItems.length > 0 && selectedItems.length === cartItems.length;
  //await è necessario anche se ts suggerisce di non usarlo
  const toggleAll = async () => {
    if (isAllChecked) {
      setSelectedItems([]);
      for (const cartItem of cartItems) {
        await onSelectedForOderChange(cartItem, false);
      }
    } else {
      const allItemIds = cartItems.map((cartItem) => cartItem.id);
      setSelectedItems(allItemIds);
      for (const cartItem of cartItems) {
        await onSelectedForOderChange(cartItem, true);
      }
    }
  };

  const handleItemChange = (item: any, isChecked: boolean) => {
    setSelectedItems((prevSelected) =>
      isChecked ? [...prevSelected, item.id] : prevSelected.filter((id) => id !== item.id),
    );
    onSelectedForOderChange(item, isChecked);
  };
  //Preseleziono gli item già selezionati sul BE
  useEffect(() => {
    const initiallySelected = cartItems.filter((item) => item.selectedForOrder).map((item) => item.id);
    setSelectedItems(initiallySelected);
  }, [cartItems]);
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle ">
          <div className="overflow-hidden border-gray-200  border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <CheckAll onChange={toggleAll} isAllChecked={isAllChecked} />
                  </th>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only">{t('DETAILS')}</span>
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('EXCERPT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {cartItems.map((cartItem, index) => {
                  return (
                    <ShoppingCartRow
                      key={index}
                      cartItem={cartItem}
                      readOnly={readOnly}
                      selectedItems={selectedItems}
                      handleItemChange={handleItemChange}
                      tecdocManufacturers={tecdocManufacturers}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartTable;
