import { FC, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRODUCT_DETAIL_PATHNAME } from '../../../App';
import { useSettings } from '../../../providers/SettingsProvider';
import { IQuoteItem } from '../../../lib/fetch/quotes';
import { getThumbnail } from '..';
import { getTecdocManufacturerById } from '../../../lib/utils/manufacturers';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import ConfirmModal from '../../Modals/ConfirmModal';
import { quantityInputProps as _quantityInputProps } from '../../../lib/utils';
import { useAuth } from '../../../providers/AuthProvider';
import classNames from 'classnames';
import { useCart } from '../../../providers/CartProvider';

interface IProductsListTable {
  isCompleted?: boolean;
  items: IQuoteItem[];
  readOnly?: boolean;
}

const ProductsListTable: FC<IProductsListTable> = (props) => {
  const [{ user }] = useAuth();
  const { isCompleted = false, items = [] } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();
  const { addToCart } = useCart();
  const itemsWithQuantityProps = items.map((item) => ({
    ...item,
    quantityInputProps: _quantityInputProps(item.product.data, user),
  }));
  const { control } = useFormContext();

  const { remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [removeItemsModalOpen, setRemoveItemsModalOpen] = useState(false);
  const [removeItems, setRemoveItems] = useState<number[]>([]);

  return (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle ">
            <div className="overflow-hidden border-gray-200  border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t('PRODUCT')}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t('BRAND')}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t('PRICE')}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t('PRICE_NET')}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t('QTY')}
                    </th>

                    {!isCompleted && (
                      <th
                        scope="col"
                        className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t('ACTION')}
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {itemsWithQuantityProps.map((item, index) => (
                    <Disclosure key={index}>
                      {({ open }) => (
                        <>
                          <tr className="border-t">
                            <td className="whitespace-nowrap py-2 pl-2 pr-4">
                              <div className="flex items-center">
                                {getThumbnail(item.product.data.tecdoc?.images[0]?.imageURL3200)}
                                <Link to={`${PRODUCT_DETAIL_PATHNAME}/${item.product.data.id}`} className="ml-3">
                                  <div className="text-sm font-medium text-gray-900">
                                    {item.product.data.attributes.code}
                                  </div>
                                  <div className="text-sm font-normal text-gray-400">
                                    {item.product.data.attributes.description}
                                  </div>
                                </Link>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-4 py-2">
                              {item.product.data.tecdoc?.dataSupplierId &&
                              getTecdocManufacturerById(
                                item.product.data.tecdoc?.dataSupplierId,
                                tecdocManufacturers,
                              ) ? (
                                <img
                                  className="h-auto w-20"
                                  src={
                                    getTecdocManufacturerById(
                                      item.product.data.tecdoc?.dataSupplierId,
                                      tecdocManufacturers,
                                    )!.dataSupplierLogo.imageURL800
                                  }
                                  alt=""
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-700 font-semibold">
                              {item.product.data.attributes.price_list
                                ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(
                                    item.product.data.attributes.price_list,
                                  )
                                : '-'}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-700 font-semibold">
                              {item.product.data.attributes.price_net
                                ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(
                                    item.product.data.attributes.price_net,
                                  )
                                : '-'}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-700 font-semibold">
                              <div key={item.id}>
                                <Controller
                                  name={`items.${index}.quantity`}
                                  control={control}
                                  defaultValue={1}
                                  render={({ field }) => (
                                    <input
                                      type="number"
                                      className="mr-2 block w-20 rounded-md border-transparent bg-gray-100 py-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm min-w-16"
                                      min={1}
                                      {...field}
                                      onChange={(e) => field.onChange(parseInt(e.target.value || '1', 10))}
                                    />
                                  )}
                                />
                              </div>
                            </td>

                            {!isCompleted && (
                              <td className="whitespace-nowrap px-4 py-2 flex items-center justify-end gap-2">
                                <ShoppingCartIcon
                                  className={classNames(
                                    'h-7 w-7 p-1 rounded-full',
                                    item.quantityInputProps.disabled
                                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed opacity-50'
                                      : 'bg-blue-100 text-blue-500 cursor-pointer hover:bg-blue-200',
                                  )}
                                  aria-hidden="true"
                                  onClick={() => {
                                    if (!item.quantityInputProps.disabled) {
                                      addToCart({
                                        product: item.product.data,
                                        quantity: item.quantity,
                                        displayAddToCartModal: true,
                                      });
                                    }
                                  }}
                                />
                                <XMarkIcon
                                  className="h-6 w-6 text-red-500 cursor-pointer hover:text-red-600"
                                  aria-hidden="true"
                                  onClick={() => {
                                    setRemoveItemsModalOpen(true);
                                    setRemoveItems([index]);
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </tbody>
              </table>
              {items.length === 0 && (
                <p className="text-center text-gray-400 my-10 w-full ">{t('QUOTE:NO_PRODUCT_SAVED')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={removeItemsModalOpen}
        onRequestClose={() => setRemoveItemsModalOpen(false)}
        onConfirm={() => {
          remove(removeItems);
          setRemoveItemsModalOpen(false);
        }}
        title={t('QUOTE:REMOVE_PRODUCT')}
        message={t('QUOTE:REMOVE_PRODUCT_DESCRIPTION')}
        confirmText={t('QUOTE:REMOVE_BTN')}
      />
    </>
  );
};

export default ProductsListTable;
