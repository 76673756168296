import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../../../../lib/constants/regexp';
import IResetTemporaryPasswordForm from '../interfaces/resetPasswordInAppForm';

const resetTemporaryPasswordValidator: yup.ObjectSchema<IResetTemporaryPasswordForm> = yup
  .object({
    password: yup.string().required('COMMON:REQUIRED').matches(PASSWORD_REGEXP, 'SECURITY:WEAK_PASSWORD'),
    repeatPassword: yup.string().oneOf([yup.ref('password'), undefined], 'SECURITY:PASSWORDS_MUST_COINCIDE'),
  })
  .required();

export default resetTemporaryPasswordValidator;
