import { DevicePhoneMobileIcon, EnvelopeIcon, EyeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { setCustomer } from '../../../lib/fetch/agent';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Filters from './Filters';
import axios from 'axios';
import { ICustomerForAgent, ICustomerForAgentResponse } from '../../../lib/interfaces/ICustomerForAgent';
import { CustomerDetailsModal } from '../../../components/CustomerDetails';
import { AgentPromotionsDetailsModal } from '../../../components/AgentPromotions';
import { Pagination } from '../../../components/ProductsTable';
import { useTranslation } from 'react-i18next';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { currency } from '../../../lib/utils';

const { REACT_APP_AGENT_GET_CUSTOMERS_URL, REACT_APP_DEFAULT_AGENT_PAGE_SIZE } = process.env;

const CustomersList = () => {
  const [data, setData] = useState<ICustomerForAgent[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_AGENT_PAGE_SIZE! || 100);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation('CUSTOMER_LIST');
  const { t: tCommon } = useTranslation('COMMON');

  const [filters, setFilters] = useState({
    customer_id: '',
    address: '',
    name: '',
    vat_num: '',
    active: '',
  });
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailForModal, setDetailForModal] = useState<ICustomerForAgent>();
  const [promotionsForModal, setPromotionsForModal] = useState<ICustomerForAgent>();
  const [isPromotionsModalOpen, setIsPromotionsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [{ token }, dispatchAuthChange] = useAuth();

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      fetchData();
    }, 1000);

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  useEffect(() => {
    handlePageChange({
      page: page.toString(),
      pageSize: pageSize.toString(),
    });
  }, [page]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get<ICustomerForAgentResponse>(REACT_APP_AGENT_GET_CUSTOMERS_URL!, {
        params: filters,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.customersWithPromotions ?? []);
      const { page, pageSize, pageCount, total } = response.data.pagination;
      setPage(page);
      setPageSize(pageSize);
      setPageCount(pageCount);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPage(1);
  };

  const handlePageChange = (newFilters: { [key: string]: string } = {}) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const openPromotionsModal = async (item: ICustomerForAgent) => {
    setPromotionsForModal(item);
    setIsPromotionsModalOpen(true);
  };

  const closePromotionsModal = () => {
    setIsPromotionsModalOpen(false);
  };

  const openDetailModal = (item: ICustomerForAgent) => {
    setDetailForModal(item);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const impersonateCustomer = async (customerId: number) => {
    const { data: updatedUser } = await setCustomer(token!, customerId);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    navigate('/search-results');
  };

  return (
    <>
      <FullScreenLoading loading={isLoading} title={tCommon('LOADING')} subtitle={tCommon('LOADING_SUBTITLE')} />
      <div className="overflow-x-auto m-8">
        <Filters filters={filters} onFilterChange={handleFilterChange} />
        <CustomerDetailsModal
          isOpen={isDetailModalOpen}
          onRequestClose={closeDetailModal}
          customerDetailsProps={detailForModal}
        />
        <AgentPromotionsDetailsModal
          isOpen={isPromotionsModalOpen}
          onRequestClose={closePromotionsModal}
          promotionsDetailsProps={promotionsForModal}
        />
        <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
          <thead className="bg-gray-50 text-gray-500">
            <tr className="rounded">
              <th className="px-2 py-2">{t('INFOS')}</th>
              <th className="px-2 py-2">{t('CONTACTS')}</th>
              <th className="px-4 py-2">{t('ADDRESS')}</th>
              <th className="px-4 py-2">{t('CATEGORY')}</th>
              <th className="px-4 py-2">{t('STATE')}</th>
              <th className="px-4 py-2">{t('NUMBER_ORDERS')}</th>
              <th className="px-4 py-2">{t('AMOUNT_ORDERS')}</th>
              <th className="px-4 py-2">{t('TRUST')}</th>
              <th className="px-4 py-2">{t('CLIENT_PROMOTIONS')}</th>
              <th className="px-4 py-2">{t('AGENT_PROMOTIONS')}</th>
              <th className="px-4 py-2">{t('ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border">
                <td className="px-4 py-2">
                  <p className="text-sm text-gray-400">
                    {item?.customer_id ? `${t('CODE')}: ${item.customer_id}` : ''}
                  </p>
                  <p className="text-sm font-bold">{item?.name}</p>
                  <p className="text-sm text-gray-400">{item?.vat_num ? `${t('P_IVA')}: ${item.vat_num}` : ''}</p>
                  <p className="text-sm text-gray-400">
                    {item?.fiscal_code ? `${t('FISCAL_CODE')}: ${item.fiscal_code}` : ''}
                  </p>
                </td>
                <td className="px-4 py-2 text-sm text-gray-400">
                  <div className="flex flex-col align-center my-2">
                    <div className="flex flex-row">
                      <PhoneIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                      <p className="text-sm text-gray-400">{item?.phone || t('NO_DATA')}</p>
                    </div>
                    <div className="flex flex-row">
                      <DevicePhoneMobileIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                      <p className="text-sm text-gray-400">{item?.telex || t('NO_DATA')}</p>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2 text-sm text-gray-400">
                  <div className="flex align-center my-2">
                    <MapPinIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                    {`${item?.address ? `${item.address},` : ''} ${item?.zipcode || ''} ${item?.city || ''} ${item?.province ? `(${item.province})` : ''} ${item?.country ? ` - ${item.country}` : ''}`}
                  </div>
                </td>
                <td className="px-4 py-2 text-center">
                  <span className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full text-xs mr-2 mb-2 whitespace-nowrap inline-block">
                    {item.customer_class_id?.name}
                  </span>
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {item.active ? (
                    <>
                      <span className="text-green-500 text-2xl mr-2">●</span>
                      <span className="text-green-500">{t('ACTIVE')}</span>
                    </>
                  ) : (
                    <>
                      <span className="text-red-500 text-2xl mr-2">●</span>
                      <span className="text-red-500">{t('INACTIVE')}</span>
                    </>
                  )}
                </td>
                <td className="px-4 py-2 text-center">
                  <span className="bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold">
                    {item?.orders?.totalOrders || 0}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <span className="text-gray-400 py-1 px-3 w-7 h-7 text-xs inline-flex items-center justify-center font-bold">
                    {currency(item?.orders?.totalSpent || 0)}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <span className=" text-gray-400 py-1 px-3 w-7 h-7 text-xs inline-flex items-center justify-center font-bold">
                    {item?.fido ? `${currency(item.fido)}` : t('NO_DATA')}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <span
                    className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                      (item.promotions.customer_promotions?.length ?? 0) === 0
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => (item.promotions.customer_promotions?.length ?? 0) > 0 && openPromotionsModal(item)}
                  >
                    {item.promotions.customer_promotions?.length ?? 0}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <span
                    className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                      (item.promotions.agent_promotions?.length ?? 0) === 0
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => (item.promotions.agent_promotions?.length ?? 0) > 0 && openPromotionsModal(item)}
                  >
                    {item.promotions.agent_promotions?.length ?? 0}
                  </span>
                </td>
                {/*<td className="px-4 py-2">
                {item.clusterList.map((cluster, i) => (
                  <div key={i} className="text-gray-400">
                    {cluster}
                  </div>
                ))}
              </td>*/}
                <td className="px-4 py-2 flex">
                  <button className="bg-gray-200 text-black rounded-md p-2 mr-2" onClick={() => openDetailModal(item)}>
                    <EyeIcon className="h-5 w-5" />
                  </button>
                  <button className="bg-red-500 text-white rounded-md p-2" onClick={() => impersonateCustomer(item.id)}>
                    <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
            <Pagination
              currentItems={data}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              page={page}
              pageCount={pageCount}
              pageSize={pageSize}
              total={total}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomersList;
