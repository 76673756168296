import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
// import Grey200Button from '../../Buttons/Grey200Button';
// import { InputPresentational } from '../../Form';
import { IAddress } from '../../lib/interfaces';
import { COUNTRIES, currency } from '../../lib/utils';

interface ITotals {
  subTotal: number;
  totalDiscount: number;
  totalTaxes: number;
  totalShipping: number;
  subTotalNet: number;
  total: number;
}

interface ISubtotalPresentational {
  shippingAddress: IAddress | null | undefined;
  totals: ITotals;
}

const SubtotalPresentational: FC<ISubtotalPresentational> = (props) => {
  const { totals } = props;
  const { subTotal, totalDiscount, totalTaxes, totalShipping, subTotalNet, total } = totals;

  const [{ user }] = useAuth();

  const { addresses = [] } = user || {};
  const shippingAddress = props.shippingAddress || addresses.find(({ deliveryDefault }) => deliveryDefault);
  const shippingCountryCode = shippingAddress && shippingAddress.country;
  let taxName = shippingCountryCode && COUNTRIES.find((country) => country.value === shippingCountryCode)?.taxName;

  const { t } = useTranslation('PRODUCTS');
  taxName = taxName || t('TAXES');

  return (
    <div className="border-t border-gray-200 px-4 ">
      {/* TODO: handle promotion code */}
      {/*<div className="flex items-end pt-4">*/}
      {/*  <div className="flex-1 pr-3">*/}
      {/*    <InputPresentational*/}
      {/*      label={t('PROMOTION_CODE')}*/}
      {/*      name="promotionCode"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <Grey200Button text={t('COMMON:APPLY')} />*/}
      {/*</div>*/}
      <dl className="space-y-6 pt-6 text-sm font-medium text-gray-500">
        <div className="flex justify-between">
          <dt>{t('SUBTOTAL_LIST')}</dt>
          <dd className="text-gray-900">{currency(subTotal)}</dd>
        </div>

        {totalDiscount ? (
          <div className="flex justify-between">
            <dt>{t('DISCOUNT')}</dt>
            <dd className="text-gray-900">- {currency(totalDiscount)}</dd>
          </div>
        ) : null}

        <div className="flex justify-between">
          <dt>{t('SUBTOTAL_NET')}</dt>
          <dd className="text-gray-900">{currency(subTotalNet)}</dd>
        </div>

        {/*         <div className="flex justify-between">
          <dt>{taxName}</dt>
          <dd className="text-gray-900">{currency(totalTaxes)}</dd>
        </div>

        <div className="flex justify-between">
          <dt>{t('SHIPPING')}</dt>
          <dd className="text-gray-900">{currency(totalShipping)}</dd>
        </div> */}

        <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
          <dt className="text-lg">{t('TOTAL')}</dt>
          <dd className="text-lg">{currency(total)}</dd>
        </div>
      </dl>
    </div>
  );
};

export default SubtotalPresentational;
