import { useEffect } from 'react';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../lib/interfaces';
import SofinnInfoNewUsernamePresentational from './SofinnInfoNewUsernamePresentational';
import { useMutation } from 'react-query';
import aknowledgeSofinnUserForNewCredentials from '../../../lib/fetch/sofinn/auth/aknowledgeSofinnUserForNewCredentials';

const SofinnInfoNewUsername: React.FC = () => {
  const navigate = useNavigate();
  const [{ sofinnUser, sofinnToken }, dispatchAuthChange] = useAuth();

  const handleRedirect = ({
    _sofinnToken,
    _sofinnUser,
  }: {
    _sofinnToken: string | null;
    _sofinnUser?: IUser | null;
  }) => {
    if (!_sofinnUser || !_sofinnToken) {
      dispatchAuthChange({ type: AuthActionType.Logout });
      navigate('/cati-b2b/login');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!sofinnToken && !sofinnUser) {
        navigate('/cati-b2b/login');
      }
    }, 10000);

    if (sofinnToken) {
      clearInterval(timer);
      handleRedirect({ _sofinnToken: sofinnToken, _sofinnUser: sofinnUser });
    }
  }, [sofinnUser]);

  const { mutate, isLoading, isError } = useMutation('sofinnUserInformed', aknowledgeSofinnUserForNewCredentials, {
    onSuccess: () => {
      navigate('/cati-b2b/question-reset-password');
    },
  });

  const handleClick = () => {
    if (sofinnToken) mutate(sofinnToken);
  };

  if (!sofinnUser) {
    return <></>;
  }

  return (
    <SofinnInfoNewUsernamePresentational
      isLoading={isLoading}
      handleClick={handleClick}
      username={sofinnUser?.username}
      isError={isError}
    />
  );
};

export default SofinnInfoNewUsername;
