import { FC } from 'react';

interface ICheckAll {
  readOnly?: boolean;
  onChange: () => void;
  isAllChecked: boolean;
}

const CheckAll: FC<ICheckAll> = ({ onChange, isAllChecked = false }) => {
  return (
    <input
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
      checked={isAllChecked}
      onChange={onChange}
    />
  );
};

export default CheckAll;
