import { StrapiSingleEntityResponse } from '../../interfaces/ICommons';
import { IQuote, IQuoteAttributes } from '.';
import axios from 'axios';

const axiosInstance = axios.create();
const { REACT_APP_BACKEND_URL } = process.env;
const updateQuote = async (token: string, id: string, data: IQuoteAttributes) => {
  const response = await axiosInstance.put<StrapiSingleEntityResponse<IQuote>>(
    `${REACT_APP_BACKEND_URL!}/quotes/${id}`,
    { data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export default updateQuote;
