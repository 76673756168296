import SofinnResetPasswordFeedbackMessagePresentational from './SofinnResetPasswordFeedbackMessagePresentational';
import { useNavigate } from 'react-router-dom';

const SofinnResetPasswordFeedback: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/cati-b2b/login');
  };

  return <SofinnResetPasswordFeedbackMessagePresentational onRedirect={handleRedirect} />;
};

export default SofinnResetPasswordFeedback;
