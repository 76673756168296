import { useEffect, useState } from 'react';
import SearchResultsPresentational from './SearchResultsPresentational';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import { getCrossReferences, IProduct, search as callSearchProducts, IPromotion } from '../../lib/fetch/products';
import i18n from '../../i18n';
import { useQuery } from 'react-query';
import fetchPromotions from '../../lib/fetch/promotions/list';
import { AxiosResponse } from 'axios';
import { useCart } from '../../providers/CartProvider';

const { REACT_APP_DEFAULT_PAGE_SIZE } = process.env;

const SearchResults = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const { t } = useTranslation('PRODUCTS');
  const lang = i18n.languages[0];
  const [addToCart, setAddToCart] = useQueryParam('addToCart', BooleanParam);
  const [search] = useQueryParam('search', StringParam);
  const [where] = useQueryParam('where', StringParam);
  const [sort] = useQueryParam('sort', StringParam);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_PAGE_SIZE! || 25);
  const [products, setProducts] = useState<IProduct[]>([]);
  const {
    cartState: { isLoading },
    addToCart: onAddToCart,
  } = useCart();

  const searchProducts = async (search: string, where?: string | null) => {
    setLoading(true);
    const { data: axiosData, error } = await callSearchProducts(token!, {
      page,
      pageSize,
      search,
      sort: sort!,
      where,
      lang,
      addToCart,
    });

    if (error || !axiosData) {
      setLoading(false);
      setProducts([]);
      return dispatchAlertChange({ open: true });
    }
    const {
      data = [],
      meta: { pagination },
    } = axiosData;
    const { pageCount = 1, total = 0 } = pagination;
    setPageCount(pageCount);
    setTotal(total);
    setProducts(data);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (!addToCart) return setLoading(false);

    setAddToCart(false); // Reset to prevent re-adding on page reload
    if (data.length > 1) {
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('ADD_TO_CART_MULTIPLE_ERROR'),
      });
    }
    const [onlyResult] = data;
    if (!onlyResult) {
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('NO_RESULT'),
      });
    }
    dispatchCartOverlayChange({ open: false });
    try {
      await onAddToCart({ product: onlyResult, quantity: 1, displayAddToCartModal: true });
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('ADD_TO_CART_ERROR'),
      });
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useDebouncedCallback(searchProducts, 100);

  useEffect(() => {
    setPage(1);
  }, [pageSize, search, sort, where]);

  useEffect(() => {
    if (isLoading || loading) return;
    search && sort ? debouncedSearch(search, where) : setProducts([]);
  }, [addToCart, page, pageSize, search, sort, where]);

  const onFetchCrossReferences = async (productId: number): Promise<IProduct[]> => {
    const { error, data: responseData } = await getCrossReferences(token!, productId, lang);
    if (error || !responseData.data) {
      return [];
    }
    return responseData.data;
  };

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const { data: promotions } = useQuery<AxiosResponse<IPromotion[]>>('promotions', () => fetchPromotions(token), {
    onSuccess,
    onError,
  });

  return (
    <>
      <SearchResultsPresentational
        adding={isLoading}
        loading={loading}
        onFetchCrossReferences={onFetchCrossReferences}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        products={products}
        page={page}
        pageCount={pageCount}
        pageSize={pageSize}
        total={total}
        search={search as string}
        where={where}
        promotions={promotions?.data}
      />
    </>
  );
};

export default SearchResults;
