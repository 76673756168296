import axios, { AxiosResponse } from 'axios';
import ICustomer from '../../interfaces/ICustomer';
const instance = axios.create();

const { REACT_APP_UPDATE_CUSTOMER_URL } = process.env;

const updateCustomer = async (token: string, updatedCustomer: Partial<ICustomer>) => {
  const response = await instance.put<ICustomer>(
    `${REACT_APP_UPDATE_CUSTOMER_URL!}/${updatedCustomer.id}`,
    { data: updatedCustomer },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default updateCustomer;
