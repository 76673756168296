import qs from 'qs';
import i18n from '../../../i18n';
import axios from 'axios';
import { StrapiListEntityResponse } from '../../interfaces/ICommons';
import { IPurchasedProduct } from '../orderItems';

const axiosInstance = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

interface SearchParams {
  page: number | undefined;
  limit?: number;
  sort?: string | string[];
  filters?: object;
}

const fetchPurchasedProducts = async (token: string | null, params: SearchParams) => {
  const { page = 1, limit = 6, sort, filters } = params;
  const pagination = { page, pageSize: limit };
  const lang = i18n.languages[0];

  const query = qs.stringify(
    {
      pagination,
      sort,
      filters,
      locale: lang,
    },
    { encodeValuesOnly: true },
  );

  const headers = { Authorization: `Bearer ${token}` };

  const data = await axiosInstance.get<StrapiListEntityResponse<IPurchasedProduct>>(
    `${REACT_APP_BACKEND_URL!}/orders/purchased-products?${query}`,
    { headers },
  );

  return data.data;
};

export default fetchPurchasedProducts;
