import { useTranslation } from 'react-i18next';

interface Props {
  message: string;
}

const RedirectPresentational: React.FC<Props> = (props) => {
  const { message } = props;
  const { t } = useTranslation('REDIRECT');
  return (
    <div className="flex w-full justify-center h-screen">
      <div className="flex my-auto flex-row w-full justify-center">
        <span className="text-center p-6">{t(message)}</span>
      </div>
    </div>
  );
};

export default RedirectPresentational;
