import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../../lib/utils';
import Address from '../../Profile/components/Addresses/AddressBox/Address';
import { IAddress } from '../../../lib/interfaces';
import { AddressType } from '../index';

interface IRadioGroupAddressBox {
  type: AddressType;
  addresses: IAddress[];
  loading?: boolean;
  selectedAddress: IAddress | undefined;
  onAddressChange: (_: IAddress, type: AddressType) => void;
}

const RadioGroupAddressBox: FC<IRadioGroupAddressBox> = (props) => {
  const {
    type,
    addresses,
    loading = false,
    selectedAddress,
    onAddressChange = (_: IAddress, _type: AddressType) => {},
  } = props;

  return (
    <RadioGroup
      disabled={loading}
      value={selectedAddress}
      onChange={(address: IAddress) => onAddressChange(address, type)}
    >
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        {addresses.map((address) => (
          <RadioGroup.Option
            key={address.id}
            value={address}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-red-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white shadow-sm focus:outline-none',
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex w-full flex-col">
                  <div className="flex w-full items-center justify-between border-b px-4 py-3">
                    <RadioGroup.Label as="span" className="block text-base font-semibold text-gray-900">
                      {address.name}
                    </RadioGroup.Label>
                    <CheckCircleIcon
                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-red-600')}
                      aria-hidden="true"
                    />
                  </div>

                  <div className="p-4">
                    <Address address={address} />
                  </div>
                </div>

                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-red-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupAddressBox;
