import { useTranslation } from 'react-i18next';
import ResetPasswordInAppForm from './ResetPasswordInAppForm';
import IResetPasswordInAppForm from './interfaces/resetPasswordInAppForm';

interface Props {
  onSubmit: (formValues: IResetPasswordInAppForm) => void;
  isLoading: boolean;
}

const SecurityPresentational: React.FC<Props> = (props) => {
  const { t } = useTranslation('SECURITY');
  const { onSubmit, isLoading } = props;

  return (
    <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-800">{t('CHANGE_PASSWORD')}</h3>
      <ResetPasswordInAppForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  );
};

export default SecurityPresentational;
