import axios, { AxiosResponse } from 'axios';
import { IOrder } from '.';

const { REACT_APP_ORDERS_URL } = process.env;

const getOne = async (token: string, id: number) => {
  const response = await axios.get<{ data: IOrder }>(`${REACT_APP_ORDERS_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      populate: '*',
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getOne;
