import axios, { AxiosResponse } from 'axios';

export interface ICommercialStats {
  amount: number;
  label: string;
  amount_last_year: number;
}

const { REACT_APP_ORDERS_URL } = process.env;

interface IGetCommercialStats {
  data: ICommercialStats[];
}

const getCommercialStats = async (token: string, lang: string, monthlyView: boolean) => {
  const response = await axios.get<IGetCommercialStats>(`${REACT_APP_ORDERS_URL!}/commercialStats`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { lang, monthlyView },
  });
  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getCommercialStats;
