import axios from 'axios';

const instanceAxios = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

const url = `${REACT_APP_BACKEND_URL}/auth/sofinn/v2/acknowledge-new-credentials`;

const aknowledgeSofinnUserForNewCredentials = async (token: string) => {
  const headers = { Authorization: `Bearer ${token}` };
  return await instanceAxios.post(url, {}, { headers });
};

export default aknowledgeSofinnUserForNewCredentials;
