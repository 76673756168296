import { useTranslation } from 'react-i18next';
import { ITecdocProduct } from '../../../../lib/fetch/products';

interface IOemCodesProps {
  product: { id: number; tecdoc?: ITecdocProduct };
}

const OemCodes = (props: IOemCodesProps) => {
  const { product } = props;
  const { t } = useTranslation('PRODUCTS');

  const tecdocOemCodes = product.tecdoc?.oemNumbers || [];

  return !!tecdocOemCodes.length ? (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="flex-1">
        <h3 className="text-md mb-4 font-semibold text-gray-600">{t('OEM_CODES')}</h3>
        <div className="text-xs">
          <div className="w-full rounded-md border border-gray-200 bg-white py-2 font-medium">
            <dl className="divide-y divide-gray-200">
              {tecdocOemCodes.map(({ articleNumber, mfrName }, key) => (
                <div key={key} className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="capitalize text-gray-500">{mfrName}</dt>
                  <dd
                    className="mt-1 capitalize text-gray-900 sm:col-span-2 sm:mt-0"
                    dangerouslySetInnerHTML={{ __html: articleNumber }}
                  />
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{t('DETAILS_CURRENTLY_NOT_AVAILABLE')}</>
  );
};

export default OemCodes;
