import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../Buttons';
import Lottie from 'react-lottie-player';
import orderPackedLottie from '../../lotties/order-packed.json';

interface IOrderSuccess {
  wireTransfer: boolean;
}

const OrderSuccess = ({ wireTransfer }: IOrderSuccess) => {
  const { t } = useTranslation('SHOPPING_CART');
  return (
    <div className="w-full bg-gray-50 px-12 pt-10 pb-20">
      <div className="flex w-full flex-col items-center justify-center">
        <Lottie loop animationData={orderPackedLottie} play style={{ width: 300, height: 300 }} />

        <h1 className="mb-8 text-center text-4xl font-bold text-green-700">{t('THANK_YOU_TITLE')}</h1>

        <p className="mb-8 text-center text-sm text-gray-600">
          <Trans i18nKey="SHOPPING_CART:THANK_YOU_DESCRIPTION">
            Your order has been processed correctly.
            <br />
            We will send you an email as soon as the courier takes charge of your package.
            <br />
            <br />
            You can always track your shipment in
            <Link to="/my-orders" className="font-semibold text-gray-900 underline underline-offset-2">
              “My orders”
            </Link>
            section!
          </Trans>
        </p>
        {/*         {wireTransfer && (
          <p className="mb-8 text-center text-sm text-gray-600">
            <i>{t('THANK_YOU_WIRE_TRANSFER_INFO')}</i>
          </p>
        )} */}
        <Link to="/search-results">
          <PrimaryButton text={t('CONTINUE_SHOPPING')} />
        </Link>
      </div>
    </div>
  );
};

export default OrderSuccess;
