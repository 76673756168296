import FooterSofinn from '../../../components/Sofinn/FooterSofinn';
import { ISofinnUpdateProfileForm } from '../../../lib/interfaces/ISofinn';
import SofinnUpdateProfileForm from './SofinnUpdateProfileForm';

interface Props {
  onUpdateSofinnProfile: (values: ISofinnUpdateProfileForm) => void;
  onRedirect: () => void;
  isError: boolean;
}

const SofinnUpdateProfilePresentational: React.FC<Props> = (props) => {
  const { onUpdateSofinnProfile, onRedirect, isError } = props;
  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>

        <SofinnUpdateProfileForm
          onRedirect={onRedirect}
          isError={isError}
          onUpdateSofinnProfile={onUpdateSofinnProfile}
        />
      </div>

      <FooterSofinn />
    </div>
  );
};

export default SofinnUpdateProfilePresentational;
