import { AuthAction, AuthActionType } from '../../../../providers/AuthProvider';

const { REACT_APP_BACKEND_URL } = process.env;

interface Props {
  sofinnToken: string | null;
  dispatchAuthChange: (_action: AuthAction) => void;
}

const redirectToSofinnV2 = (props: Props) => {
  const { sofinnToken, dispatchAuthChange } = props;
  const url = `${REACT_APP_BACKEND_URL}/auth/sofinn/v2/redirect?token=${sofinnToken}`;
  dispatchAuthChange({ type: AuthActionType.LogoutSofinn });
  window.location.replace(url);
};

export default redirectToSofinnV2;
