import { FC, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { quantityInputProps } from '../../../lib/utils';
import { IProduct } from '../../../lib/fetch/products';
import { useAuth } from '../../../providers/AuthProvider';
import { useCart } from '../../../providers/CartProvider';

interface IEditField {
  cartItem: ICartItem;
  readOnly?: boolean;
  setRequestedQuantity: (quantity: number) => void;
}

const EditField: FC<IEditField> = (props) => {
  const [{ user }] = useAuth();
  const { cartItem, readOnly = false, setRequestedQuantity } = props;
  const { product, quantity } = cartItem;
  const {
    max: maxQuantity,
    min: minQuantity,
    disabled: isUnavailable,
  } = quantityInputProps({ attributes: product } as unknown as IProduct, user);
  const { onItemQuantityChange, onItemRemove } = useCart();
  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { quantity },
  });
  const onSubmit = handleSubmit(
    useDebouncedCallback(({ quantity: newQuantity }) => {
      setRequestedQuantity(+newQuantity || quantity);
      onItemQuantityChange({ cartItem, newQuantity: +newQuantity || quantity });
    }, 800),
  );

  useEffect(() => {
    setValue('quantity', cartItem.quantity);
  }, [cartItem]);

  useEffect(
    () =>
      watch((formData, { name, type }) => {
        name === 'quantity' && type === 'change' && onSubmit();
      }).unsubscribe,
    [watch],
  );

  return (
    <form className="flex items-center justify-end" onSubmit={onSubmit}>
      <input
        type="number"
        className="mr-2 block w-full w-20 rounded-md border-transparent bg-gray-100 py-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm min-w-16	"
        max={maxQuantity}
        min={minQuantity}
        step="1"
        disabled={isUnavailable || cartItem.price === 0 || readOnly}
        required
        {...register('quantity', {
          max: maxQuantity,
          min: minQuantity,
          disabled: isUnavailable || cartItem.price === 0 || readOnly,
          required: true,
        })}
      />
      <button
        type="button"
        className="bg-trasparent flex items-center justify-center rounded-full border border-transparent text-sm font-normal text-red-600 shadow-sm hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        onClick={() => onItemRemove(cartItem)}
        disabled={cartItem.price === 0 || readOnly}
      >
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </form>
  );
};

export default EditField;
