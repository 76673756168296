import { Routes, Route } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './components/Auth';
import ActivationRequest from './pages/ActivationRequest';
import EditProfile from './pages/EditProfile';
import SearchResults from './pages/SearchResults';
import ProductDetail from './pages/ProductDetail';
import ShoppingCart from './pages/ShoppingCart';
import CheckoutShipping from './pages/CheckoutShipping';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutResult from './pages/CheckoutResult';
import MyOrders from './pages/MyOrders';
import Faq from './pages/Faq';
import Forgot from './pages/Forgot';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import Reset from './pages/Reset';
import Shipping from './pages/Shipping';
import TermsConditions from './pages/TermsConditions';
import ThankYouRegistration from './pages/ThankYouRegistration';
import './App.css';
import Agent from './pages/Agent';
import Rva from './pages/Rva';
import AgentCart from './pages/AgentCart';
import SofinnLogin from './pages/Sofinn/SofinnLogin';
import SofinnQuestionResetPassword from './pages/Sofinn/SofinnQuestionResetPassword';
import SofinnUpdateProfile from './pages/Sofinn/SofinnUpdateProfile';
import SofinnPrivateRoute from './components/Auth/SofinnPrivateRoute';
import SofinnForgotPassword from './pages/Sofinn/SofinnForgotPassword';
import SofinnForgotPasswordFeedback from './pages/Sofinn/SofinnForgotPasswordFeedback';
import SofinnResetPassword from './pages/Sofinn/SofinnResetPassword';
import SofinnResetPasswordFeedback from './pages/Sofinn/SofinnResetPasswordFeedback';
import ResetTemporaryPassword from './pages/Profile/components/ResetTemporaryPassword';
import Redirect from './pages/Redirect';
import SofinnResetTemporaryPassword from './pages/Sofinn/SofinnResetTemporaryPassword';
import SofinnResetTemporaryPasswordFeedback from './pages/Sofinn/SofinnResetTemporaryPasswordFeedback';
import PurchasedProducts from './pages/PurchasedProducts';
import PromotionsList from './pages/Promotions/PromotionsList';
import { ROUTES } from './routes';
import SofinnInfoNewUsername from './pages/Sofinn/SofinnInfoNewUsername';
import RedirectSofinn from './pages/Sofinn/RedirectSofinn';
import Promotion from './pages/Promotions/Promotion';
import NewsCategories from './pages/NewsList';
import ProductsList from './pages/ProductsList';
import ProductsListDetail from './pages/ProductsListDetail';
import { RoleType } from './lib/enums';
import { useAuth } from './providers/AuthProvider';
import { isOrdersEnabled, isOrdersViewEnabled } from './lib/utils/userPermissions';

export const SEARCH_RESULTS_PATHNAME = '/search-results';
export const PRODUCT_DETAIL_PATHNAME = '/product-detail';
export const CHECKOUT_PAYMENT_PATHNAME = '/checkout-payment';
export const MY_ORDERS_PATHNAME = '/my-orders';

const App = () => {
  const [{ user }] = useAuth();
  return (
    <Routes>
      {/* Public routes, with redirect if user is authenticated */}
      <Route index element={<PublicRoute element={<Login />} />} />
      <Route path="/login" element={<PublicRoute element={<Login />} />} />
      {/* SOFINN ROUTES */}
      <Route path="/cati-b2b/login" element={<PublicRoute element={<SofinnLogin />} />} />
      <Route
        path="/cati-b2b/question-reset-password"
        element={<SofinnPrivateRoute element={<SofinnQuestionResetPassword />} publicRedirect="/cati-b2b/redirect" />}
      />
      <Route
        path="/cati-b2b/reset-password"
        element={<SofinnPrivateRoute element={<SofinnUpdateProfile />} publicRedirect="/cati-b2b/redirect" />}
      />
      <Route path="/cati-b2b/forgot-password" element={<PublicRoute element={<SofinnForgotPassword />} />} />
      <Route
        path="/cati-b2b/forgot-password-feedback"
        element={<PublicRoute element={<SofinnForgotPasswordFeedback />} />}
      />
      <Route path="/cati-b2b/reset" element={<PublicRoute element={<SofinnResetPassword />} />} />
      <Route
        path="/cati-b2b/reset-temporary-password"
        element={<SofinnPrivateRoute element={<SofinnResetTemporaryPassword />} publicRedirect="/cati-b2b/redirect" />}
      />
      <Route
        path="/cati-b2b/reset-temporary-password-feedback"
        element={<SofinnPrivateRoute element={<SofinnResetTemporaryPasswordFeedback />} />}
      />
      <Route path="/cati-b2b/reset-feedback" element={<PublicRoute element={<SofinnResetPasswordFeedback />} />} />
      <Route path={'/cati-b2b/redirect'} element={<PublicRoute element={<RedirectSofinn />} />} />
      <Route path={'/cati-b2b/info-new-username'} element={<PublicRoute element={<SofinnInfoNewUsername />} />} />
      {/* ******************************************************* */}
      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
      <Route path="/reset" element={<PublicRoute element={<Reset />} />} />
      <Route path="/activation-request" element={<PublicRoute element={<ActivationRequest />} />} />
      <Route path="/thank-you-registration" element={<PublicRoute element={<ThankYouRegistration />} />} />
      {/* Private routes, with redirect if user is NOT authenticated */}
      <Route path="/profile/edit" element={<PrivateRoute element={<EditProfile />} />} />
      <Route path="/profile/*" element={<PrivateRoute element={<Profile />} />} />
      <Route path="/profile/reset-temporary-password" element={<PrivateRoute element={<ResetTemporaryPassword />} />} />
      <Route path={SEARCH_RESULTS_PATHNAME} element={<PrivateRoute element={<SearchResults />} />} />
      <Route path={`${PRODUCT_DETAIL_PATHNAME}/:id`} element={<PrivateRoute element={<ProductDetail />} />} />
      <Route path="/shopping-cart" element={<PrivateRoute element={<ShoppingCart />} />} />
      <Route path="/products-list/" element={<PrivateRoute element={<ProductsList />} />} />
      <Route path="/products-list/:id" element={<PrivateRoute element={<ProductsListDetail />} />} />
      <Route path="/products-list/create" element={<PrivateRoute element={<ProductsListDetail />} />} />
      <Route
        path="/checkout-shipping"
        element={<PrivateRoute element={<CheckoutShipping />} hasUserRequiredOptions={isOrdersEnabled(user!)} />}
      />
      <Route
        path={MY_ORDERS_PATHNAME}
        element={<PrivateRoute element={<MyOrders />} hasUserRequiredOptions={isOrdersViewEnabled(user!)} />}
      />
      <Route
        path={CHECKOUT_PAYMENT_PATHNAME}
        element={<PrivateRoute element={<CheckoutPayment />} hasUserRequiredOptions={isOrdersEnabled(user!)} />}
      />
      <Route path={'/checkout-result'} element={<PrivateRoute element={<CheckoutResult />} />} />
      <Route path={'/news'} element={<PrivateRoute element={<NewsCategories />} />} />
      <Route path={ROUTES.PROMOTIONS.LIST} element={<PrivateRoute element={<PromotionsList />} />} />
      <Route path={ROUTES.PROMOTIONS.PROMO + '/:id'} element={<PrivateRoute element={<Promotion />} />} />

      <Route path={'/purchased-products'} element={<PrivateRoute element={<PurchasedProducts />} />} />
      {/* Agent routes */}
      <Route
        path={'/agent'}
        element={
          <PrivateRoute
            element={<Agent />}
            restrictedToRoles={[RoleType.AGENTE, RoleType.RVA]}
            hasUserRequiredOptions={user?.agent_id !== null} //L'rva puo' accedere solo se ha un agente associato
          />
        }
      />
      {/* RVA routes */}
      <Route path={'/rva'} element={<PrivateRoute element={<Rva />} restrictedToRoles={[RoleType.RVA]} />} />
      <Route
        path={'/agent-cart'}
        element={<PrivateRoute element={<AgentCart />} restrictedToRoles={[RoleType.RVA]} />}
      />
      {/* Public routes, without redirect */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/shipping" element={<Shipping />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/*" element={<NotFound />} />
      <Route path={'/redirect'} element={<PrivateRoute element={<Redirect />} />} />
    </Routes>
  );
};

export default App;
