import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import { FixedAlert } from '../../components/Alert';
import BackButton from '../../components/Buttons/BackButton';
import { AlertType } from '../../providers/AlertProvider';

interface IForgotPresentational {
  loading: boolean;
  result?: AlertType;
  resultTitle?: string;
  resultDescription?: string;
  onForgot: (formData: { identifier: string }) => void;
}

const ForgotPresentational = (props: IForgotPresentational) => {
  const {
    loading = false,
    result,
    resultTitle,
    resultDescription,
    onForgot = (_: { identifier: string }) => {},
  } = props;
  const { t } = useTranslation('FORGOT');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ identifier: string }>();

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mb-10">
            <BackButton text={t('BACK_LOGIN')} to="/login" />
          </div>
          <img className="h-32 w-auto" src="../images/cati-logo-red.png" alt="Cati Ecommerce" title="Cati Ecommerce" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('TITLE')}</h2>
          <p className="mt-2 text-sm text-gray-600">{t('DESCRIPTION')}</p>

          <div className="mt-8 mb-6">
            <form onSubmit={handleSubmit(onForgot)}>
              <div className="mb-6 grid grid-cols-1 gap-6">
                <InputPresentational
                  label={t('COMMON:EMAIL')}
                  id="identifier"
                  type="email"
                  autoComplete="email"
                  {...register('identifier', {
                    required: t('COMMON:REQUIRED') as string,
                  })}
                  hasError={!!errors.identifier}
                  helperText={errors.identifier?.message}
                />
                <PrimaryButtonFull loading={loading} text={t('RESET')} type="submit" />
              </div>
            </form>
          </div>
          <FixedAlert type={result} title={resultTitle} description={resultDescription} />
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="../images/login-img.jpg"
          alt="Cati Ecommerce"
          title="Cati Ecommerce"
        />
      </div>
    </div>
  );
};

export default ForgotPresentational;
