import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';

interface IMadeOrderBadge {
  quantity?: number;
}

const MadeOrderBadge: FC<IMadeOrderBadge> = (props) => {
  const { quantity = 0 } = props;
  const [{ user }] = useAuth();

  return quantity ? (
    <div>
      <div className="inline-flex items-center">
        <div className="mr-2 h-5 w-5 rounded-full bg-yellow-400 flex items-center justify-center text-black text-xs">
          G
        </div>

        {user!.displayQuantity ? (
          <Trans i18nKey={'PRODUCTS:PIECES'} count={quantity}>
            {' '}
            <span className="mr-1 text-lg font-medium text-yellow-400">{{ quantity } as any}</span>{' '}
            <span className="text-sm font-medium text-yellow-400">pieces</span>
          </Trans>
        ) : (
          <span className="text-sm font-medium text-yellow-400">disponibile</span>
        )}
      </div>
    </div>
  ) : null;
};

export default MadeOrderBadge;
