import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { IProduct } from './index';
import { WhereToSearch } from '../../enums';

const { REACT_APP_PRODUCTS_URL } = process.env;
const qsOptions = { encodeValuesOnly: true };

interface ISearchParams {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  where?: string | null;
  lang: string;
  addToCart?: boolean | null;
}

interface ISearchProductResponse {
  data: IProduct[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export const exactSearchCode = async (token: string, params: ISearchParams) => {
  const { page, pageSize, search, sort, lang } = params;
  const filters = {
    [WhereToSearch.Code]: { $eq: search },
  };
  const pagination = { page, pageSize };
  const query = `${qs.stringify(
    {
      filters,
      pagination,
      // populate: '*',
      sort,
      locale: lang,
    },
    qsOptions,
  )}`;
  const response = await axios.get<ISearchProductResponse>(`${REACT_APP_PRODUCTS_URL!}?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

const search = async (token: string, params: ISearchParams) => {
  const { page, pageSize, search, sort, where, lang, addToCart } = params;

  /*
  const whereArray = where ? [where] : Object.values(WhereToSearch);
  const $or = whereArray.map((productPropName) => {
    return {
      [productPropName]: { $containsi: search },
    };
  });
  const filters = { $or };
  const pagination = { page, pageSize };
  const query = `${qs.stringify(
    {
      filters,
      pagination,
      // populate: '*',
      sort,
      locale: lang,
    },
    qsOptions,
  )}`;
  */

  // Custom SEARCH endpoint
  const query = `${qs.stringify({
    search,
    page,
    pageSize,
    where,
    lang,
    sort,
    addToCart,
  })}`;
  const response = await axios.get<ISearchProductResponse>(`${REACT_APP_PRODUCTS_URL!}/search?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default search;
