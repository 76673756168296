import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import InputPresentational from '../Form/InputPresentational';
import { FC } from 'react';
import { REGEX_PATTERNS } from '../../lib/constants/regexp';
import { SelectPresentational } from '../Form';
import { COUNTRIES } from '../../lib/utils';

interface IStep1 {
  isEditing?: boolean;
}

const Step1: FC<IStep1> = ({ isEditing = false }) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const { VAT_OR_FISCAL_CODE, PHONE, ADDRESS, ZIP_CODE, CITY } = REGEX_PATTERNS;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="rounded-md border border-gray-300 px-6 py-6">
        <h3 className="mb-6 text-2xl font-bold text-gray-900">{t('STEP1_SUBTITLE1')}</h3>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          <InputPresentational
            label={`${t('STEP2_BUSINESS_NAME')}*`}
            id="companyName"
            {...register('companyName')}
            autoComplete="organization"
            hasError={!!errors.name}
            helperText={errors.name?.message as string}
          />
          <InputPresentational
            label={t('STEP3_ADDRESS')}
            id="address"
            {...register('address', {
              pattern: {
                value: ADDRESS,
                message: t('ACTIVATION_REQUEST:INVALID_ADDRESS_FORMAT'),
              },
            })}
            autoComplete="street-address"
            hasError={!!errors.address}
            helperText={errors.address?.message as string}
          />
          <InputPresentational
            label={t('STEP3_CITY')}
            id="municipality"
            {...register('municipality', {
              pattern: {
                value: CITY,
                message: t('ACTIVATION_REQUEST:INVALID_CITY_FORMAT'),
              },
            })}
            autoComplete="address-level2"
            hasError={!!errors.municipality}
            helperText={errors.municipality?.message as string}
          />
          <InputPresentational
            label={t('STEP3_STATE_PROVINCE')}
            id="province"
            {...register('province')}
            autoComplete="address-level1"
          />
          <SelectPresentational
            label={t('STEP3_NATION')}
            id="country"
            options={[{ name: '-', value: '' }, ...COUNTRIES]}
            {...register('country')}
            autoComplete="country-name"
          />
          <InputPresentational
            label={`${t('STEP1_VAT')} *`}
            id="vatNumber"
            {...register('vatNumber', {
              required: t('ACTIVATION_REQUEST:REQUIRED_FIELD') as string,
              pattern: {
                value: VAT_OR_FISCAL_CODE,
                message: t('ACTIVATION_REQUEST:INVALID_VAT_FISCAL_FORMAT'),
              },
            })}
            hasError={!!errors.vatNumber}
            helperText={errors.vatNumber?.message as string}
          />
        </div>
      </div>
      <div className="rounded-md border border-gray-300 px-6 py-6 mt-4">
        <h3 className="mb-6 text-2xl font-bold text-gray-900">{t('STEP1_SUBTITLE2')}</h3>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          <InputPresentational
            label={`${t('STEP1_NAME')} *`}
            id="name"
            {...register('name', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="given-name"
            hasError={!!errors.name}
            helperText={errors.name?.message as string}
          />
          <InputPresentational
            label={`${t('STEP1_EMAIL')} *`}
            id="email"
            disabled={isEditing}
            {...register('email', {
              required: t('COMMON:REQUIRED') as string,
            })}
            type="email"
            autoComplete="email"
            hasError={!!errors.email}
            helperText={errors.email?.message as string}
          />
          <InputPresentational
            label={t('STEP1_PHONE')}
            id="phone"
            {...register('phone')}
            type="tel"
            autoComplete="tel"
          />
          <InputPresentational
            label={t('STEP1_MESSAGE')}
            id="message"
            {...register('message')}
            type="message"
            autoComplete="message"
          />
        </div>
      </div>
    </>
  );
};

export default Step1;
