import SofinnResetTemporaryPasswordFeedbackMessagePresentational from './SofinnResetTemporaryPasswordFeedbackMessagePresentational';
import { useNavigate } from 'react-router-dom';

const SofinnResetTemporaryPasswordFeedback: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = ({ isError, isSuccess }: { isError?: boolean; isSuccess?: boolean }) => {
    if (isError) navigate('/cati-b2b/reset-temporary-password');
    if (isSuccess) navigate('/cati-b2b/redirect');
  };

  return <SofinnResetTemporaryPasswordFeedbackMessagePresentational onRedirect={handleRedirect} />;
};

export default SofinnResetTemporaryPasswordFeedback;
