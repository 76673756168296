import { useTranslation } from 'react-i18next';
import CommercialStatsChart from './CommercialStatsChart';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import getCommercialStats, { ICommercialStats } from '../../lib/fetch/commercialStats';
import i18n from '../../i18n';
import FullScreenLoading from '../FullScreenLoading';

const Chart = () => {
  const [monthlyView, setMonthlyView] = useState(true);
  const [compareLastYear, setCompareLastYear] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('PROFILE');
  const { t: t2 } = useTranslation('COMMON');
  const lang = i18n.languages[0];
  const [{ token }] = useAuth();
  const [data, setData] = useState<ICommercialStats[]>([]);

  useEffect(() => {
    isLoading &&
      (async () => {
        const { error, data } = await getCommercialStats(token!, lang, monthlyView);
        if (error || !data) return;
        setData(data as any);
        setIsLoading(false);
      })();
  }, [isLoading]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { error, data } = await getCommercialStats(token!, lang, monthlyView);
      if (error || !data) return;
      setData(data as any);
      setIsLoading(false);
    })();
  }, [lang, monthlyView]);

  if (isLoading) return <FullScreenLoading loading title={t2('LOADING')} subtitle={t2('LOADING_SUBTITLE')} />;

  return (
    <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-800">{t('COMMERCIAL_STATS')}</h3>
      <div className="mb-4 flex items-center">
        <div className="flex items-center">
          <label className="mr-1 block text-sm font-medium text-gray-700">{t('COMMERCIAL_STATS_SELECT_LABEL')}</label>
          <div className="relative mt-1">
            <select
              className="block rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
              onChange={(e) => setMonthlyView(e.target.value === 'M')}
              value={monthlyView ? 'M' : 'B'}
            >
              <option value={'M'} key={'M'}>
                {t('COMMERCIAL_STATS_SELECT_MONTH')}
              </option>
              <option value={'B'} key={'B'}>
                {t('COMMERCIAL_STATS_SELECT_BRAND')}
              </option>
            </select>
          </div>
        </div>
        <div className="ml-8 flex">
          <div className="flex h-5 items-center">
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
              onChange={() => setCompareLastYear(!compareLastYear)}
              checked={compareLastYear}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              className="font-medium text-gray-700"
              dangerouslySetInnerHTML={{ __html: t('COMMERCIAL_STATS_COMPARE') }}
              onClick={() => setCompareLastYear(!compareLastYear)}
            />
          </div>
        </div>
      </div>
      <CommercialStatsChart data={data} compareLastYear={compareLastYear} />
    </div>
  );
};

export default Chart;
