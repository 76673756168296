import * as t from 'io-ts';

const ImageFormat = t.type({
  ext: t.string,
  url: t.string,
  hash: t.string,
  mime: t.string,
  name: t.string,
  path: t.union([t.string, t.null]),
  size: t.number,
  width: t.number,
  height: t.number,
});

const ImageFormats = t.partial({
  large: ImageFormat,
  small: ImageFormat,
  medium: ImageFormat,
  thumbnail: ImageFormat,
});

const ImageAttributes = t.type({
  name: t.string,
  alternativeText: t.union([t.string, t.null]),
  caption: t.union([t.string, t.null]),
  width: t.number,
  height: t.number,
  formats: ImageFormats,
  hash: t.string,
  ext: t.string,
  mime: t.string,
  size: t.number,
  url: t.string,
  previewUrl: t.union([t.string, t.null]),
  provider: t.string,
  provider_metadata: t.union([t.string, t.null]),
  createdAt: t.string,
  updatedAt: t.string,
  path: t.union([t.string, t.null]),
});

export const ImageModel = t.type({
  data: t.union([
    t.type({
      id: t.number,
      attributes: ImageAttributes,
    }),
    t.null,
  ]),
});
