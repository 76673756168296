import { TFunction } from 'i18next';

interface Parameters {
  t: TFunction;
  message?: string;
}

const getTranslatedMessage = (params: Parameters) => {
  const { t, message } = params;
  if (!message) return;

  return t(message);
};

export default getTranslatedMessage;
