import { IProduct } from '../../../lib/fetch/products';
import { IUser } from '../../../lib/interfaces';
import getCanBuyOutOfStock from './getCanBuyOutOfStock';
import getProductStock from './getProductStock';

type Params = {
  user?: IUser;
  product: IProduct;
  quantity: number;
};

const getCanUserOrder = (params: Params): boolean => {
  const { product, user, quantity } = params;

  const { greenQuantity, yellowQuantity } = getProductStock({ product });
  const canUserBuyOutOfStock = getCanBuyOutOfStock({ user });
  const totalQuantity = greenQuantity + yellowQuantity;
  const canUserOrder = quantity <= totalQuantity || canUserBuyOutOfStock;

  return canUserOrder;
};

export default getCanUserOrder;
