import { useAuth } from '../../providers/AuthProvider';
import fetchIFrameBanner from '../../lib/fetch/iFrameBanner/list';
import { useEffect, useState } from 'react';
import { IFrameBannerAttributesModel } from '../../lib/fetch/iFrameBanner';
import { encryptCode } from './utils';

export const IFrameBannerSearchBottom = () => {
  const [{ token, user }] = useAuth();
  const [iFrames, setIFrames] = useState<IFrameBannerAttributesModel | null>(null);

  const fetchIFrames = async () => {
    try {
      const { data: { data } = {} } = await fetchIFrameBanner(token!);
      const customerId = user?.customer.customer_id || '';
      const encryptCustomerId = encryptCode(customerId);
      const cartItemsCode = user?.cart?.cart_items.map((item) => item.product.code).toString() || '';
      const encryptCartItemsCode = encryptCode(cartItemsCode);
      if (data) {
        data.attributes.search_bottom_1 = data.attributes.search_bottom_1
          ? `${data.attributes.search_bottom_1}?customerId=${encryptCustomerId}&cartItemsCode=${encryptCartItemsCode}`
          : data.attributes.search_bottom_1;
        setIFrames(data?.attributes || null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    token && !iFrames && fetchIFrames();
  }, [token, user]);

  return (
    <>
      {iFrames?.search_bottom_1 && (
        <div className="mb-6 mt-6 border-b border-gray-200 pb-2 flex justify-center">
          <iframe
            width="100%"
            height={288}
            key={iFrames?.search_bottom_1}
            src={iFrames?.search_bottom_1}
            title={iFrames?.search_bottom_1}
          />
        </div>
      )}
    </>
  );
};
