import axios, { AxiosResponse } from 'axios';
import { ICartItem } from '../cartItems';

const { REACT_APP_CARTS_URL } = process.env;

export interface ICartCreate {
  users_permissions_user: number;
  customer: number;
  cart_items: number[];
  shipping_address?: number | null;
}

export interface ICartCreateResult {
  id: number;
  attributes: {
    cart_items: ICartItem[];
  };
}

const create = async (token: string, cart: ICartCreate) => {
  const response = await axios.post<{ data: ICartCreateResult }>(
    REACT_APP_CARTS_URL!,
    { data: cart },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
