import axios from 'axios';
import { ILoginResponse } from '../../auth';

const instanceAxios = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

interface ILoginRequest {
  identifier: string;
  password: string;
}

const url = `${REACT_APP_BACKEND_URL}/auth/sofinn/v2/login`;

const sofinnLoginV2 = async (credentials: ILoginRequest) => {
  return await instanceAxios.post<ILoginResponse>(url, credentials, {});
};

export default sofinnLoginV2;
