import axios, { AxiosResponse } from 'axios';
import { IUser } from '../../interfaces';
import { ILoginResponse } from './login';

const { REACT_APP_REGISTER_URL } = process.env;

interface IRegister extends IUser {}

const register = async (registeringUser: IRegister) => {
  const response = await axios.post<ILoginResponse>(REACT_APP_REGISTER_URL!, registeringUser);

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default register;
