import { useQuery } from 'react-query';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import { NewsPost } from '../../lib/models/NewsPost';
import { fetchNews } from '../../lib/fetch/newsposts';
import { useEffect, useState } from 'react';
import { NewsItem } from '../../components/News/NewsItem';
import NewsDetail from '../../components/News/NewsDetail';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../components/Alert/MessageAlert';
import { Pagination } from '../../components/ProductsTable';
import i18n from '../../i18n';

const { REACT_APP_DEFAULT_PAGE_SIZE } = process.env;

interface NewsListInterface {
  categoryId: number;
}

const NewsList = ({ categoryId }: NewsListInterface) => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NewsPost | null>(null);
  const { t } = useTranslation('COMMON');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_PAGE_SIZE! || 25);

  const handleClick = (item: NewsPost) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const {
    data: newsposts,
    isError,
    isLoading,
    refetch,
  } = useQuery<PaginatedResponse<NewsPost>>(
    'newsposts',
    () => fetchNews(token, { page, sort: ['date:desc'] }, categoryId),
    {
      onSuccess,
      onError,
    },
  );

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang, page, pageSize]);

  return (
    <>
      {isError && (
        <div className="h-full py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}
      {newsposts && (
        <div className="p-8">
          <div className="grid grid-cols-1 gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            {newsposts.data.map((news) => (
              <NewsItem key={news.id} news={news} onClick={() => handleClick(news)} />
            ))}
          </div>
          <Pagination
            currentItems={newsposts.data}
            onPageChange={(page: number) => setPage(page)}
            onPageSizeChange={(size: number) => setPageSize(size)}
            page={newsposts.meta.pagination.page}
            pageCount={newsposts.meta.pagination.pageCount}
            pageSize={newsposts.meta.pagination.pageSize}
            total={newsposts.meta.pagination.total}
          />
        </div>
      )}
      <NewsDetail news={selectedItem} open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default NewsList;
