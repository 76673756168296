/* eslint-disable */
export var aastoreEmbed = function (...args) {
  args = args[0]
  this.base_uri = new URL(args.custom_uri ? args.custom_uri : "https://cati.aastore.biz/?embed");
  args.debug ? this.debug = true : this.debug = false;
  args.empty_target ? this.empty_target = args.empty_target : this.empty_target = false;
  this.instance = false;
  this.target = false;
  this.dispatching = false;
  // init the service and get contents
  this.init = function (...obj) {
    obj = obj[0]
    // avoid trying to initiate if no auth token has been given
    if (!obj.token.length) return false;
    // authenticating
    fetch(this.base_uri, {
      method: "POST",
      redirect: 'follow',
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(obj)
    }).then((response) => response.json()).then((result) => {
      if (!result.status) throw ('No connection.');
      this.instance = true;
      // if a target has been defined proceed
      if (document.querySelector(obj.target)) this.target = document.querySelector(obj.target);
      // if no target has been defined 
      if (!this.target) throw ('Target undefined.')
      // otherwise finally load the new resource
      if (this.instance) {
        const iframe = document.createElement("iframe");
        iframe.style.cssText = "position:relative;width:100%;height:100%;border:0px;";
        iframe.src = result.url;
        if (this.empty_target) document.querySelector(this.empty_target).remove();
        this.target.appendChild(iframe);
      }
    }).catch(err => {
      this.debug ? console.warn(err) : null;
      return false;
    });
  };
  this.listen = function (e) {
    // avoid external requests
    if (e.origin !== this.base_uri.origin) return;

    this.dispatching = window.dispatchEvent(new CustomEvent("aastoreFeedback", { bubbles: true, detail: { content: e.data } }));
  };
  // cross iframe forwarding 
  this.send = function (obj) {
    document.querySelector(this.target).contentWindow.postMessage(obj, this.base_uri.origin);
  };
  // user callbacks
  this.addCallback = function (cback) {
    window.addEventListener("aastoreFeedback", cback, false);
  };

  this.getPool = function () { };
  this.getVehicle = function () { };
  this.getCurrentDiz = function () { };

  // init instance if it is not started
  if (!this.instance) this.init(args);

  // start listeners
  if (this.dispatching) window.detachEvent("aastoreFeedback", this.dispatching);
  setTimeout(() => { window.addEventListener('message', this.listen.bind(this)); }, 100);
}


if (!library) var library = {};

library.json = {
  replacer: function (match, pIndent, pKey, pVal, pEnd) {
    var key = '<span class=json-key>';
    var val = '<span class=json-value>';
    var str = '<span class=json-string>';
    var r = pIndent || '';
    if (pKey)
      r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
    if (pVal)
      r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
    return r + (pEnd || '');
  },
  prettyPrint: function (obj) {
    var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
    return JSON.stringify(obj, null, 3)
      .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
      .replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(jsonLine, library.json.replacer);
  }
};