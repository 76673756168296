import { IAddress } from '../../../../lib/interfaces';
import AddressBox from './AddressBox';
import SimpleAddressBox from './AddressBox/SimpleAddressBox';
import NewAddressBox from './NewAddressBox';

interface IAddressesPresentational {
  loading: boolean;
  addresses: IAddress[];
  onAddressCreate: (_: IAddress) => void;
  onAddressDelete: (_: IAddress) => void;
  onAddressEdit: (_: IAddress) => void;
}

const AddressesPresentational = (props: IAddressesPresentational) => {
  const { addresses = [] } = props;

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          {addresses.map((address, index) => (
            <SimpleAddressBox key={index} address={address} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddressesPresentational;
