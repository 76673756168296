import { classNames } from '../../lib/utils';

interface ISingleWordButtonPrimary {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

const SingleWordButtonPrimary = (props: ISingleWordButtonPrimary) => {
  const { onClick = () => {}, text, disabled = false } = props;
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'w-fit text-sm font-medium text-red-500',
        disabled ? 'cursor-not-allowed text-red-200' : 'text-red-500 hover:text-red-700',
      )}
    >
      {text}
    </button>
  );
};

export default SingleWordButtonPrimary;
