import { useFormContext } from 'react-hook-form';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import { ISofinnResetPasswordForm } from '../../../lib/interfaces/ISofinn';
import { useTranslation } from 'react-i18next';
import getTranslatedMessage from '../../../lib/utils/getTranslatedMessage';

interface Props {
  onResetPassword: (values: ISofinnResetPasswordForm) => void;
  onRedirect: () => void;
}

const SofinnResetPasswordForm: React.FC<Props> = (props) => {
  const { onResetPassword, onRedirect } = props;
  const { t } = useTranslation('RESET_PASSWORD_SOFINN');

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<ISofinnResetPasswordForm, Omit<ISofinnResetPasswordForm, 'confirmPassword'>>();

  return (
    <form
      onSubmit={handleSubmit(onResetPassword)}
      className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]"
    >
      <h4 className="uppercase">{t('RESET_PASSWORD')}</h4>
      <div className="grid gap-2 grid-cols-1 mt-4">
        <span className="text-[14px]">{t('PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('PASSWORD')}
          type="password"
          {...register('password')}
          hasError={!!errors.password}
          helperText={getTranslatedMessage({ t, message: errors.password?.message })}
        />
        <span className="text-[14px]">{t('REPEAT_PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('REPEAT_PASSWORD')}
          type="password"
          {...register('passwordConfirmation')}
          hasError={!!errors.passwordConfirmation}
          helperText={getTranslatedMessage({ t, message: errors.passwordConfirmation?.message })}
        />
      </div>
      <div className="flex flex-row justify-between">
        <SofinnButton
          text={t('CANCEL')}
          type="button"
          onClick={() => onRedirect()}
          className="max-w-min px-[20px] text-gray-800 hover:bg-[#dc2626]/75 bg-[#dc2626]"
        />
        <SofinnButton text={t('SEND')} className="max-w-min px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75" />
      </div>
    </form>
  );
};

export default SofinnResetPasswordForm;
