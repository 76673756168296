import moment from 'moment';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ICommercialStats } from '../../lib/fetch/commercialStats';
import { useTranslation } from 'react-i18next';

interface CommercialStatsChartProps {
  data: ICommercialStats[];
  compareLastYear: boolean;
}

const CommercialStatsChart = ({ data, compareLastYear }: CommercialStatsChartProps) => {
  const { t } = useTranslation('MONTHS');

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart data={data}>
        <XAxis dataKey="label" tickFormatter={(label) => t(label)} />
        <YAxis
          tickFormatter={(value) => {
            return `${value}€`;
          }}
        />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'amount') {
              return [`${value}€`, moment().year()];
            } else {
              return [`${value}€`, moment().subtract(1, 'year').year()];
            }
          }}
          labelFormatter={(label) => t(label)}
        />
        {compareLastYear && (
          <Legend
            formatter={(value) => {
              if (value === 'amount') {
                return moment().year();
              } else {
                return moment().subtract(1, 'year').year();
              }
            }}
          />
        )}
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Bar type="monotone" dataKey="amount" fill="#DC2626" />
        {compareLastYear && <Bar type="monotone" dataKey="amount_last_year" fill="#6B7280" />}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CommercialStatsChart;
