import { FormProvider, useForm } from 'react-hook-form';
import SecurityPresentational from './SecurityPresentational';
import ResetPasswordInAppForm from './interfaces/resetPasswordInAppForm';
import { yupResolver } from '@hookform/resolvers/yup';
import resetPasswordInAppFormValidator from './validators/resetPasswordInAppFormValidator';
import IResetPasswordInAppForm from './interfaces/resetPasswordInAppForm';
import { useMutation } from 'react-query';
import _ from 'lodash';
import resetInApp from '../../../../lib/fetch/auth/resetInApp';
import { AlertType, useAlert } from '../../../../providers/AlertProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';

const Security = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const formMethods = useForm<ResetPasswordInAppForm>({
    //@ts-ignore
    resolver: yupResolver(resetPasswordInAppFormValidator),
  });

  const { mutate, isLoading: isResetingPassword } = useMutation('resetPasswordInAppMutation', resetInApp, {
    onError: (error: any) => {
      const errorMessage = error.response?.data?.error?.message || null;
      dispatchAlertChange({ type: AlertType.Error, open: true, message: errorMessage });
    },
    onSuccess: () => {
      formMethods.reset();
      dispatchAlertChange({ type: AlertType.Success, open: true, message: t('COMMON:OPERATION_DONE') });
    },
  });

  const onSubmit = (formValues: IResetPasswordInAppForm) => {
    const data: Omit<IResetPasswordInAppForm, 'repeatNewPassword'> = _.omit(formValues, 'repeatNewPassword');
    mutate({ data, token });
  };

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <FormProvider {...formMethods}>
          <SecurityPresentational onSubmit={onSubmit} isLoading={isResetingPassword} />
        </FormProvider>
      </div>
    </div>
  );
};

export default Security;
