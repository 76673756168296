import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useAuth } from '../../providers/AuthProvider';
import PurchasedProductItem from './PurchasedProductItem';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { fetchPurchasedProducts } from '../../lib/fetch/purchasedProducts';
import MessageAlert from '../Alert/MessageAlert';
import { PurchasesType } from '../../lib/fetch/orderItems';

const PurchasedProductsCollapse = () => {
  const { t } = useTranslation('PURCHASED_PRODUCTS');
  const [{ token }] = useAuth();

  const lang = i18n.languages[0];

  const { data: purchasedProducts } = useQuery(
    ['firstNewsposts', lang],
    () => fetchPurchasedProducts(token, { page: 1, limit: 1, sort: ['date:desc'] }),
    { staleTime: 60 * 2000 },
  );

  return (
    <>
      <div className="mb-6 border-b border-gray-200 pb-2">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <h3 className="-mx-2 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-gray-400 hover:text-gray-500">
                  <span className="font-semibold text-gray-900">
                    {t('PURCHASED_PRODUCTS_TITLE')}{' '}
                    <Link
                      to={`/purchased-products?tab=${PurchasesType.own}`}
                      className="pl-2 text-left text-xs text-red-600 underline"
                    >
                      {t('COMMON:SHOW_ALL')}
                    </Link>
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>

              {purchasedProducts && (
                <Disclosure.Panel className="mb-2 px-2 py-4">
                  {Number(purchasedProducts?.data?.length) > 0 ? (
                    purchasedProducts.data
                      .slice(0, 20)
                      .map((product) => <PurchasedProductItem key={product.id} product={product} />)
                  ) : (
                    <div className="my-4 mx-auto w-3/4 text-center justify-center">
                      <MessageAlert text={t('COMMON:NO_DATA')} />
                    </div>
                  )}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export default PurchasedProductsCollapse;
