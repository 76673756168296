import { IOrder } from '../../../lib/fetch/orders';

/**
 * Questo metodo restituisce una stringa con il nome + cognome dato in ingresso un ordine
 *
 * @param order
 * @returns
 */
const getOrderShippingAddressName = (order: IOrder) => {
  const {
    attributes: { shipping_address, ord_ship_address },
  } = order;

  if (shipping_address) return shipping_address.name;
  if (ord_ship_address) return ord_ship_address.data.attributes.name;

  return '-';
};

export default getOrderShippingAddressName;
