import { IProduct } from '../../../lib/fetch/products';

type Params = { product: IProduct };

type ReturnType = {
  greenQuantity: number;
  yellowQuantity: number;
};

/**
 * restituisce lo stock di un prodotto del tipo IProduct
 *
 * (non funziona sui prodotti di altri tipi, come relazioni)
 *
 * @param params
 * @returns
 */
const getProductStock = (params: Params): ReturnType => {
  const { product } = params;

  const {
    attributes: { quantity },
  } = product;
  const { quantityDefaultWarehouse: greenQuantity, quantityOtherWarehouses: yellowQuantity } = quantity;

  return { greenQuantity, yellowQuantity };
};

export default getProductStock;
