import { ForwardedRef, forwardRef, useState } from 'react';
import { EyeIcon, EyeSlashIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

interface IInputPresentational {
  label: string;
  name: string;
  id?: string;
  type?: string;
  autoComplete?: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
}

const InputPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      type = 'text',
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      required = false,
      ...rest
    }: IInputPresentational,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [plainTextPassword, setPlainTextPassword] = useState(false);
    const isPasswordInput = type === 'password';
    return (
      <div>
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        <div className="relative mt-1">
          <input
            ref={ref}
            id={id}
            name={name}
            type={plainTextPassword ? 'text' : type}
            autoComplete={autoComplete}
            disabled={disabled}
            readOnly={disabled}
            required={required}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
            {...rest}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {hasError && <ExclamationCircleIcon className="m-2 h-5 w-5 text-red-500" aria-hidden="true" />}
            {isPasswordInput && (
              <button type="button" className="" onClick={() => setPlainTextPassword(!plainTextPassword)}>
                {plainTextPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </button>
            )}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  },
);

export default InputPresentational;
