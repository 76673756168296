import { useAuth } from '../../providers/AuthProvider';
import fetchIFrameBanner from '../../lib/fetch/iFrameBanner/list';
import { useEffect, useState } from 'react';
import { IFrameBannerAttributesModel } from '../../lib/fetch/iFrameBanner';
import { encryptCode } from './utils';

export const IFrameBannerProfileSide = () => {
  const [{ token, user }] = useAuth();
  const [iFrames, setIFrames] = useState<IFrameBannerAttributesModel | null>(null);

  const fetchIFrames = async () => {
    try {
      const { data: { data } = {} } = await fetchIFrameBanner(token!);
      const customerId = user?.customer.customer_id || '';
      const encryptCustomerId = encryptCode(customerId);
      const cartItemsCode = user?.cart?.cart_items.map((item) => item.product.code).toString() || '';
      const encryptCartItemsCode = encryptCode(cartItemsCode);
      if (data) {
        data.attributes.profile_side_1 = data.attributes.profile_side_1
          ? `${data.attributes.profile_side_1}?customerId=${encryptCustomerId}&cartItemsCode=${encryptCartItemsCode}`
          : data.attributes.profile_side_1;
        data.attributes.profile_side_2 = data.attributes.profile_side_2
          ? `${data.attributes.profile_side_2}?customerId=${encryptCustomerId}&cartItemsCode=${encryptCartItemsCode}`
          : data.attributes.profile_side_2;
        data.attributes.profile_side_3 = data.attributes.profile_side_3
          ? `${data.attributes.profile_side_3}?customerId=${encryptCustomerId}&cartItemsCode=${encryptCartItemsCode}`
          : data.attributes.profile_side_3;
        setIFrames(data?.attributes || null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    token && !iFrames && fetchIFrames();
  }, [token, user]);

  return (
    <>
      {iFrames?.profile_side_1 && (
        <div className="mb-6 border-b border-gray-200 pb-2 flex justify-center">
          <iframe
            width={288}
            height={288}
            key={iFrames?.profile_side_1}
            src={iFrames?.profile_side_1}
            title={iFrames?.profile_side_1}
          />
        </div>
      )}
      {iFrames?.profile_side_2 && (
        <div className="mb-6 border-b border-gray-200 pb-2 flex justify-center">
          <iframe
            width={288}
            height={288}
            key={iFrames?.profile_side_2}
            src={iFrames?.profile_side_2}
            title={iFrames?.profile_side_2}
          />
        </div>
      )}
      {iFrames?.profile_side_3 && (
        <div className="mb-6 border-b border-gray-200 pb-2 flex justify-center">
          <iframe
            width={288}
            height={288}
            key={iFrames?.profile_side_3}
            src={iFrames?.profile_side_3}
            title={iFrames?.profile_side_3}
          />
        </div>
      )}
    </>
  );
};
