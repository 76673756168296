export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,64}$/;

export const REGEX_PATTERNS = {
  FISCAL_CODE: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
  PHONE: /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  CITY: /^[a-zA-ZÀ-ÖØ-öø-ÿ' -]+$/,
  ZIP_CODE: /^\d{5}(?:[-\s]\d{4})?$/,
  ADDRESS: /^[#.0-9a-zA-ZÀ-ú\s,'-]+$/,
  COUNTRY: /[a-zA-Z]{2,}/,
  FAX: /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/,
  VAT_OR_FISCAL_CODE: /^(IT)?[0-9]{11}$|^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
};
