import { useTranslation } from 'react-i18next';
import InputResult from '../../pages/Profile/components/GeneralData/InputResult';
import PersonalDataBox from '../../pages/Profile/components/GeneralData/PersonalDataBox';
import { ICustomerForAgent } from '../../lib/interfaces/ICustomerForAgent';

const CustomerDetails = (customer: ICustomerForAgent | undefined) => {
  const { t } = useTranslation('PROFILE');
  if (!customer) return <></>;
  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="relative h-full">
          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP2_TITLE')}>
            <InputResult label={t('ACTIVATION_REQUEST:STEP2_CODE')} text={customer.customer_id} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_NAME')} text={customer.customer_class_id?.name ?? '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_NAME')} text={customer.warehouse_id?.name ?? '-'} />
            <InputResult label={'List Discount'} text={String(customer.list_discount)} />
            <InputResult label={'Usual Discount'} text={String(customer.usual_discount)} />
            <InputResult label={'Revenue Discount'} text={String(customer.revenue_discount)} />
            <InputResult label={'Increase'} text={String(customer.increase)} />
          </PersonalDataBox>
          <PersonalDataBox title={'Registry'}>
            <InputResult label={'Fax'} text={customer?.fax || '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_CITY')} text={customer?.city || '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP2_BUSINESS_NAME')} text={customer?.name ?? '-'} />
            <InputResult label={'Telex'} text={'pppp'} />
            <InputResult label={t('PROFILE:PHONE_NUMBER')} text={customer?.phone || '-'} />
            <InputResult label={t('PROFILE:ADDRESS')} text={customer?.address ?? '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_VAT')} text={customer?.vat_num || '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_CAP')} text={customer?.zipcode || '-'} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_STATE_PROVINCE')} text={customer?.district || '-'} />
            <InputResult label={'Id Regione'} text={String(customer?.region_id)} />
          </PersonalDataBox>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
