import { DetailedHTMLProps, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { classNames } from '../../lib/utils';

interface Props {
  hasError?: boolean;
  helperText?: string;
}

const SofinnInput = forwardRef(
  (
    props: Props & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div>
        <input
          ref={ref}
          className={classNames(
            'flex w-full rounded-[3px] text-[12px] h-[30px] px-[10px] py-[5px] leading-6 focus:ring-0 border-[#ccc] border-[1px] focus:shadow-sm focus:shadow-blue-400 focus:border-blue-400',
            props.className,
          )}
          {...props}
        />
        {props.helperText && (
          <p
            className={`text-sm text-${props.hasError ? 'red-600' : 'gray-500'}`}
            id={`${props.hasError ? 'error' : 'helper'}`}
          >
            {props.helperText}
          </p>
        )}
      </div>
    );
  },
);

export default SofinnInput;
