import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'use-debounce';
import { addErrorInterceptor } from '../../lib/interceptors';
import { me } from '../../lib/fetch/auth';
import { IUser } from '../../lib/interfaces';
import i18n from '../../i18n';

enum AuthErrorStates {
  Unauthorized = 401,
}

export enum AuthActionType {
  Login = 'login',
  SofinnLogin = 'SofinnLogin',
  Logout = 'logout',
  LogoutSofinn = 'LogoutSofinn',
  SetUser = 'setUser',
  SetSofinnUser = 'setSofinnUser',
  SetAppLoaded = 'setAppLoaded',
}

interface AuthState {
  appLoaded: boolean;
  isAuthenticated: boolean;
  isSofinnAuthenticated: boolean;
  token: string | null;
  sofinnToken: string | null;
  user?: IUser;
  sofinnUser?: IUser;
}

export interface AuthAction {
  type: AuthActionType;
  token?: string;
  user?: IUser;
  sofinnUser?: IUser;
  remember?: boolean;
  sofinnToken?: string;
}

const INITIAL_AUTH_STATE: AuthState = Object.freeze({
  appLoaded: false,
  isAuthenticated: false,
  token: null,
  sofinnToken: null,
  isSofinnAuthenticated: false,
});

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  const { type, token = null, user, sofinnUser, sofinnToken = null } = action;
  switch (type) {
    case AuthActionType.Logout:
      return {
        ...INITIAL_AUTH_STATE,
        appLoaded: true,
      };
    case AuthActionType.LogoutSofinn:
      return {
        ...INITIAL_AUTH_STATE,
        appLoaded: true,
      };
    case AuthActionType.Login:
      return {
        ...state,
        isAuthenticated: true,
        appLoaded: true,
        token,
        user,
      };
    case AuthActionType.SofinnLogin:
      return {
        ...state,
        sofinnToken,
        isSofinnAuthenticated: !!sofinnToken,
        sofinnUser,
      };
    case AuthActionType.SetAppLoaded:
      return {
        ...state,
        appLoaded: true,
      };
    case AuthActionType.SetUser:
      return {
        ...state,
        isAuthenticated: true,
        appLoaded: true,
        user,
      };
    case AuthActionType.SetSofinnUser:
      return {
        ...state,
        isSofinnAuthenticated: true,
        appLoaded: true,
        sofinnUser,
      };
    default:
      return { ...state };
  }
};

const AuthContext = createContext<[AuthState, (_action: AuthAction) => void, () => Promise<void>]>([
  { ...INITIAL_AUTH_STATE },
  (_action: AuthAction) => {},
  async () => {},
]);

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'sofinnToken']);
  const { token, sofinnToken } = cookies;
  const isAuthenticated = !!token;
  const isSofinnAuthenticated = !!sofinnToken;

  const initialState = {
    ...INITIAL_AUTH_STATE,
    isAuthenticated,
    isSofinnAuthenticated,
    sofinnToken,
    token,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appLoaded } = state;
  const [shouldCallMe] = useDebounce(isAuthenticated && !appLoaded, 100);
  const lang = i18n.languages[0];

  const setCookiesAndDispatch = (action: AuthAction) => {
    const { type, token = null, remember = false, sofinnToken = null } = action;
    switch (type) {
      case AuthActionType.Login:
        remember && setCookie('token', token);
        break;
      case AuthActionType.SofinnLogin:
        remember && setCookie('sofinnToken', sofinnToken);
        break;
      case AuthActionType.Logout:
        removeCookie('token');
        break;
      case AuthActionType.LogoutSofinn:
        removeCookie('sofinnToken');
        break;
      default:
        break;
    }
    return dispatch(action);
  };

  useEffect(() => {
    addErrorInterceptor(AuthErrorStates.Unauthorized, () =>
      setCookiesAndDispatch({
        type: AuthActionType.Logout,
      }),
    );
  }, []);

  const refetchMe = async () => {
    const { error, data: user } = await me(token, lang);
    if (error)
      setCookiesAndDispatch({
        type: AuthActionType.Logout,
      });
    else dispatch({ type: AuthActionType.SetUser, user });
  };

  useEffect(() => {
    shouldCallMe
      ? (async () => {
          refetchMe();
        })()
      : dispatch({ type: AuthActionType.SetAppLoaded });
  }, [shouldCallMe]);

  return <AuthContext.Provider value={[state, setCookiesAndDispatch, refetchMe]}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
