import axios, { AxiosResponse } from 'axios';
import { IUser } from '../../interfaces';
const instance = axios.create();

const { REACT_APP_UPDATE_PROFILE_URL } = process.env;

interface IUserUpdate extends Omit<IUser, 'addresses'> {
  addresses: number[];
}

const update = async (token: string, updatedUser: Partial<IUserUpdate>) => {
  const response = await instance.put<IUser>(`${REACT_APP_UPDATE_PROFILE_URL!}/${updatedUser.id}`, updatedUser, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
