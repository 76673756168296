import * as t from 'io-ts';

export const PaginationModel = t.type({
  page: t.number,
  pageSize: t.number,
  pageCount: t.number,
  total: t.number,
});

export const MetaModel = t.type({
  pagination: PaginationModel,
});

export type PaginatedResponse<T> = {
  data: T[];
  meta: t.TypeOf<typeof MetaModel>;
};
