import axios, { AxiosResponse } from 'axios';
import { IFrameBannerData } from './index';

const { REACT_APP_IFRAMEBANNER_URL = '' } = process.env;

const fetchIFrameBanner = async (token: string) => {
  const response = await axios.get<{ data: IFrameBannerData }>(REACT_APP_IFRAMEBANNER_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default fetchIFrameBanner;
