import { ICartItem } from '../fetch/cartItems';
import { IPromoExtraDiscountPrice, IPromotion } from '../fetch/products';
import currency from './currency';

export const getPromotionTypeCode = (promotionType: string) => {
  const PROMOTION_TYPE_REGEX = /id:(.*?)##/g;
  const promotionCode = PROMOTION_TYPE_REGEX.exec(promotionType);

  return promotionCode ? promotionCode[0] : null;
};

export const getPromoDescription = (promo: IPromotion, lang: string) => {
  if (promo.extraScontoItem && promo.extraScontoItem.length > 0) {
    let description = '<ul style="list-style: inside;">';
    promo.extraScontoItem.forEach((extraScontoFascia) => {
      description += `<li>Con un acquisto minimo di ${extraScontoFascia.minimoDi} pz otterrai un Extra sconto del ${extraScontoFascia.extraSconto}%</li>`;
    });
    return description + '</ul>';
  }
  if (promo.promoTipoSuTipo && promo.promoTipoSuTipo.length > 0) {
    let description = '<ul style="list-style: inside;">';
    promo.promoTipoSuTipo.forEach((promoTipoSuTipo, index) => {
      if (index === 0) {
        description += `<li>Per ogni ${promoTipoSuTipo.ogni} pz acquistati di questo articolo, riceverai ${promoTipoSuTipo.inOmaggio} pz in omaggio</li>`;
      } else {
        description += `<li>Per ogni ${promoTipoSuTipo.ogni} pz acquistati, ne riceverai ${promoTipoSuTipo.inOmaggio} extra in omaggio</li>`;
      }
    });
    return description + '</ul>';
  }

  //TODO: c'era un case su PromoConfigurationType.EVERY_X_FOR_FREE che però non sembra esistere più nel backend
  return 'No valid promo';
};

export const getCartItemList = (cartItem: ICartItem) => {
  // Netto nel carrello
  const {
    quantity,
    product: { price_list },
  } = cartItem;

  return price_list ? `${currency(price_list)} (${quantity}x)` : '-';
};

export const getCartItemNet = (cartItem: ICartItem) => {
  // Netto nel carrello
  const { quantity } = cartItem;

  const productPrice = getProductPrice(cartItem);

  return `${currency(productPrice)} (${quantity}x)`;
};

export const getCartItemFinal = (cartItem: ICartItem) => {
  //Finale nel carrello

  const { quantity } = cartItem;

  const productPrice = getProductPrice(cartItem);

  return `${currency(productPrice * quantity)}`;
};

export const getProductPrice = (cartItem: ICartItem) => {
  const { price, quantity, promotions, priceDiscounted } = cartItem;

  if (!promotions?.data.length) {
    if (price || price === 0) return price;
    else return 0;
  } else {
    if (priceDiscounted) {
      return priceDiscounted;
    }
    const promo = promotions.data[0];

    if (promo.extraScontoItem) {
      const fasciaExtraSconto = promo.extraScontoItem
        .slice()
        .sort((a, b) => b.minimoDi - a.minimoDi)
        .find((fascia) => fascia.minimoDi <= quantity);
      if (fasciaExtraSconto) {
        return price - price * (fasciaExtraSconto.extraSconto / 100)!;
      } else {
        return price!;
      }
    }
    return price;
  }
};

export const getExtraDiscountPrice = (priceDiscounted: IPromoExtraDiscountPrice[] | null, quantity: number = 1) => {
  if (!priceDiscounted || !priceDiscounted.length) return null;
  return (
    priceDiscounted?.sort((a, b) => b.minimoDi - a.minimoDi)?.find((fascia) => fascia.minimoDi <= quantity)?.prezzo ??
    null
  );
};
