import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQueryParam, BooleanParam, NumberParam } from 'use-query-params';
import { CHECKOUT_PAYMENT_PATHNAME, MY_ORDERS_PATHNAME } from '../../App';
import { useAuth } from '../../providers/AuthProvider';
import CheckoutResultPresentational from './CheckoutResultPresentational';
import queryString from 'query-string';
import { updateStatus as updateOrderStatus } from '../../lib/fetch/orders';
import { OrderStatus } from '../../lib/enums';

const CheckoutResult = () => {
  const [{ token }] = useAuth();
  const navigate = useNavigate();
  const [orderId] = useQueryParam('orderId', NumberParam);
  const [success] = useQueryParam('success', BooleanParam);
  const [wireTransfer] = useQueryParam('wireTransfer', BooleanParam);

  useEffect(() => {
    if (!success || !orderId || wireTransfer) return;
    updateOrderStatus(token!, orderId, OrderStatus.Processing);
  }, [orderId, success, wireTransfer]);

  if (success === undefined || success === null) {
    return <Navigate replace to={'/profile'} />;
  }

  const onRetryPayment = async () => {
    /*
    const qString = {
      orderId,
    };
    navigate(orderId ? `${CHECKOUT_PAYMENT_PATHNAME}?${queryString.stringify(qString)}` : MY_ORDERS_PATHNAME);
    */
    navigate('/shopping-cart');
  };

  return (
    <CheckoutResultPresentational
      onRetryPayment={onRetryPayment}
      success={success}
      wireTransfer={wireTransfer || false}
    />
  );
};

export default CheckoutResult;
