import { FormProvider, useForm } from 'react-hook-form';
import SofinnUpdateProfilePresentational from './SofinnUpdateProfilePresentational';
import { useMutation, useQuery } from 'react-query';
import { ISofinnUpdateProfileForm } from '../../../lib/interfaces/ISofinn';
import updateProfileSofinn from '../../../lib/fetch/sofinn/auth/resetPassword';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import sofinnUpdateUserFormValidator from './validator';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { me } from '../../../lib/fetch/auth';
import redirectToSofinnV2 from '../../../lib/fetch/sofinn/auth/redirectV2';

const SofinnUpdateProfile: React.FC = () => {
  const [{ sofinnToken }, dispatchAuthChange] = useAuth();

  const formMethods = useForm<ISofinnUpdateProfileForm, ISofinnUpdateProfileForm>({
    //@ts-ignore
    resolver: yupResolver(sofinnUpdateUserFormValidator),
    mode: 'onSubmit',
  });

  const handleRedirect = () => {
    redirectToSofinnV2({ sofinnToken: String(sofinnToken), dispatchAuthChange });
  };

  const { refetch: refetchMe } = useQuery('meSofinnQuery', () => me(String(sofinnToken)), {
    enabled: false,
    onSuccess: (data) => {
      dispatchAuthChange({
        type: AuthActionType.SetSofinnUser,
        sofinnUser: data.data,
      });
      handleRedirect();
    },
  });

  const { mutate, isError } = useMutation('sofinnUpdateProfile', updateProfileSofinn, {
    onSuccess: () => {
      refetchMe();
    },
  });

  const onUpdateSofinnProfile = (values: ISofinnUpdateProfileForm) => {
    mutate({ data: _.omit(values, ['confirmPassword']), token: sofinnToken });
  };

  return (
    <FormProvider {...formMethods}>
      <SofinnUpdateProfilePresentational
        isError={isError}
        onRedirect={handleRedirect}
        onUpdateSofinnProfile={onUpdateSofinnProfile}
      />
    </FormProvider>
  );
};

export default SofinnUpdateProfile;
