import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../../lib/constants/regexp';

const resetTemporaryPasswordValidator: any = yup
  .object({
    password: yup
      .string()
      .required('REQUIRED_FIELD')
      .min(8, 'PASSWORD_LENGTH_ERROR')
      .matches(PASSWORD_REGEXP, 'INVALID_PASSWORD_ERROR'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'PASSWORDS_DONT_MATCH')
      .required('REQUIRED_FIELD'),
  })
  .required();

export default resetTemporaryPasswordValidator;
