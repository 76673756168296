import { useTranslation } from 'react-i18next';
import { PrimaryButtonFull } from '../../../../components/Buttons';
import { CheckBoxPresentational } from '../../../../components/Form';
import { useFormContext } from 'react-hook-form';
import IUserNotificationsForm from './interfaces/userNotificationsForm';
import { formatFormErrorMessage } from '../../../../lib/utils/formatters';
import { classNames } from '../../../../lib/utils';

interface Props {
  onSubmit: (formValues: IUserNotificationsForm) => void;
  isLoading: boolean;
  className?: string;
}

const UserNotificationsForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('');
  const { onSubmit, isLoading, className } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IUserNotificationsForm>();

  return (
    <form className={classNames('w-full justify-center flex flex-col', className)} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">
        <div>
          <CheckBoxPresentational
            label={t('NOTIFICATIONS:ORDERS_NOTIFICATIONS')}
            id="oldPassword"
            {...register('isOrdersEmailsAccepted')}
            hasError={!!errors.isOrdersEmailsAccepted?.message}
            helperText={t(formatFormErrorMessage(errors.isOrdersEmailsAccepted?.message))}
          />
          <label className="mr-1 block text-sm font-small text-gray-600 italic">
            {t('NOTIFICATIONS:ORDERS_NOTIFICATIONS_DESCRIPTION')}
          </label>
        </div>
      </div>

      <div className="flex mx-auto my-4 lg:w-1/2 xl:w-1/3 mt-10">
        <PrimaryButtonFull type="submit" loading={isLoading} text={t('COMMON:SAVE_CHANGES')} />
      </div>
    </form>
  );
};

export default UserNotificationsForm;
