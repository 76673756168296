import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import packageInfo from '../package.json';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  REACT_APP_SENTRY_ENABLED,
} = process.env;

Sentry.init({
  enabled: REACT_APP_SENTRY_ENABLED === 'true',

  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: process.env.SENTRY_ENV || ("unknown but " + process.env.NODE_ENV),
  release: packageInfo.version,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: parseFloat(REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0'),

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: parseFloat(REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '1.0'),
  replaysOnErrorSampleRate: parseFloat(REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),
});
