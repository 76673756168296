import axios, { AxiosResponse } from 'axios';
import { IAddress } from '../../interfaces';

const { REACT_APP_ADDRESSES_URL } = process.env;

const update = async (token: string, address: IAddress) => {
  const response = await axios.put<IAddress>(
    `${REACT_APP_ADDRESSES_URL!}/${address.id}`,
    { data: address },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
