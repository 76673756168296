import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
const { REACT_APP_MOTORINI_LOGIN_URL } = process.env;

export const CatalogueMotoriniButton = () => {
  const { t } = useTranslation('CATALOGUE');
  const [{ token, user }] = useAuth();
  const url = `${REACT_APP_MOTORINI_LOGIN_URL}?token=${token}&user=${user?.email || ''}`;

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className="mb-4 rounded-lg px-2 py-4 flex opacity-100"
      style={{
        background:
          'transparent radial-gradient(closest-side at 50% 50%, #6DD5ED 0%, #2193B0 100%) 0% 0% no-repeat padding-box',
        boxShadow: '3px 3px 6px #00000029',
      }}
    >
      <div>
        <div className="text-sm text-white">
          <p className="mt-1 text-xs">{t('BROWSE')}</p>
          <p className="mt-1 font-black text-2xl">{t('MOTOR')}</p>
          <p className="mt-1 font-black text-2xl">{t('ALTERNATOR')}</p>
        </div>
      </div>
      <div className="my-auto ml-auto mr-2">
        <ArrowRightStartOnRectangleIcon
          className="h-10 w-10 text-white rounded-full cursor-pointer p-1.5"
          style={{ backgroundColor: '#6ab8cc' }}
          aria-hidden="true"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
