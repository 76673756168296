import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

interface ConfirmModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  title,
  message,
  confirmText,
}) => {
  const { t } = useTranslation();
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg transition-all"
      ariaHideApp={false}
      contentLabel={title}
    >
      <h2 className="text-lg font-semibold text-center text-gray-800 mb-4">{title}</h2>
      <p className="text-center text-gray-600 mb-10">{message}</p>
      <div className="flex justify-center space-x-4">
        <button
          onClick={onRequestClose}
          className="p-3  w-full rounded-md bg-gray-200 text-gray-800 text-sm  hover:bg-gray-300 uppercase  "
        >
          {t('COMMON:CANCEL')}
        </button>
        <button
          onClick={onConfirm}
          className="p-3 w-full rounded-md bg-red-600 text-white text-sm  shadow hover:bg-red-700 uppercase"
        >
          {confirmText}
        </button>
      </div>
    </ReactModal>
  );
};

export default ConfirmModal;
