import { useState } from 'react';
import RvaHeader from '../../components/Header/RvaHeader';
import AgentList from './Components/AgentList';
import CartList from './Components/CartList';

const Rva = () => {
  const [activeSection, setActiveSection] = useState('AGENTI');

  return (
    <div className="min-h-screen">
      <RvaHeader activeSection={activeSection} setActiveSection={setActiveSection} />
      {activeSection === 'AGENTI' && <AgentList />}
      {activeSection === 'CARRELLI' && <CartList />}
    </div>
  );
};

export default Rva;
