const SinglePromotion = ({ title, from, to }: { title: string; from: string; to: string }) => (
  <div className="grid grid-cols-3 gap-4">
    <span className="col-span-1 font-bold">{title}</span>
    <span className="col-span-2 text-gray-500">
      dal {from} al {to}
    </span>
  </div>
);

export default SinglePromotion;
