import { FormProvider, useForm } from 'react-hook-form';
import UserNotificationsPresentational from './UserNotificationsPresentational';
import ResetPasswordInAppForm from './interfaces/userNotificationsForm';
import { yupResolver } from '@hookform/resolvers/yup';
import resetPasswordInAppFormValidator from './validators/resetPasswordInAppFormValidator';
import IUserNotificationsForm from './interfaces/userNotificationsForm';
import { useMutation } from 'react-query';
import { AlertType, useAlert } from '../../../../providers/AlertProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import updateCustomer from '../../../../lib/fetch/customer/updateCustomer';
import ICustomer from '../../../../lib/interfaces/ICustomer';

const UserNotifications = () => {
  const [{ token, user }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const formMethods = useForm<ResetPasswordInAppForm>({
    //@ts-ignore
    resolver: yupResolver(resetPasswordInAppFormValidator),
    defaultValues: { isOrdersEmailsAccepted: user?.customer.isOrdersEmailsAccepted },
  });

  const { mutate, isLoading: isResetingPassword } = useMutation(
    'resetPasswordInAppMutation',
    (data: Partial<ICustomer>) => updateCustomer(String(token), data),
    {
      onError: (error: any) => {
        const errorMessage = error.response?.data?.error?.message || null;
        dispatchAlertChange({ type: AlertType.Error, open: true, message: errorMessage });
      },
      onSuccess: () => {
        dispatchAlertChange({ type: AlertType.Success, open: true, message: t('COMMON:OPERATION_DONE') });
      },
    },
  );

  const onSubmit = (formValues: IUserNotificationsForm) => {
    mutate({ ...formValues, id: user?.customer?.id });
  };

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <FormProvider {...formMethods}>
          <UserNotificationsPresentational onSubmit={onSubmit} isLoading={isResetingPassword} />
        </FormProvider>
      </div>
    </div>
  );
};

export default UserNotifications;
