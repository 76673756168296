import { RoleType } from '../enums';
import { IUser } from '../interfaces';

export const getIsUserRoleAllowed = (user: IUser, restrictedToRoles?: RoleType[]) => {
  if (!restrictedToRoles) return true; //se vuoto è disponibile per authenticated, agenti e rva
  return user?.role?.type && restrictedToRoles.includes(user.role.type);
};

export const getHasCustomerRequiredOptions = (user: IUser, hasCustomerRequiredOptions: boolean) => {
  if (user?.role?.type === RoleType.AUTHENTICATED) return user?.customer && hasCustomerRequiredOptions;
  return hasCustomerRequiredOptions;
};
