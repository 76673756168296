import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { fetchNews } from '../../lib/fetch/newsposts';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import { NewsPost } from '../../lib/models/NewsPost';
import { useAuth } from '../../providers/AuthProvider';
import NewsDetail from './NewsDetail';
import { NewsItem } from './NewsItem';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';

const NewsCollapse = () => {
  const { t } = useTranslation('NEWS');
  const [{ token }] = useAuth();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NewsPost | null>(null);

  const { data: newsposts, refetch } = useQuery<PaginatedResponse<NewsPost>>(
    'firstNewsposts',
    () => fetchNews(token, { page: 1, limit: 1, sort: ['date:desc'] }),
    { staleTime: 60 * 2000 },
  );

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  const handleClick = (item: NewsPost) => {
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <>
      <div className="mb-6 border-b border-gray-200 pb-2">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <h3 className="-mx-2 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-gray-400 hover:text-gray-500">
                  <span className="font-semibold text-gray-900">
                    {t('NEWS')}{' '}
                    <Link to="/news" className="pl-2 text-left text-xs text-red-600 underline">
                      {t('COMMON:SHOW_ALL')}
                    </Link>
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              {newsposts && (
                <Disclosure.Panel className="mb-2 px-2 py-4">
                  {newsposts.data.map((news) => (
                    <NewsItem key={news.id} news={news} onClick={() => handleClick(news)} />
                  ))}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
        <NewsDetail news={selectedItem} open={open} onClose={() => setOpen(false)} />
      </div>
    </>
  );
};

export default NewsCollapse;
