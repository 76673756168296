import moment from 'moment';
import { IOrderAttributes } from '../../lib/fetch/orders';
import { StrapiDataType } from '../../lib/interfaces/ICommons';
import { useTranslation } from 'react-i18next';

interface Props {
  orders: StrapiDataType<IOrderAttributes>[];
}

const PurchasedProductOrderItem: React.FunctionComponent<Props> = (props) => {
  const { orders } = props;
  const { t } = useTranslation('PURCHASED_PRODUCTS');

  const getUserNameFromOrder = (order: StrapiDataType<IOrderAttributes>) => {
    const { name, surname } = order.attributes.users_permissions_user.data.attributes;
    return `${name} ${surname}`;
  };

  return (
    <>
      <td className="whitespace-nowrap py-2 pl-2 pr-4 text-xs">
        <ul className="list-disc pl-5">
          {orders.map((_order) => (
            <li>{getUserNameFromOrder(_order)}</li>
          ))}
        </ul>
      </td>
      <td className="whitespace-nowrap py-2 pl-2 pr-4 text-xs">
        <ul className="list-disc pl-5">
          {orders.map((_order) => (
            <li>{t(_order.attributes.status)}</li>
          ))}
        </ul>
      </td>
      <td className="whitespace-nowrap py-2 pl-2 pr-4 text-xs">
        <ul className="list-disc pl-5">
          {orders.map((_order) => (
            <li>{moment(_order.attributes.date).format('L [-] HH:mm')}</li>
          ))}
        </ul>
      </td>
    </>
  );
};

export default PurchasedProductOrderItem;
