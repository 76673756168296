interface Props {
  loading?: boolean;
  disabled?: boolean;
  pointer?: boolean;
}

const getCursorClassname = (props: Props) => {
  const { disabled, loading, pointer } = props;
  if (loading) return 'cursor-progress';
  if (disabled) return 'cursor-not-allowed';
  if (pointer) return 'cursor-pointer';

  return '';
};

export default getCursorClassname;
