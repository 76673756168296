import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { IStrapiFile } from '../../../../lib/interfaces';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { GreyButton, PrimaryButton } from '../../../../components/Buttons';

interface IDeleteFile {
  loading: boolean;
  file: IStrapiFile;
  onFileDelete: (_: IStrapiFile) => void;
}

const DeleteFile = (props: IDeleteFile) => {
  const { loading = false, file, onFileDelete = (_: IStrapiFile) => {} } = props;
  const { t } = useTranslation('PROFILE');
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const onDeleteConfirm = () => {
    onFileDelete(file);
    setOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="ml-4 inline-flex items-center rounded-full border border-transparent p-0.5 text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
                <div>
                  <Dialog.Title as="h3" className="text-md font-bold leading-6 text-gray-800">
                    {t('CONFIRM_DELETE_FILE')}
                  </Dialog.Title>

                  <div className="mt-6 bg-gray-50 py-4 px-6">
                    <div className="mb-2 flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 h-7 w-7 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="mb-0 text-base font-normal text-gray-700">{file.name}</p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <GreyButton text={t('COMMON:NO')} onClick={() => setOpen(false)} />
                  <PrimaryButton text={t('COMMON:YES')} loading={loading} onClick={onDeleteConfirm} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteFile;
