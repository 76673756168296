import { FC } from 'react';

const PromotionsBox: FC<{ children: JSX.Element[]; title: string }> = ({ children, title }) => (
  <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
    <h3 className="mb-6 text-2xl font-bold text-gray-800">{title}</h3>
    <div className="space-y-4">{children}</div>
  </div>
);

export default PromotionsBox;
