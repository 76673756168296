import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { PrimaryButtonFull } from '../../components/Buttons';
import Footer from '../../components/Footer';
import InputButtonPresentational from '../../components/Form/InputButtonPresentational';
import Header from '../../components/Header';
import Subtotal from '../../components/Subtotal';
import ShoppingCartTable from '../../components/ProductsTable/ShoppingCartTable';
import { ICart } from '../../lib/fetch/carts';
import axios from 'axios';
import { useAuth } from '../../providers/AuthProvider';
import { calculateCartTotals } from '../../lib/utils/cart';
import { useCart } from '../../providers/CartProvider';

const { REACT_APP_RVA_APPROVE_CART_URL, REACT_APP_RVA_DISAPPROVE_CART_URL } = process.env;

interface IShoppingCartPresentational {
  cart?: ICart;
  deliveryAddressId?: number;
  readOnly?: boolean;
}

const ShoppingCartPresentational: FC<IShoppingCartPresentational> = (props) => {
  const { deliveryAddressId, readOnly = true } = props;
  const {
    cartState: { cart },
    onSearchAddToCart,
  } = useCart();

  const [statufullCart, setStatefullCart] = useState<ICart | undefined>(cart || undefined);
  const [{ token, user }] = useAuth();
  let { cart_items: cartItems, shipping: cartShipping } = cart || {};
  cartItems = cartItems || [];
  cartShipping = cartShipping || 0;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');
  const { fido } = user || {};
  const selectedForOrderCartItems = cartItems.filter((cartItem) => cartItem.selectedForOrder);
  const approveCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_APPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('CART_APPROVAL_ERROR'),
      });
    }
  };

  const rejectCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_DISAPPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('CART_REJECTION_ERROR'),
      });
    }
  };

  return (
    <div className="min-h-screen">
      <Header />

      <div className="py-10 px-4">
        <h2 className="mb-6 text-2xl font-bold text-gray-900">{t('TITLE')}</h2>

        <div className="flex flex-col gap-6 lg:flex-row">
          {readOnly ? (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <div className="mt-6 border-t py-6">
                <ShoppingCartTable cartItems={cartItems} readOnly={readOnly} />
              </div>
            </div>
          ) : (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <InputButtonPresentational
                onSubmit={(search) => onSearchAddToCart(search)}
                placeholder={t('PLACEHOLDER_SEARCH')}
                textButton={t('MENU:ADD_TO_CART')}
                type="text"
              />
              <div className="mt-6 border-t py-6">
                <ShoppingCartTable cartItems={cartItems} />
              </div>
            </div>
          )}

          <aside className="mx-auto w-full overflow-y-auto bg-gray-50 py-6 lg:w-2/6">
            <div className="px-4">
              {/* TODO: delivery estimate */}
              {/*<div className="rounded-md bg-yellow-50 px-2 py-3 text-center shadow-sm w-full border border-yellow-700 mb-6">*/}
              {/*  <p className="text-sm text-yellow-700">*/}
              {/*    {t('ESTIMATE_DELIVERY_FOR')}{' '}*/}
              {/*    <span className="uppercase">domani</span>*/}
              {/*  </p>*/}
              {/*</div>*/}
              <h3 className="mb-4 text-lg font-bold text-gray-900">{t('ORDER_SUMMARY')}</h3>
            </div>
            <Subtotal
              cartItems={selectedForOrderCartItems}
              shipping={cartShipping}
              shippingAddress={cart?.shipping_address}
            />
            <div className="mt-10 px-4">
              {readOnly ? (
                statufullCart?.approved === true ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white" disabled>
                      Approvato
                    </button>
                  </div>
                ) : statufullCart?.approved === false ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" disabled>
                      Rifiutato
                    </button>
                  </div>
                ) : (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" onClick={rejectCart}>
                      Rifiuta Carrello
                    </button>
                    <button
                      className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white"
                      onClick={approveCart}
                    >
                      Approva Carrello
                    </button>
                  </div>
                )
              ) : (
                <>
                  <p className="text-sm text-gray-600 mb-2">*{t('ORDER_PROCESSING_INFO')}</p>
                  <Link
                    to={
                      '/checkout-shipping' +
                      (deliveryAddressId ? `?${queryString.stringify({ deliveryAddressId })}` : '')
                    }
                    onClick={(e) => {
                      const { total } = calculateCartTotals(selectedForOrderCartItems || [], cartShipping);
                      if (fido?.max !== 0 && total > (fido?.remaining || 0)) {
                        e.preventDefault();
                        dispatchAlertChange({
                          open: true,
                          message: t('INSUFFICIENT_FIDO'),
                        });
                        return;
                      }
                      if (selectedForOrderCartItems?.length && total > 0) return;
                      e.preventDefault();
                      dispatchAlertChange({
                        open: true,
                        message: t('EMPTY_CART_ERROR'),
                      });
                    }}
                  >
                    <PrimaryButtonFull
                      text={t('CHECKOUT_BTN')}
                      onClick={(e) => {
                        const isCartNotApprovedForAgent = !cart?.approved && user?.role?.type === 'agente';

                        if (user?.customer.status === 3 || !user!.ordersEnabled || isCartNotApprovedForAgent) {
                          e.preventDefault();
                          dispatchAlertChange({
                            open: true,
                            message: isCartNotApprovedForAgent ? t('CART_NOT_APPROVED') : t('CHECKOUT_BTN_DISABLED'),
                          });
                          return;
                        }
                      }}
                    />
                  </Link>
                </>
              )}
            </div>
          </aside>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ShoppingCartPresentational;
