import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { ShoppingBagIcon, UserIcon, ArrowRightStartOnRectangleIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import { classNames } from '../../lib/utils';
import { useAlert } from '../../providers/AlertProvider';
import { isOrdersViewEnabled } from '../../lib/utils/userPermissions';

const ProfileMenu = () => {
  const { t } = useTranslation('MENU');
  const [{ user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { name = '', surname = '', customer = null, fido } = user || {};
  const warehouse = user?.warehouse ? user.warehouse?.name : user?.customer.warehouse_id?.name;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="group block flex-shrink-0">
        <div className="flex items-center">
          <div className="hidden text-right lg:inline">
            <p className="text-sm font-medium text-gray-400 group-hover:text-gray-500">
              {`${customer?.customer_id} - ${customer?.name}`}
            </p>
            <p>
              {fido?.max !== 0 && (
                <>
                  <span className="text-xs font-medium text-green-600 bg-green-100 rounded-full px-2">
                    {`€ ${(fido?.remaining || 0).toLocaleString('it-IT', {
                      // style: 'currency',
                      // currency: 'EUR',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </span>
                  <span className="text-sm font-medium text-gray-400 group-hover:text-gray-900"> | </span>
                </>
              )}
              <span className="text-sm font-medium text-gray-400 group-hover:text-gray-900">{warehouse} | </span>
              <span className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                {name} {surname}
              </span>
            </p>
          </div>
          <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 lg:hidden">
            <span className="font-medium leading-none text-white">TW</span>
          </span>
          <ChevronDownIcon className="-mr-1 ml-2 hidden h-5 w-5 lg:inline" aria-hidden="true" />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute max-lg:left-0 lg:right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                >
                  <UserIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  {t('PROFILE')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/my-orders"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={(e) => {
                    if (!isOrdersViewEnabled(user!)) {
                      e.preventDefault();
                      dispatchAlertChange({
                        open: true,
                        message: t('ORDERS_DISABLED'),
                      });
                      return;
                    }
                  }}
                >
                  <ShoppingBagIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {t('MY_ORDERS')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/products-list"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                >
                  <QueueListIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Liste prodotti
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() =>
                    dispatchAuthChange({
                      type: AuthActionType.Logout,
                    })
                  }
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                >
                  <ArrowRightStartOnRectangleIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {t('SIGN_OUT')}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;
