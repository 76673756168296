import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITab } from '.';
import { classNames } from '../../lib/utils';
import { AlertType, useAlert } from '../../providers/AlertProvider';

interface ITabBarSmall {
  tabs: ITab[];
}

const TabBarSmall: FC<ITabBarSmall> = (props) => {
  const { tabs } = props;
  const { t } = useTranslation('PRODUCTS');
  const [, dispatchAlertChange] = useAlert();

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500"
          defaultValue={tabs.find((tab) => tab.current)?.name || tabs[0].name}
          onChange={(event) => {
            const selectedTab = tabs[+event.target.value];
            selectedTab.onClick
              ? selectedTab.onClick()
              : dispatchAlertChange({
                  open: true,
                  type: AlertType.Info,
                  message: t('COMMON:AVAILABLE_SOON'),
                });
          }}
        >
          {tabs
            .filter((t) => !t.hidden)
            .map((tab, index) => (
              <option key={tab.name} value={index}>
                {tab.name}
              </option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs
            .filter((t) => !t.hidden)
            .map((tab) => (
              <div
                key={tab.name}
                className={classNames(
                  tab.current ? 'bg-red-100 text-red-700' : 'text-gray-500 hover:text-gray-700',
                  'cursor-pointer rounded-md px-2 py-1 text-xs font-medium',
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => {
                  tab.onClick
                    ? tab.onClick()
                    : dispatchAlertChange({
                        open: true,
                        type: AlertType.Info,
                        message: t('COMMON:AVAILABLE_SOON'),
                      });
                }}
              >
                {tab.name}
              </div>
            ))}
        </nav>
      </div>
    </div>
  );
};

export default TabBarSmall;
