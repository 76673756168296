import axios, { AxiosResponse } from 'axios';
import { IAddress } from '../../interfaces';

const { REACT_APP_ADDRESSES_URL } = process.env;

const remove = async (token: string, address: IAddress) => {
  const response = await axios.delete<IAddress>(`${REACT_APP_ADDRESSES_URL!}/${address.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default remove;
