import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { currency } from '../../../lib/utils';
import { getCartItemFinal, getCartItemNet } from '../../../lib/utils/promotions';

interface IExtendedPriceField {
  cartItem: ICartItem;
}

const ExtendedPriceField: FC<IExtendedPriceField> = (props) => {
  const { cartItem } = props;
  const { price, product, quantity, priceDiscounted } = cartItem || {};
  const { t } = useTranslation('PRODUCTS');

  return (
    <>
      <div className="flex flex-row gap-3">
        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light ">{t('LIST')}:</span>
          <span className="text-sm">{product.price_list ? `${currency(product.price_list)} (${quantity}x)` : '-'}</span>
        </div>

        {priceDiscounted ? (
          <div className="flex flex-col text-gray-400">
            <span className="text-xs font-light ">{t('DISCOUNT')}:</span>
            <span className="text-sm">{`${currency((price - priceDiscounted) * quantity)}  (${
              Math.round((100 - (priceDiscounted / price) * 100) * 100) / 100
            }%)`}</span>
          </div>
        ) : null}

        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light">{t('NET')}:</span>
          {priceDiscounted && <span className="line-through font-light text-gray-400">{currency(price)}</span>}
          <span className="text-sm">{getCartItemNet(cartItem)}</span>
        </div>

        <div className="flex flex-col">
          <span className="text-xs font-light text-gray-400">{t('FINAL')}:</span>
          {priceDiscounted && (
            <p className="text-sm line-through font-light text-gray-500">{currency(price * quantity)}</p>
          )}
          <span className="text-sm text-gray-700">{getCartItemFinal(cartItem)}</span>
        </div>
      </div>
    </>
  );
};

export default ExtendedPriceField;
