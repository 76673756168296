import { useState, useEffect, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import './ImportantMessages.css';

const mockData = [
  { id: 1, text: 'Ricordarsi di fornire i dettagli implementativi' },
  { id: 2, text: 'PER ORA QUESTI SONO DATI MOCK' },
  {
    id: 3,
    text: 'TESTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO LUNGO',
  },
  {
    id: 4,
    text: 'TESTO TRONCATO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO MOLTO LUNGO',
  },
];

const ImportantMessages = () => {
  const { t } = useTranslation('ALERT');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const startInterval = () => {
    if (mockData.length > 1) {
      intervalRef.current = setInterval(() => {
        setIsExiting(true);
      }, 5000);
    }
  };

  useEffect(() => {
    startInterval();
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleExited = () => {
    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % mockData.length);
    setIsExiting(false);
  };

  const handleDotClick = (index: number) => {
    if (index !== currentMessageIndex) {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
      setIsExiting(true);
      setTimeout(() => {
        setCurrentMessageIndex(index);
        setIsExiting(false);
        startInterval(); // Riavvia il timer dopo il cambio manuale
      }, 500); // Durata dell'effetto di dissolvenza
    }
  };

  return (
    <div className="mb-4 rounded-md border border-yellow-600 px-2 py-4">
      <div className="flex justify-between">
        <h3 className="text-base font-medium text-yellow-600 font-bold">{t('IMPORTANT_MESSAGE_TITLE')}</h3>
        <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
      </div>

      <div className="mt-2 text-sm text-gray-700 fixed-height" title={mockData[currentMessageIndex].text}>
        <CSSTransition in={!isExiting} timeout={500} classNames="fade" onExited={handleExited} unmountOnExit>
          <p>{mockData[currentMessageIndex].text}</p>
        </CSSTransition>
      </div>

      {mockData.length > 1 && (
        <div className="flex justify-center mt-2">
          {mockData.map((_, index) => (
            <span
              key={index}
              onClick={() => handleDotClick(index)}
              className={`h-2 w-2 mx-1 rounded-full cursor-pointer ${
                index === currentMessageIndex ? 'bg-yellow-600' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImportantMessages;
