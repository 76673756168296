import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import FullScreenLoading from '../../components/FullScreenLoading';
import { ITab, TabBarBig } from '../../components/TabBars';
import { Pagination } from '../../components/ProductsTable';
import MessageAlert from '../../components/Alert/MessageAlert';
import { ArrayParam, NumberParam, useQueryParams } from 'use-query-params';
import { QuoteStatus } from '../../lib/enums';
import { ProductsList } from '../../components/ProductsList/ProductsList';
import { IQuote } from '../../lib/fetch/quotes';
import { PrimaryButtonFull } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';

const ProductsListPresentational = ({
  quotes,
  loading,
  total,
  totalsByStatus,
  setPage,
  page,
  pageSize,
  setPageSize,
  pageCount,
  setSearch,
}: {
  quotes: IQuote[];
  loading: boolean;
  total: number;
  totalsByStatus: { archived: number; pending: number; completed: number };
  setPage: (page: number) => void;
  page: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageCount: number;
  setSearch: (search: string) => void;
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    status: ArrayParam,
    dateFilter: NumberParam,
  });
  const navigate = useNavigate();

  const tabs: ITab[] = [
    {
      name: t('QUOTE:ALL'),
      count: totalsByStatus.archived + totalsByStatus.completed + totalsByStatus.pending,
      current: !query.status,
      onClick: () => setQuery({ status: undefined }),
    },
    {
      name: t('QUOTE:COMPLETED_TAB'),
      count: totalsByStatus.completed,
      current: !!query.status && query.status.some((status) => status === QuoteStatus.Completed),
      onClick: () => setQuery({ status: [QuoteStatus.Completed] }),
    },
    {
      name: t('QUOTE:PENDING_TAB'),
      count: totalsByStatus.pending,
      current: !!query.status && query.status.some((status) => status === QuoteStatus.Pending),
      onClick: () => setQuery({ status: [QuoteStatus.Pending] }),
    },
    {
      name: t('QUOTE:ARCHIVED_TAB'),
      count: totalsByStatus.archived,
      current: !!query.status && query.status.some((status) => status === QuoteStatus.Archived),
      onClick: () => setQuery({ status: [QuoteStatus.Archived] }),
    },
  ];

  return (
    <div className="min-h-screen">
      <Header />

      <div className="mx-auto w-full flex-grow lg:flex lg:px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          <div className="bg-white lg:w-64 lg:flex-shrink-0 xl:w-72">
            <div className="relative h-full pl-4 pr-4 pt-6 pb-0 sm:pl-4 lg:pb-6 lg:pl-0 lg:pr-0">
              <div className="h-full lg:fixed lg:w-64 xl:w-72">
                <div className="mb-6 border-b border-gray-200 pb-2">
                  <h2 className="text-xl font-bold text-gray-900">{t('QUOTE:TITLE')}</h2>
                </div>

                <PrimaryButtonFull
                  text={t('QUOTE:CREATE_NEW_LIST')}
                  onClick={() => navigate('/products-list/create')}
                />
                <div className="flex gap-2 flex-col mt-8">
                  <p className="text-gray-800 font-medium text-sm">{t('QUOTE:SEARCH_LABEL')}</p>
                  <input
                    type="text"
                    className="w-full text-sm bg-gray-100 border-none rounded-md p-2 text-gray-600"
                    placeholder={t('QUOTE:SEARCH_PLACEHOLDER')}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white lg:min-w-0 lg:flex-1">
            <div className="h-full py-6 px-4 lg:pr-0">
              <TabBarBig tabs={tabs} />

              {!loading && !quotes?.length && (
                <div className="mx-auto mt-6 max-w-xl text-center">
                  <MessageAlert text={'Nessuna lista trovata'} />
                </div>
              )}

              <FullScreenLoading
                loading={loading}
                title={t('COMMON:LOADING')}
                subtitle={t('COMMON:LOADING_SUBTITLE')}
              />

              {quotes.length ? (
                <div className="mt-6">
                  <ProductsList productsList={quotes} />

                  <Pagination
                    currentItems={quotes}
                    onPageChange={setPage}
                    onPageSizeChange={setPageSize}
                    page={page}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    total={total}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsListPresentational;
