import { IUser } from '../../../lib/interfaces';

type Params = { user?: IUser };

const getCanBuyOutOfStock = (params: Params): boolean => {
  const { user } = params;

  const {
    canBuyOutOfStock: canBuyOutOfStockUser = false,
    customer: { canBuyOutOfStock: canBuyOutOfStockCustomer = false } = {},
  } = user || {};

  const canBuyOutOfStock = Boolean(canBuyOutOfStockUser && canBuyOutOfStockCustomer);

  return canBuyOutOfStock;
};

export default getCanBuyOutOfStock;
