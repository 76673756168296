import CryptoJS from 'crypto-js';

export const encryptCode = (code: string) => {
  const key = process.env.REACT_APP_IFRAME_ENCRYPTION_KEY || '';
  if (!key) return code;
  const keyObj = CryptoJS.enc.Utf8.parse(key);

  const encrypted = CryptoJS.AES.encrypt(code, keyObj, {
    mode: CryptoJS.mode.ECB, // Modalità di crittografia: Electronic Codebook Mode (ECB)
    padding: CryptoJS.pad.Pkcs7, // Metodo di riempimento dei dati: PKCS #7
  });

  return encrypted.toString();
};

export const decryptCode = (encryptedCode: string) => {
  const key = process.env.REACT_APP_IFRAME_ENCRYPTION_KEY || '';
  const keyObj = CryptoJS.enc.Utf8.parse(key);

  const decrypted = CryptoJS.AES.decrypt(encryptedCode, keyObj, {
    mode: CryptoJS.mode.ECB, // Modalità di crittografia: Electronic Codebook Mode (ECB)
    padding: CryptoJS.pad.Pkcs7, // Metodo di riempimento dei dati: PKCS #7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
