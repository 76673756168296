import ReactModal from 'react-modal';
import { IProduct } from '../../lib/fetch/products';
import { useTranslation } from 'react-i18next';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

interface IModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  product: IProduct | null;
  quantity: number;
}

const AddToCartModal: React.FC<IModalProps> = ({ isOpen, onRequestClose, onConfirm, product, quantity }) => {
  const { t } = useTranslation('PRODUCTS');

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg transform transition-all z-60"
      contentLabel={t('MODAL.ADD_TO_CART')}
    >
      <div className="flex flex-col items-center space-y-4">
        <ShoppingCartIcon className="h-12 w-12 text-red-600" aria-hidden="true" />
        <p
          className="text-center text-gray-600"
          dangerouslySetInnerHTML={{
            __html: t('MODAL.PRODUCT_ALREADY_IN_CART', {
              product: `${product?.attributes.code}`,
              quantity: `${quantity}`,
            }),
          }}
        />
        <div className="flex space-x-4">
          <button
            onClick={onConfirm}
            className="flex items-center justify-center rounded-md bg-red-600 px-6 py-2 text-white text-sm font-semibold shadow hover:bg-red-700"
          >
            {t('MODAL.YES_ADD_MORE')}
          </button>
          <button
            onClick={onRequestClose}
            className="flex items-center justify-center rounded-md border border-gray-300 px-6 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
          >
            {t('MODAL.NO_CANCEL')}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default AddToCartModal;
