import { useSearchParams } from 'react-router-dom';
import useRedirect from '../../hooks/useRedirect';
import { RedirectAction } from '../../lib/interfaces/IRedirect';
import RedirectPresentational from './RedirectPresentational';
import { useMemo } from 'react';

interface Props {}

const Redirect: React.FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const action: RedirectAction = useMemo(() => searchParams.get('action') as RedirectAction, [searchParams]);
  const { message } = useRedirect(action);

  return <RedirectPresentational message={message} />;
};

export default Redirect;
