import { Link } from 'react-router-dom';
import { getThumbnail } from '../../components/ProductsTable';
import { StrapiDataType } from '../../lib/interfaces/ICommons';
import { PRODUCT_DETAIL_PATHNAME } from '../../App';
import PurchasedProductOrderItem from './PurchasedProductOrderItem';
import { IPurchasedProduct } from '../../lib/fetch/orderItems';

interface Props {
  purchasedProduct: StrapiDataType<IPurchasedProduct>;
}

const PurchasedProductRow: React.FunctionComponent<Props> = (props) => {
  const { purchasedProduct } = props;
  return (
    <tr className="border-t">
      <td className="whitespace-nowrap py-2 pl-2 pr-4">
        <div className="flex items-center">{getThumbnail(purchasedProduct.attributes.productThumbnail || '')}</div>
      </td>
      <td className="whitespace-nowrap py-2 pl-2 pr-4">
        <Link to={`${PRODUCT_DETAIL_PATHNAME}/${purchasedProduct.attributes.productId}`} className="ml-3 flex h-full">
          <div className="flex h-full text-sm font-medium text-gray-900">{purchasedProduct.attributes.productCode}</div>
        </Link>
      </td>
      <PurchasedProductOrderItem orders={purchasedProduct.attributes.orders} />
    </tr>
  );
};

export default PurchasedProductRow;
