const ShippingStepTitle = ({ text, stepNumber }: { stepNumber: any; text: string }) => {
  return (
    <div className="mb-6 flex items-center border-b pb-4 text-sm font-medium" aria-current="step">
      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
        <span className="text-red-600">{stepNumber}</span>
      </span>
      <span className="ml-4 text-lg font-semibold text-gray-900">{text}</span>
    </div>
  );
};

export default ShippingStepTitle;
