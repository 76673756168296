import { ICustomerForAgent } from '../../lib/interfaces/ICustomerForAgent';
import PromotionsBox from './components/PromotionsBox';
import SinglePromotion from './components/SinglePromotion';

const AgentPromotionsDetails = (customer: ICustomerForAgent | undefined) => {
  if (!customer) return <></>;
  const { extra_sconto, customer_promotions, agent_promotions } = customer.promotions;
  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="relative h-full">
          <PromotionsBox title={'Extra Sconto'}>
            {extra_sconto?.map((promotion, index) => (
              <SinglePromotion title={promotion.title} from={promotion.validFrom} to={promotion.validTo} />
            ))}
          </PromotionsBox>
          <PromotionsBox title={'Promozioni Cliente'}>
            {customer_promotions.map((promotion, index) => (
              <SinglePromotion title={promotion.title} from={promotion.validFrom} to={promotion.validTo} />
            ))}
          </PromotionsBox>
          <PromotionsBox title={'Promozioni Agente'}>
            {agent_promotions.map((promotion, index) => (
              <SinglePromotion title={promotion.title} from={promotion.validFrom} to={promotion.validTo} />
            ))}
          </PromotionsBox>
        </div>
      </div>
    </div>
  );
};

export default AgentPromotionsDetails;
