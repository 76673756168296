import axios from 'axios';
import { IProductStock } from './index';

const { REACT_APP_PRODUCTS_URL } = process.env;
const instance = axios.create();

type Params = {
  token: string | null;
  id: number;
};

const findProductStock = async (params: Params): Promise<IProductStock> => {
  const { token, id } = params;

  const response = await instance.get<IProductStock>(`${REACT_APP_PRODUCTS_URL}/${id}/stock`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export default findProductStock;
