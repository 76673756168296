import axios from 'axios';
import { IQuoteAttributes } from '.';
import qs from 'qs';
import i18n from '../../../i18n';
import { StrapiListEntityResponse } from '../../interfaces/ICommons';
import { QuoteStatus } from '../../enums';

const { REACT_APP_BACKEND_URL } = process.env;
const axiosInstance = axios.create();

interface ISearchParams {
  page?: number;
  pageSize?: number;
  sort?: string | string[];
  status?: string;
  search?: string;
}

export const fetchQuotes = async (token: string | null, params: ISearchParams) => {
  const { page = 1, pageSize = 6, sort, status, search } = params;
  const pagination = { page, pageSize };
  const lang = i18n.languages[0];

  const query = qs.stringify(
    {
      pagination,
      sort,
      locale: lang,
      populate: '*',
      filters: {
        status,
        ...(search
          ? {
              $or: [{ name: { $contains: search } }, { description: { $contains: search } }],
            }
          : {}),
      },
    },
    { encodeValuesOnly: true },
  );

  const headers = { Authorization: `Bearer ${token}` };

  const data = await axiosInstance.get<StrapiListEntityResponse<IQuoteAttributes>>(
    `${REACT_APP_BACKEND_URL!}/quotes?${query}`,
    { headers },
  );

  return data.data;
};

export const fetchQuoteTotalsByStatus = async (token: string | null) => {
  const [archived, pending, completed] = await Promise.all([
    fetchQuoteIds(token, { status: QuoteStatus.Archived }),
    fetchQuoteIds(token, { status: QuoteStatus.Pending }),
    fetchQuoteIds(token, { status: QuoteStatus.Completed }),
  ]);

  return {
    archived: archived.meta?.pagination.total || 0,
    pending: pending.meta?.pagination.total || 0,
    completed: completed.meta?.pagination.total || 0,
  };
};

export const fetchQuoteIds = async (token: string | null, params: ISearchParams) => {
  const { page = 1, pageSize = 6, sort, status } = params;
  const pagination = { page, pageSize };
  const lang = i18n.languages[0];

  const query = qs.stringify(
    {
      pagination,
      sort,
      locale: lang,
      fields: ['id'],
      filters: {
        status,
      },
    },
    { encodeValuesOnly: true },
  );

  const headers = { Authorization: `Bearer ${token}` };

  const data = await axiosInstance.get<StrapiListEntityResponse<{ id: number }>>(
    `${REACT_APP_BACKEND_URL!}/quotes?${query}`,
    { headers },
  );

  return data.data;
};
