import axios from 'axios';
const instance = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

const forgotTemporaryPassword = async ({ identifier }: { identifier: string }) => {
  const url = `${REACT_APP_BACKEND_URL}/auth/forgot-temporary-password`;
  const response = await instance.post(url, { identifier });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default forgotTemporaryPassword;
