import axios, { AxiosResponse } from 'axios';
import { IPayment } from './index';

const { REACT_APP_PAYMENTS_URL } = process.env;

export interface IPaymentCreate extends Omit<IPayment, keyof { id: string; providerId: string }> {
  order: number;
  users_permissions_user: number;
}

const create = async (token: string, payment: IPaymentCreate) => {
  const response = await axios.post(
    REACT_APP_PAYMENTS_URL!,
    { data: payment },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
