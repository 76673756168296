import * as t from 'io-ts';
import { MetaModel } from './PaginatedResponse';

export const LinkAttributesModel = t.type({
  text: t.string,
  href: t.string,
});

export const LinkModel = t.type({
  id: t.number,
  attributes: LinkAttributesModel,
});

export const PaginatedLinkModel = t.type({
  data: t.array(LinkModel),
  meta: MetaModel,
});

export type PaginatedLink = t.TypeOf<typeof PaginatedLinkModel>;

export type LinkType = t.TypeOf<typeof LinkModel>;
