import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/Breadcrumbs';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { IProduct } from '../../lib/fetch/products';
import { ITab, TabBarBig } from '../../components/TabBars';
import FullScreenLoading from '../../components/FullScreenLoading';
import {
  MadeOrderBadge,
  OutProductionBadge,
  PiecesBadge,
  UnavailableBadge,
  WarehouseModal,
} from '../../components/StockBadge';
import { classNames, currency, quantityInputProps as _quantityInputProps } from '../../lib/utils';
import { DEFAULT_IMAGE_PLACEHOLDER, HIDDEN_MANUFACTURERS_NAMES } from '../../lib/constants';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from '../../lib/utils/manufacturers';
import { useSettings } from '../../providers/SettingsProvider';
import { CrossReferences, TechnicalSpecs } from './Tabs';
import { ProductDetailsTab } from '../../lib/enums';
import { PromotionPrice } from './Components';
import { getExtraDiscountPrice, getPromoDescription } from '../../lib/utils/promotions';
import moment from 'moment';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/mousewheel';
import { FreeMode, Navigation, Thumbs, Mousewheel } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';
import './swiper.css';
import { OemCodes } from '../../components/ProductsTable/DetailsDisclosure/Tabs';
import { ROUTES } from '../../routes';
import { useAuth } from '../../providers/AuthProvider';
import { useCart } from '../../providers/CartProvider';
const { REACT_APP_MEDIA_LIBRARY_URL } = process.env;

interface IProductDetailPresentational {
  loading: boolean;
  product?: IProduct;
  crossReferences: IProduct[];
  loadingCrossReferences: boolean;
}

const IMAGE_PLACEHOLDER = Object.freeze({
  id: 0,
  lang: '',
  description: 'Picture not available',
  name: 'Picture not available',
  type: 'JPG',
  url: DEFAULT_IMAGE_PLACEHOLDER,
});

const ProductDetailPresentational: FC<IProductDetailPresentational> = (props) => {
  const { crossReferences = [], loadingCrossReferences = false, loading = false, product } = props;
  const [{ user }] = useAuth();
  const [{ tecdocManufacturers }] = useSettings();
  const {
    t,
    i18n: { language },
  } = useTranslation('PRODUCTS');
  const { handleSubmit, register, setValue: setFormValue, watch } = useForm();
  const [selectedTab, setSelectedTab] = useState(ProductDetailsTab.TECHNICAL_SPECS);
  const quantityInputProps = product ? _quantityInputProps(product, user) : undefined;
  const [actualQuantity, setActualQuantity] = useState<number>(quantityInputProps?.defaultValue || 0);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const { addToCart } = useCart();
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'quantity') {
        setActualQuantity(value.quantity);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (quantityInputProps?.defaultValue) {
      setActualQuantity(quantityInputProps.defaultValue);
      setFormValue('quantity', quantityInputProps.defaultValue);
    }
  }, [quantityInputProps?.defaultValue]);

  const detailsTabs: ITab[] = [
    {
      name: t(ProductDetailsTab.TECHNICAL_SPECS),
      current: selectedTab === ProductDetailsTab.TECHNICAL_SPECS,
      onClick: () => setSelectedTab(ProductDetailsTab.TECHNICAL_SPECS),
    },
    {
      name: t(ProductDetailsTab.CROSS_REFERENCES),
      current: selectedTab === ProductDetailsTab.CROSS_REFERENCES,
      onClick: () => setSelectedTab(ProductDetailsTab.CROSS_REFERENCES),
    },
    {
      name: t(ProductDetailsTab.OEM_CODES),
      current: selectedTab === ProductDetailsTab.OEM_CODES,
      onClick: () => setSelectedTab(ProductDetailsTab.OEM_CODES),
    },
  ];

  if (loading)
    return <FullScreenLoading loading={loading} title={t('LOADING_TITLE')} subtitle={t('LOADING_SUBTITLE')} />;
  if (!product) return null;

  const { attributes, tecdoc } = product;

  const { manufacturer, description: name, price_net: price } = attributes;

  let manufacturerName = manufacturer?.data.attributes.name;
  if (HIDDEN_MANUFACTURERS_NAMES.includes((manufacturerName || '').toUpperCase())) {
    manufacturerName = `${manufacturer?.data.id || ''}`;
  }
  const manufacturerLogo = (
    tecdoc?.dataSupplierId
      ? getTecdocManufacturerById(tecdoc?.dataSupplierId, tecdocManufacturers)
      : getTecdocManufacturerByName(manufacturer?.data.attributes.name, tecdocManufacturers)
  )?.dataSupplierLogo.imageURL800;

  const images =
    tecdoc?.images.map((image, idx) => ({
      id: idx,
      name: image.fileName,
      url: image.imageURL3200,
    })) || [];

  if (product.attributes.thumbnail) {
    images.push({
      id: 0,
      name: 'Product thumbnail',
      url: product.attributes.thumbnail,
    });
  }

  if (!images.length) images.push(IMAGE_PLACEHOLDER);

  const disabled = quantityInputProps!.disabled || !price || !product.attributes.active;

  const steps = [
    {
      name: product.attributes.code,
      to: ROUTES.SEARCH.PRODUCT_DETAIL + '/' + product.id,
      current: true,
    },
  ];

  return (
    <div className="min-h-screen">
      <Header />
      <Breadcrumbs steps={steps} />
      <div className="mx-auto w-full flex-grow overflow-x-hidden lg:flex lg:px-4">
        <div className="mb-10 flex flex-1 flex-col items-stretch overflow-hidden xl:flex-row">
          <main className="flex-1 overflow-y-auto">
            <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last">
              <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                  <div className="flex flex-col-reverse lg:flex-row h-96">
                    <div className="flex flex-col lg:flex-row w-full">
                      <div className="hidden lg:flex flex-1 min-w-28">
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          spaceBetween={10}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
                          direction={'vertical'}
                          navigation={true}
                          className="lateralSwiper"
                          mousewheel={true}
                        >
                          {images.map((image, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <img
                                  src={image.url}
                                  alt={image.name}
                                  className="h-full w-full object-cover object-center rounded-md"
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                      <Swiper
                        spaceBetween={10}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
                        className="w-full aspect-video"
                        mousewheel={true}
                      >
                        {images.map((image, index) => {
                          return (
                            <SwiperSlide key={index} className="flex items-center justify-center">
                              <img src={image.url} alt={image.name} className="max-h-full max-w-full object-contain" />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                  {/* Product info */}
                  <div className="mt-6 sm:px-0 lg:mt-0">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.attributes.code}</h1>
                    <h2 className="text-2xl font-bold tracking-tight text-gray-600">
                      {product.attributes.description ||
                        product.tecdoc?.genericArticles[product.tecdoc?.genericArticles.length - 1]
                          .genericArticleDescription ||
                        name ||
                        product.attributes.family?.data.attributes.name}
                    </h2>

                    <div className="mt-3">
                      {product.attributes.priceDiscounted &&
                      getExtraDiscountPrice(product.attributes.priceDiscounted, actualQuantity) ? (
                        <PromotionPrice product={product} quantity={actualQuantity} />
                      ) : (
                        <p className={classNames(price ? 'text-3xl text-gray-700' : 'text-2xl text-gray-500')}>
                          {price ? currency(price) : t('PRICE_NOT_AVAILABLE')}
                        </p>
                      )}
                      {!!product.attributes.promotions.data.length &&
                        product.attributes.promotions.data
                          .filter((p) => !!p.extraScontoItem || !!p.promoTipoSuTipo)
                          .map((promotion) => (
                            <div key={promotion.id} className="mt-4 rounded-md bg-gray-100 px-3 py-2">
                              <p className="text-xs text-gray-700">{t('PROMOTION_AVAILABLE')}:</p>
                              <p
                                className=" text-sm text-red-500"
                                dangerouslySetInnerHTML={{
                                  __html: getPromoDescription(promotion, language),
                                }}
                              ></p>
                              <p className="mt-3 text-xs text-gray-700">{t('MENU:DESCRIPTION')}: </p>
                              <p className="text-xs text-gray-500">{promotion.description}</p>
                              {promotion?.image?.url && (
                                <img
                                  className="mt-3 mb-3 h-auto"
                                  src={
                                    promotion.image.url.startsWith('https://')
                                      ? promotion.image.url
                                      : `${REACT_APP_MEDIA_LIBRARY_URL}${promotion.image.url}`
                                  }
                                  alt="promozione"
                                />
                              )}
                            </div>
                          ))}
                    </div>

                    <div className="mt-10">
                      {manufacturerLogo ? (
                        <img className="mb-3 h-auto w-24" src={manufacturerLogo} alt={manufacturerName} />
                      ) : (
                        manufacturerName
                      )}
                      <p className="text-sm text-gray-500 ">{/* {standardDescription || ''} */}</p>
                    </div>

                    <div className="mt-10">
                      {!product.attributes.active && <OutProductionBadge />}
                      {product.attributes.active &&
                        product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                        product.attributes.quantity.quantityOtherWarehouses === 0 && <UnavailableBadge />}
                      {product.attributes.active && product.attributes.quantity.quantityDefaultWarehouse > 0 && (
                        <div>
                          <PiecesBadge quantity={product.attributes.quantity.quantityDefaultWarehouse} />
                        </div>
                      )}
                      {product.attributes.active &&
                        product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                        product.attributes.quantity.quantityOtherWarehouses > 0 && (
                          <>
                            <MadeOrderBadge quantity={product.attributes.quantity.quantityOtherWarehouses} />
                            <WarehouseModal otherWarehouses={product.attributes.quantity.otherWarehouses} />
                          </>
                        )}
                    </div>
                    <p className="text-xs">
                      {t('MIN_QUANTITY')}
                      {product?.attributes?.sales_package_sizes
                        ? Math.floor(product?.attributes?.sales_package_sizes)
                        : 1}
                    </p>
                    {product.attributes.mpcExpire && (
                      <p className="text-xs text-red-600">
                        {t('NET_AVAILABILITY')}
                        {moment(product?.attributes?.mpcExpire, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </p>
                    )}
                    <form
                      className="mt-10 flex items-center"
                      onSubmit={handleSubmit(
                        ({ quantity }) =>
                          !disabled && addToCart({ product, quantity: +quantity || 0, displayAddToCartModal: true }),
                      )}
                    >
                      <input
                        type="number"
                        className="mr-2 block w-20 rounded-md border-transparent bg-gray-100 py-2 text-sm shadow-sm focus:border-red-500 focus:ring-red-500"
                        {...quantityInputProps}
                        min={1}
                        {...register('quantity', {
                          required: true,
                          disabled,
                          valueAsNumber: true,
                        })}
                      />
                      <button
                        type="submit"
                        disabled={disabled || actualQuantity < 1}
                        className={classNames(
                          'flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
                          !(disabled || actualQuantity < 1) && 'hover:bg-red-700',
                          (disabled || actualQuantity < 1) && 'opacity-50',
                        )}
                        style={disabled || actualQuantity < 1 ? { cursor: 'not-allowed' } : {}}
                      >
                        <ShoppingCartIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        {t('ADD_TO_CART')}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <aside className="mx-auto w-full max-w-2xl overflow-y-auto bg-white py-6 px-4 lg:px-0 xl:w-96 xl:max-w-none xl:pr-0 xl:pl-4">
            {/* TODO: DeliveryBox */}
            {/*<DeliveryBox />*/}
          </aside>
        </div>
      </div>

      <div className="mb-10 px-4">
        <div className="mb-4 sm:mx-4 sm:mb-0">
          <TabBarBig tabs={detailsTabs} />
        </div>
        <div className="rounded-md border border-gray-200 p-6 px-4">
          {selectedTab === ProductDetailsTab.TECHNICAL_SPECS && (
            <>
              <div className="px-3 py-4">
                <TechnicalSpecs product={product} />
              </div>
            </>
          )}
          {selectedTab === ProductDetailsTab.CROSS_REFERENCES && (
            <>
              <div className="py-4 px-3">
                <CrossReferences crossReferences={crossReferences} loading={loadingCrossReferences} />
              </div>
            </>
          )}
          {selectedTab === ProductDetailsTab.OEM_CODES && (
            <>
              <div className="px-3 py-4">
                <OemCodes product={product} />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailPresentational;
