import { FormProvider, useForm } from 'react-hook-form';
import SofinnResetPasswordPresentational from './SofinnForgotPasswordPresentational';
import { useMutation } from 'react-query';
import { ISofinnForgotPasswordForm } from '../../../lib/interfaces/ISofinn';
import { yupResolver } from '@hookform/resolvers/yup';
import sofinnResetPasswordFormValidator from './validator';
import { useNavigate } from 'react-router-dom';
import forgotTemporaryPassword from '../../../lib/fetch/auth/forgotTemporaryPassword';
import { useTranslation } from 'react-i18next';

const SofinnForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('FORGOT_PASSWORD_SOFINN');

  const formMethods = useForm<ISofinnForgotPasswordForm, ISofinnForgotPasswordForm>({
    //@ts-ignore
    resolver: yupResolver(sofinnResetPasswordFormValidator),
    mode: 'onSubmit',
  });

  const handleRedirect = (errorMessage?: string) => {
    const error = errorMessage ? `?errorMessage=${errorMessage}` : '';
    navigate(`/cati-b2b/forgot-password-feedback${error}`);
  };

  const { mutate } = useMutation('sofinnResetPassword', forgotTemporaryPassword, {
    onSuccess: () => {
      handleRedirect();
    },
    onError: () => {
      handleRedirect(t('ERROR_FORGOT_PASSWORD'));
    },
  });

  const onForgotPassword = (values: ISofinnForgotPasswordForm) => {
    mutate({ identifier: values.identifier });
  };

  return (
    <FormProvider {...formMethods}>
      <SofinnResetPasswordPresentational onForgotPassword={onForgotPassword} />
    </FormProvider>
  );
};

export default SofinnForgotPassword;
