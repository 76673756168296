import { useFormContext } from 'react-hook-form';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import IResetTemporaryPasswordForm from '../../Profile/components/ResetTemporaryPassword/interfaces/resetPasswordInAppForm';
import { useTranslation } from 'react-i18next';

interface Props {
  onResetPassword: (values: IResetTemporaryPasswordForm) => void;
}

const SofinnResetTemporaryPasswordForm: React.FC<Props> = (props) => {
  const { onResetPassword } = props;
  const { t } = useTranslation('SOFINN_RESET_TEMPORARY_PASSWORD');

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<IResetTemporaryPasswordForm, IResetTemporaryPasswordForm>();

  return (
    <form
      onSubmit={handleSubmit(onResetPassword)}
      className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]"
    >
      <h4 className="uppercase">{t('RESET_TEMPORARY_PASSWORD_MSG')}</h4>
      <div className="grid gap-2 grid-cols-1 mt-4">
        <span className="text-[14px]">{t('PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('PASSWORD')}
          type="password"
          {...register('password')}
          hasError={!!errors.password}
          helperText={errors.password?.message}
        />
        <span className="text-[14px]">{t('REPEAT_PASSWORD')}:</span>
        <SofinnInput
          placeholder={t('REPEAT_PASSWORD')}
          type="password"
          {...register('repeatPassword')}
          hasError={!!errors.repeatPassword}
          helperText={errors.repeatPassword?.message}
        />
      </div>
      <div className="flex flex-row justify-between">
        <SofinnButton text="Invia" className="w-full px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75" />
      </div>
    </form>
  );
};

export default SofinnResetTemporaryPasswordForm;
