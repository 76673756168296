import { useTranslation } from 'react-i18next';
import { CheckBoxPresentational, InputPresentational, SelectPresentational } from '../../../../../components/Form';
import { useFormContext } from 'react-hook-form';
import { COUNTRIES } from '../../../../../lib/utils';

const AddressForm = () => {
  const { t } = useTranslation('PROFILE');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <form>
        <div className="mt-6 grid grid-cols-1 gap-4">
          <InputPresentational
            label={t('TITLE_ADDRESS')}
            id="title"
            type="text"
            {...register('title', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="on"
            hasError={!!errors.title}
            helperText={errors.title?.message as string}
          />

          <InputPresentational
            label={t('NAME_SURNAME')}
            id="name"
            type="text"
            {...register('name', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="name"
            hasError={!!errors.name}
            helperText={errors.name?.message as string}
          />

          <InputPresentational
            label={t('PHONE_NUMBER')}
            id="phone"
            type="text"
            {...register('phone', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="tel"
            hasError={!!errors.phone}
            helperText={errors.phone?.message as string}
          />
          <InputPresentational
            label={t('ADDRESS')}
            id="address-1"
            type="text"
            {...register('addressFirstLine', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="address-line1"
            hasError={!!errors.addressFirstLine}
            helperText={errors.addressFirstLine?.message as string}
          />
          <InputPresentational
            label={t('ADDRESS_2')}
            id="address-2"
            type="text"
            {...register('addressSecondLine')}
            autoComplete="address-line2"
            hasError={!!errors.addressSecondLine}
            helperText={errors.addressSecondLine?.message as string}
          />
        </div>

        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <InputPresentational
            label={t('ACTIVATION_REQUEST:STEP3_CAP')}
            id="postalCode"
            type="text"
            {...register('postalCode', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="postal-code"
            hasError={!!errors.postalCode}
            helperText={errors.postalCode?.message as string}
          />
          <InputPresentational
            label={t('ACTIVATION_REQUEST:STEP3_CITY')}
            id="municipality"
            type="text"
            {...register('municipality', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="address-level2"
            hasError={!!errors.municipality}
            helperText={errors.municipality?.message as string}
          />
          <InputPresentational
            label={t('ACTIVATION_REQUEST:STEP3_STATE_PROVINCE')}
            id="province"
            type="text"
            {...register('province')}
            autoComplete="address-level1"
            hasError={!!errors.province}
            helperText={errors.province?.message as string}
          />
          <SelectPresentational
            label={t('ACTIVATION_REQUEST:STEP3_NATION')}
            id="country"
            options={[{ name: '-', value: '' }, ...COUNTRIES]}
            {...register('country', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="country-name"
            hasError={!!errors.country}
            helperText={errors.country?.message as string}
          />

          <CheckBoxPresentational
            id="defaultShipping"
            {...register('deliveryDefault')}
            label={t('PROFILE:SET_DEFAULT_SHIPPING')}
            hasError={!!errors.deliveryDefault}
            helperText={errors.deliveryDefault?.message as string}
          />

          <CheckBoxPresentational
            id="defaultBilling"
            {...register('billingDefault')}
            label={t('PROFILE:SET_DEFAULT_BILLING')}
            hasError={!!errors.billingDefault}
            helperText={errors.billingDefault?.message as string}
          />
        </div>
      </form>
    </>
  );
};

export default AddressForm;
