import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../lib/interfaces';
import { COUNTRIES } from '../../../../../lib/utils';

const Address = ({ address }: { address: IAddress }) => {
  // const { name, phone, addressFirstLine, addressSecondLine, postalCode, municipality, province, country } = address;
  const { name, address: addressLine, zipcode, city, district } = address;
  const { t } = useTranslation('PROFILE');

  return (
    <p className="text-sm text-gray-600">
      <span className="font-semibold">{addressLine}</span>
      <br />
      {city}
      <br />
      {zipcode}
      <br />
      {district}
      <br />
    </p>
  );
};

export default Address;
