import { classNames } from '../../lib/utils';

interface ILightPrimaryButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
}

const LightPrimaryButton = ({ text, type = 'button', onClick = () => {}, disabled = false }: ILightPrimaryButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'flex justify-center rounded-md border border-transparent bg-red-200 py-2 px-4 text-sm font-medium uppercase text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2',
        disabled ? 'cursor-not-allowed' : 'hover:bg-red-300 hover:text-red-700',
      )}
    >
      {text}
    </button>
  );
};

export default LightPrimaryButton;
