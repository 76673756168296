import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import errorLottie from '../../lotties/error.json';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

const NotFound = () => {
  const { t } = useTranslation('NOT_FOUND');
  return (
    <div className="flex min-h-screen bg-gray-50">
      <div className="flex w-full flex-col items-center justify-center p-12">
        <Lottie loop animationData={errorLottie} play speed={0.2} style={{ width: 200, height: 200 }} />

        <h1 className="mb-8 text-center text-4xl font-bold text-red-700">{t('TITLE')}</h1>

        <p
          className="mb-8 text-center text-sm text-gray-600"
          dangerouslySetInnerHTML={{
            __html: t('DESCRIPTION'),
          }}
        />
        <Link to="/login">
          <PrimaryButton text={t('FORGOT:BACK_LOGIN')} />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
