import { ILoginForm } from './index';
import IntestazioneForm from './IntestazioneForm';
import FormLoginSofin from './FormLoginSofinn';
import RecuperoCredenzialiSofinn from './RecuperoCredenzialiSofinn';
import DettagliSofinn from './DettagliSofinn';

interface Props {
  loading: boolean;
  onLogin: (formData: ILoginForm) => void;
  isError: boolean;
}

const SofinnLoginPresentational = (props: Props) => {
  const { onLogin, isError } = props;
  return (
    <div className="bg-center bg-cover min-h-screen bg-[url('/public/images/sofinn/BANNER-E-COMMERCE_nero.jpg')]">
      <div className="flex justify-end w-full h-full px-[15px] py-[5px]">
        <div className="relative max-w-[370px] w-full rounded-[3px] mt-[30px] m-[15px]">
          <div className="relative max-w-[370px] w-full bg-[#fff] border-[#fff] border-[1px] rounded-[3px]">
            <IntestazioneForm />
            <FormLoginSofin onSubmit={onLogin} isError={isError} />
            <RecuperoCredenzialiSofinn />
          </div>
          <DettagliSofinn />
        </div>
      </div>
    </div>
  );
};

export default SofinnLoginPresentational;
