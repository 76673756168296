import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

const { REACT_APP_PRODUCTS_URL } = process.env;

interface ISearchAastoreParams {
  pcode: string;
  ls: string;
}

interface ISearchAastoreResponse {
  data: string | null;
}

const getAastoreProductCode = async (token: string | null, params: ISearchAastoreParams) => {
  const { pcode, ls } = params;

  const query = `${qs.stringify({
    pcode,
    ls,
  })}`;
  const response = await axios.get<ISearchAastoreResponse>(`${REACT_APP_PRODUCTS_URL!}/aastoreSearch?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getAastoreProductCode;
