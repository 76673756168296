import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ForgotPresentational from './ForgotPresentational';
import { AlertType } from '../../providers/AlertProvider';
import { useMutation } from 'react-query';
import forgotTemporaryPassword from '../../lib/fetch/auth/forgotTemporaryPassword';

const Forgot = () => {
  const { t } = useTranslation('FORGOT');
  const [result, setResult] = useState<AlertType | undefined>(undefined);
  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');

  const { mutate, isLoading } = useMutation('forgotTemporaryPassword', forgotTemporaryPassword, {
    onSuccess: () => {
      setResult(AlertType.Success);
      setResultTitle(t('SUCCESS_TITLE'));
      setResultDescription(t('SUCCESS_DESCRIPTION'));
    },
    onError: () => {
      setResult(AlertType.Error);
      setResultTitle(t('ERROR_TITLE'));
      setResultDescription(t('ERROR_DESCRIPTION'));
    },
  });

  const onForgot = async ({ identifier }: { identifier: string }) => {
    mutate({ identifier });
  };

  return (
    <ForgotPresentational
      loading={isLoading}
      result={result}
      resultTitle={resultTitle}
      resultDescription={resultDescription}
      onForgot={onForgot}
    />
  );
};

export default Forgot;
