import { classNames } from '../../lib/utils';
import Spinner from '../Spinner';

interface IGreyButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const GreyButton = ({ text, type = 'button', onClick = () => {}, disabled = false, loading = false }: IGreyButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        'text-grey-800 flex justify-center rounded-md border border-transparent bg-gray-100 py-2 px-4 text-sm font-medium uppercase shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2',
        disabled ? 'cursor-not-allowed' : 'hover:bg-gray-300',
      )}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default GreyButton;
