import axios, { AxiosResponse } from 'axios';
import { IAastoreLogin } from '../../interfaces/IAastore';
import { IUser } from '../../interfaces';
import { me } from '../auth';

const { REACT_APP_AASTORE_URL, REACT_APP_AASTORE_USERNAME, REACT_APP_AASTORE_PASSWORD } = process.env;

const loginAastore = async (user: IUser, lang: string, token: string | null) => {
  const { error: meError, data: updatedUser } = await me(token!, lang);
  const response = await axios.post<IAastoreLogin>(REACT_APP_AASTORE_URL || '', {
    username: REACT_APP_AASTORE_USERNAME,
    password: REACT_APP_AASTORE_PASSWORD,
    options: {
      ext_id: user.customer.customer_id,
      params: {
        username: user.username,
        customer_name: user.customer?.name,
        user_id: user.id,
        credits: meError ? user.customer.credits || 0 : updatedUser.customer.credits || 0,
      },
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default loginAastore;
