import { IProductQuantity } from '../../components/ProductsTable';
import { ICartItem } from '../../lib/fetch/cartItems';
import { ICart } from '../../lib/fetch/carts';
import { IProduct } from '../../lib/fetch/products';

export interface CartState {
  cart: ICart | null;
  isLoading: boolean;
  totalQuantity: number;
  setCart: (cart: ICart) => void;
}

export enum CartActionType {
  SetCart = 'SET_CART',
  EmptyCart = 'EMPTY_CART',
  SetLoading = 'SET_LOADING',
}

export interface CartAction {
  type: CartActionType;
  cart?: ICart | null;
  isLoading?: boolean;
}

export interface AddToCartParams {
  product: IProduct;
  quantity: number;
  displayAddToCartModal?: boolean;
}

export interface UpdateCartItemParams {
  cartItem: ICartItem;
  newQuantity: number;
}

export interface ICartContext {
  cartState: CartState;
  dispatchState: (_action: CartAction) => void;
  addToCart: (params: AddToCartParams) => Promise<void>;
  fetchCart: () => void;
  onAddAll: (products: IProduct[], productQuantities: IProductQuantity[]) => Promise<void>;
  onFlushCart: () => Promise<void>;
  onItemRemove: (cartItem: ICartItem) => Promise<void>;
  onItemQuantityChange: (params: UpdateCartItemParams) => Promise<void>;
  dispatchOverlayChange: (params: { open: boolean }) => void;
  isOverlayOpen: boolean;
  onSearchAddToCart: (search: string) => Promise<void>;
  onExcerptChange: (cartItem: ICartItem, excerpt: boolean) => Promise<void>;
  onSelectedForOderChange: (cartItem: ICartItem, selected: boolean) => Promise<void>;
}
