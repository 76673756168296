import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../FullScreenLoading';

const PublicRoute: React.FC<{
  element: React.ReactElement;
  privateRedirect?: string;
}> = (props) => {
  const { t } = useTranslation('COMMON');
  const [{ appLoaded, isAuthenticated, user }] = useAuth();
  let { element: PublicElement, privateRedirect = '/search-results' } = props;

  if (user && user.role && !user.customer) {
    if (user.role.type === 'rva') privateRedirect = '/rva';
    else if (user.role.type === 'agente') privateRedirect = '/agent';
  }

  if (appLoaded) return isAuthenticated ? <Navigate replace to={privateRedirect} /> : PublicElement;

  return <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />;
};

export default PublicRoute;
