import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectPresentational {
  label: string;
  id: string;
  name: string;
  autoComplete: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  options: readonly { name: string; value: string }[];
}

const SelectPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      options = [],
      ...rest
    }: ISelectPresentational,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <div>
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        <div className="relative mt-1">
          <select
            ref={ref}
            id={id}
            name={name}
            autoComplete={autoComplete}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
            {...rest}
          >
            {options.map(({ name, value }, index) => (
              <option value={value} key={index}>
                {t(name)}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {hasError && <ExclamationCircleIcon className="m-5 h-5 w-5 text-red-500" aria-hidden="true" />}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  },
);

export default SelectPresentational;
