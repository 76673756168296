import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import MessageAlert from '../../components/Alert/MessageAlert';
import Footer from '../../components/Footer';
import FullScreenLoading from '../../components/FullScreenLoading';
import Header from '../../components/Header';
import { IFrameBannerProfileBottom } from '../../components/IFrameBanner/ProfileBottom';
import { Sidebar } from '../../components/Sidebar';
import i18n from '../../i18n';
import fetchNewsCategories from '../../lib/fetch/newsposts/categories';
import { NewsPostCategory } from '../../lib/models/NewsPost';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { NewsBreadcrumb } from './NewsBreadcrumb';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import NewsList from './NewsList';

export const NewsCategories = () => {
  const [{ token }] = useAuth();
  const { t } = useTranslation('COMMON');
  const { t: tNews } = useTranslation('NEWS');
  const [, dispatchAlertChange] = useAlert();
  const [selectedCategory, setSelectedCategory] = useState<NewsPostCategory | null>(null);

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const {
    data: newsCategories,
    isError,
    isLoading,
    refetch,
  } = useQuery<PaginatedResponse<NewsPostCategory>>(
    'newspostscategories',
    () => fetchNewsCategories(token, { page: 1, limit: 20, sort: ['name:asc'] }),
    {
      onSuccess,
      onError,
    },
  );

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  return (
    <div className="min-h-screen">
      <Header />

      <NewsBreadcrumb categoryName={selectedCategory?.attributes?.name} setSelectedCategory={setSelectedCategory} />

      {isError && (
        <div className="h-full py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      {!isError &&
        (!newsCategories?.data.length || (selectedCategory && !selectedCategory.attributes.news.data.length)) && (
          <div className="h-full py-6 px-4 lg:pr-0">
            <div className="mx-auto max-w-xl text-center">
              <MessageAlert text={tNews('NO_CATEGORY_NEWS')} />
            </div>
          </div>
        )}
      <div className="mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}
          <div className="flex">
            <div className="w-max p-4">
              <Sidebar />
            </div>

            <div className="w-auto my-12">
              <div className="flex flex-col ml-4">
                {!selectedCategory && newsCategories && newsCategories.data.length > 0 && (
                  <div className="p-8">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {newsCategories.data.map((category) => {
                        const hasNews = category.attributes.news.data.length > 0;
                        return (
                          <div
                            key={category.id}
                            className={`${
                              hasNews ? 'bg-gray-100 hover:shadow-md cursor-pointer' : 'bg-gray-200 cursor-not-allowed'
                            } shadow-sm overflow-hidden rounded-full flex items-center justify-between p-4`}
                            onClick={() => hasNews && setSelectedCategory(category)}
                          >
                            <div className="flex items-center space-x-4 mr-16">
                              <h3 className="text-xl font-medium text-gray-500">{category.attributes.name}</h3>
                            </div>
                            <div
                              className={`flex items-center space-x-2 ${
                                hasNews ? 'bg-gray-400' : 'bg-gray-500'
                              } px-2 py-1 rounded-full text-xl font-semibold ml text-white`}
                            >
                              <span>{category.attributes.news.data.length}</span>
                            </div>
                            <ChevronRightIcon
                              className="-mr-1 hidden h-5 w-5 lg:inline text-gray-500"
                              aria-hidden="true"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selectedCategory && <NewsList categoryId={selectedCategory.id} />}

                <div className="p-8">
                  <IFrameBannerProfileBottom />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
