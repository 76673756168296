import { useSearchParams } from 'react-router-dom';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import { useEffect, useMemo } from 'react';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';

interface Props {
  onRedirect: (e: { isError?: boolean; isSuccess?: boolean }) => void;
}

const SofinnResetPasswordInAppFeedbackMessagePresentationalMessage: React.FC<Props> = (props) => {
  const { onRedirect } = props;
  const [searchParams] = useSearchParams();
  const errorMessage = useMemo(() => searchParams.get('errorMessage'), [searchParams]);
  const successMessage = useMemo(() => searchParams.get('successMessage'), [searchParams]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        onRedirect({ isSuccess: !!successMessage });
      }, 5000);
    }
  }, [successMessage]);

  return (
    <div className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]">
      <div className="flex flex-col">
        {errorMessage ? (
          <SofinnErrorBox errorMessage={errorMessage} className="text-left justify-start" />
        ) : (
          <div>
            <p>{successMessage}</p>
          </div>
        )}
        {errorMessage && (
          <SofinnButton
            text="Torna indietro"
            onClick={() => onRedirect({ isError: !!errorMessage })}
            className="px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75"
          />
        )}
      </div>
    </div>
  );
};

export default SofinnResetPasswordInAppFeedbackMessagePresentationalMessage;
