import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { DocumentsBox } from '../Documents';
import InputResult from './InputResult';
import PersonalDataBox from './PersonalDataBox';
import { IStrapiFile, IUser } from '../../../../lib/interfaces';
import { IFrameBannerProfileBottom } from '../../../../components/IFrameBanner/ProfileBottom';

interface IGeneralData {
  acceptedFiles: readonly string[];
  files: IStrapiFile[];
  user: IUser;
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: IStrapiFile) => void;
}

const GeneralData = (props: IGeneralData) => {
  const { t } = useTranslation('PROFILE');
  const { acceptedFiles = [], files, user: _user, loading, onFileChange, onFileDelete } = props;
  const user: IUser = { ..._user };
  for (const userKey in user) {
    const userValue = (user as any)[userKey];
    if ([null, ''].includes(userValue)) (user as any)[userKey] = '-';
  }
  const navigate = useNavigate();

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="relative h-full">
          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP1_TITLE')}>
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_NAME')} text={user.customer?.name || ''} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_VAT')} text={user.customer?.vat_num || ''} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_PHONE')} text={user.customer?.phone || ''} />
            <InputResult label="Fax" text={user.customer?.fax || ''} />
          </PersonalDataBox>

          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP2_TITLE')}>
            <InputResult label={t('ACTIVATION_REQUEST:STEP2_CODE')} text={user.customer.customer_id} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP2_BUSINESS_NAME')} text={user.customer?.name || ''} />
          </PersonalDataBox>

          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP3_TITLE')}>
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_ADDRESS')} text={user.customer?.address || ''} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_FRACTION')} text={user.customer?.district || ''} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_CAP')} text={user.customer?.zipcode || ''} />
            <InputResult label={t('ACTIVATION_REQUEST:STEP3_CITY')} text={user.customer?.city || ''} />
          </PersonalDataBox>

          <DocumentsBox
            acceptedFiles={acceptedFiles}
            files={files}
            title={t('ACTIVATION_REQUEST:STEP4_TITLE')}
            loading={loading}
            onFileChange={onFileChange}
            onFileDelete={onFileDelete}
          />

          <div className="flex justify-end">
            {/* <PrimaryButton text={t('EDIT_BUTTON')} onClick={() => navigate('/profile/edit')} /> */}
          </div>
          <IFrameBannerProfileBottom />
        </div>
      </div>
    </div>
  );
};

export default GeneralData;
