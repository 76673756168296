import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import MessageAlert from '../Alert/MessageAlert';
import { WarehouseTable } from '../ProductsTable';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface IWarehouseModalProps {
  otherWarehouses: {
    warehouse_name: string;
    warehouse_id: number;
    warehouse_code: string;
    quantity: number;
  }[];
}

const WarehouseModal = ({ otherWarehouses }: IWarehouseModalProps) => {
  const { t } = useTranslation('PRODUCTS');
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="text-xs text-gray-500 underline decoration-1 underline-offset-2 hover:text-gray-700 focus:outline-none"
      >
        {t('WAREHOUSE_BTN')}
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 sm:align-middle">
                <div className="flex flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="ml-4 inline-flex items-center rounded-full border border-transparent p-0.5 text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title as="h3" className="text-md font-bold leading-6 text-gray-800">
                  {t('WAREHOUSE_DISPONIBILITY_TITLE')}
                </Dialog.Title>

                <div className="mt-2">
                  <MessageAlert text={t('DELIVERY_MESSAGE')} />
                </div>

                <div className="mt-6">
                  <WarehouseTable otherWarehouses={otherWarehouses} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default WarehouseModal;
