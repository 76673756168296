import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useQueryParam, StringParam } from 'use-query-params';
import { classNames } from '../../lib/utils';

export const SORTING_OPTIONS = Object.freeze([
  { value: 'price_list:asc', name: 'SORT_PRICE_ASC' },
  { value: 'price_list:desc', name: 'SORT_PRICE_DESC' },
  // { value: 'sold:desc', name: 'SORT_MOST_SOLD' },
]);

const HorizontalFilter = () => {
  const [selected, setSelected] = useState(SORTING_OPTIONS[0]);
  const { t } = useTranslation('PRODUCTS');
  const [sort, setSort] = useQueryParam('sort', StringParam);

  useEffect(() => {
    if (!sort) return setSort(SORTING_OPTIONS[0].value);

    const sortingOption = SORTING_OPTIONS.find((option) => option.value === sort);
    if (!sortingOption) setSort(SORTING_OPTIONS[0].value);
    else setSelected(sortingOption);
  }, [sort]);

  return (
    <div className="my-4 flex justify-end">
      <div className="flex items-center">
        <Listbox
          value={selected}
          onChange={(selected: (typeof SORTING_OPTIONS)[number]) => {
            setSort(selected.value);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="mr-2 block text-sm font-light text-gray-400">{t('SORT_BY')}</Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-gray-100 py-2 pl-3 pr-10 text-left focus:outline-none sm:text-sm"
                  style={{ minWidth: '200px' }}
                >
                  <span className="block truncate">{t(selected.name)}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {SORTING_OPTIONS.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-red-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {t(option.name)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-red-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default HorizontalFilter;
