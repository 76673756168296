import { useTranslation } from 'react-i18next';
// import PersonalFilePrev from '../../../components/ProfileItems/PersonalFilePrev';
export { default as DocumentsBox } from './DocumentsBox';

const Documents = () => {
  const { t } = useTranslation('PROFILE');

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
          <h3 className="mb-6 text-2xl font-bold text-gray-800">{t('ALL_DOCUMENTS')}</h3>

          {/* <PersonalFilePrev
            url=""
            name="Titolo documento lorem ipsum"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Documents;
