import { FC } from 'react';

interface ICheckboxCustom {
  item: any;
  readOnly?: boolean;
  isChecked: boolean;
  onChange: (item: any, flag: boolean) => void;
  readonly?: boolean;
}

const CheckboxCustom: FC<ICheckboxCustom> = ({ item, onChange, isChecked, readOnly = false }) => {
  return (
    <input
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
      checked={isChecked}
      onChange={(e) => onChange(item, e.target.checked)}
      disabled={readOnly}
    />
  );
};

export default CheckboxCustom;
