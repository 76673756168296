import React from 'react';
import { IPromotion } from '../../../lib/fetch/products';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const PromotionHeader: React.FC<{ promotion: IPromotion }> = ({ promotion }) => {
  const { title = '', description = '', image = { url: '' }, validFrom = '', validTo = '' } = promotion;
  const { t } = useTranslation('PROMOTION_DETAIL');
  const { REACT_APP_MEDIA_LIBRARY_URL } = process.env;

  return (
    <div className="w-full px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start mb-2">
        <h1 className="text-4xl font-bold text-gray-900">{title}</h1>

        <div
          className="bg-gray-200 text-gray-600 text-sm px-4 py-2 rounded-full mt-2 sm:mt-0"
          dangerouslySetInnerHTML={{
            __html: t('PROMOTION_VALIDITY_PERIOD', {
              from: moment(validFrom).format('DD/MM/YYYY'),
              to: moment(validTo).format('DD/MM/YYYY'),
            }),
          }}
        />
      </div>

      {image.url && (
        <div className="w-full flex justify-center mb-6 mt-4">
          <img
            src={`${REACT_APP_MEDIA_LIBRARY_URL}${image.url}`}
            alt="Promotion"
            className="object-contain max-w-full h-auto"
          />
        </div>
      )}

      <p className="text-gray-700 text-lg leading-relaxed">{description}</p>
    </div>
  );
};

export default PromotionHeader;
