import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { AlertType, useAlert } from '../../providers/AlertProvider';

export { default as FixedAlert } from './FixedAlert';

const getAlertIcon = (type: AlertType) => {
  switch (type) {
    case AlertType.Info:
      return <InformationCircleIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />;
    case AlertType.Success:
      return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
    case AlertType.Error:
    default:
      return <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />;
  }
};

const Alert = () => {
  const { t } = useTranslation();
  const [alertState, dispatchAlertChange] = useAlert();
  const { open, message, type } = alertState;

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{getAlertIcon(type)}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{t(message)}</p>
                    {/*<p className="mt-1 text-sm text-gray-500">subtitle</p>*/}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() =>
                        dispatchAlertChange({
                          open: false,
                        })
                      }
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Alert;
