import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { register } from '../../lib/fetch/auth';
import { useAlert } from '../../providers/AlertProvider';
import ActivationRequestPresentational from './ActivationRequestPresentational';
import { IUser } from '../../lib/interfaces';

enum STRAPI_REGISTRATION_ERROR_MESSAGES {
  EmailAlreadyTaken = 'Email is already taken',
}

const ActivationRequest = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const navigate = useNavigate();

  const onRegister = async (user: IUser) => {
    setLoading(true);
    const { error } = await register({
      ...user,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      if (message === STRAPI_REGISTRATION_ERROR_MESSAGES.EmailAlreadyTaken) {
        message = t('STEP5_EMAIL_ALREADY_TAKEN');
      }
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    setLoading(false);
    navigate('/thank-you-registration');
  };

  return <ActivationRequestPresentational loading={loading} onRegister={onRegister} />;
};

export default ActivationRequest;
