export const ROUTES = {
  PROFILE: {
    RETURNS: '/profile/return-info',
    DOCUMENTS: '/profile/documents',
    COMMERCIAL_STATS: '/profile/commercial-stats',
  },
  ORDERS: {
    LIST: '/my-orders',
  },
  PROMOTIONS: {
    LIST: '/promotions',
    PROMO: '/promotion',
  },
  SEARCH: {
    PRODUCT_DETAIL: '/product-detail',
  },
  NEWS: {
    LIST: '/news',
  },
};
