import { FormProvider, useForm } from 'react-hook-form';
import ResetTemporaryPasswordPresentational from './ResetTemporaryPasswordPresentational';
import IResetTemporaryPasswordForm from './interfaces/resetPasswordInAppForm';
import { yupResolver } from '@hookform/resolvers/yup';
import resetTemporaryPasswordValidator from './validators/resetTemporaryPasswordValidator';
import { useMutation, useQuery } from 'react-query';
import resetTemporaryPassword from '../../../../lib/fetch/auth/resetTemporaryPassword';
import { AuthActionType, useAuth } from '../../../../providers/AuthProvider';
import { me } from '../../../../lib/fetch/auth';
import { useNavigate } from 'react-router-dom';
import { RedirectAction } from '../../../../lib/interfaces/IRedirect';

const ResetTemporaryPassword: React.FC = () => {
  const [{ token }, dispatchAuthChange] = useAuth();
  const navigate = useNavigate();

  //@ts-ignore
  const formMethods = useForm<IResetTemporaryPasswordForm>({ resolver: yupResolver(resetTemporaryPasswordValidator) });

  const { refetch: refetchMe } = useQuery('meQuery', () => me(String(token)), {
    enabled: false,
    onSuccess: (data) => {
      dispatchAuthChange({
        type: AuthActionType.SetUser,
        user: data.data,
      });

      navigate(`/redirect?action=${RedirectAction.TEMPORARY_PASSWORD_RESETED}`);
    },
    onError: () => {
      dispatchAuthChange({
        type: AuthActionType.Logout,
      });
    },
  });

  const { mutate, isLoading } = useMutation('resetTemporaryPasswordMutation', resetTemporaryPassword, {
    onSuccess: () => {
      refetchMe();
    },
  });

  const onSubmit = (values: IResetTemporaryPasswordForm) => {
    mutate({ token, resetData: values });
  };

  return (
    <FormProvider {...formMethods}>
      <ResetTemporaryPasswordPresentational onSubmit={onSubmit} isLoading={isLoading} />
    </FormProvider>
  );
};

export default ResetTemporaryPassword;
