import { Link } from 'react-router-dom';
import { SettingsActionType, useSettings } from '../../providers/SettingsProvider';
import LanguageDropdown from './LanguageDropdown';
import ProfileMenu from './ProfileMenu';
import SearchHeader from './SearchHeader';
import ShoppingCart from './ShoppingCart';
import CustomerImpersonationHeader from './CustomerImpersonationHeader';
import { useAuth } from '../../providers/AuthProvider';
import AgentHeader from './AgentHeader';
import AllFunctionsButton from './AllFunctionsButton';

const Header = () => {
  const [{ user }] = useAuth();
  const [{ language }, dispatchSettingsChange] = useSettings();
  return (user?.role?.type === 'agente' || user?.role?.type === 'rva') && !user?.customer ? (
    <AgentHeader />
  ) : (
    <div className="sticky top-0 z-10 bg-white">
      <header className="relative bg-white">
        <CustomerImpersonationHeader />
        <nav aria-label="Top" className="max-full mx-auto px-4 py-2 shadow-md lg:py-0">
          {/* 
            in questo div contenitore aggiungere queste classi "border-b border-gray-200" quando ci sarà la ricerca tramite categoria e veicolo

            NB: NON CANCELLARE COMMENTO, cancella elisa quando sistemerà il menu
          */}
          <div>
            <div className="flex flex-col items-center lg:h-16 lg:flex-row">
              {/* Logo */}
              <div className="ml-4 mt-4 lg:ml-0">
                <Link to="/">
                  <img
                    className="h-16 w-auto max-w-none	"
                    src="../images/cati-logo-red-resized.png"
                    alt="Cati Ecommerce"
                    title="Cati Ecommerce"
                  />
                </Link>
              </div>

              <div className="ml-auto mr-auto mt-3 flex items-center lg:mr-0 lg:mt-0">
                <ProfileMenu />

                <span className="ml-4 h-8 w-px bg-gray-200" aria-hidden="true" />

                <div className="ml-4 flow-root lg:ml-6">
                  <ShoppingCart />
                </div>

                <span className="ml-4 h-8 w-px bg-gray-200" aria-hidden="true" />

                <div>
                  <LanguageDropdown
                    selectedLanguage={language}
                    onChangeLanguage={(language) => {
                      dispatchSettingsChange({
                        type: SettingsActionType.SetLanguage,
                        language,
                      });
                    }}
                  />
                </div>

                <div>
                  <AllFunctionsButton />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center lg:h-16 lg:flex-row">
              {/* Profile and chart btn */}
              <div className="mx-auto ml-auto w-full">
                <SearchHeader />
              </div>
            </div>
          </div>
          {/* <div className="py-1.5">
            qui dentro inserire bottini per ricerca tramite categoria e tramite veicolo
            
            NB: NON CANCELLARE COMMENTO, cancella elisa quando sistemerà il menu
          </div> */}
        </nav>
      </header>
    </div>
  );
};

export default Header;
