import axios, { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { IProduct, IPromotion } from '../products';

const { REACT_APP_PROMO_PRODUCTS_URL } = process.env;

interface IGetPromoProductsParams {
  id: string;
  page: number;
  pageSize: number;
  sort: string;
}

interface IIGetPromoProductsResponse {
  data: { products: IProduct[]; promotion: IPromotion };
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

const getPromoProducts = async (token: string, params: IGetPromoProductsParams) => {
  const { id, page, pageSize, sort } = params;

  const query = `${QueryString.stringify({
    id,
    page,
    pageSize,
    sort,
  })}`;
  const response = await axios.get<IIGetPromoProductsResponse>(`${REACT_APP_PROMO_PRODUCTS_URL!}?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export default getPromoProducts;
