import PersonalFilePrev from './PersonalFilePrev';
import { IStrapiFile } from '../../../../lib/interfaces';
import { DragAndDrop } from '../../../../components/Form';

interface IDocumentsBox {
  title: string;
  acceptedFiles: readonly string[];
  files: IStrapiFile[];
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: IStrapiFile) => void;
}

const DocumentsBox = (props: IDocumentsBox) => {
  const {
    title,
    acceptedFiles = [],
    files = [],
    loading,
    onFileChange = (_: File[]) => {},
    onFileDelete = (_: IStrapiFile) => {},
  } = props;

  return (
    <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-900">{title}</h3>

      <div className="mb-6">
        <DragAndDrop acceptedFiles={acceptedFiles} onFileChange={onFileChange} />
      </div>

      {files.map((file, index) => (
        <PersonalFilePrev key={index} file={file} loading={loading} onFileDelete={onFileDelete} />
      ))}
    </div>
  );
};

export default DocumentsBox;
