import { useEffect, useState } from 'react';
import ProductsListPresentational from './ProductsListPresentational';
import { useAuth } from '../../providers/AuthProvider';
import { useAlert } from '../../providers/AlertProvider';
import { IQuote } from '../../lib/fetch/quotes';
import { useQuery } from 'react-query';
import { fetchQuotes, fetchQuoteTotalsByStatus } from '../../lib/fetch/quotes/list';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const { REACT_APP_DEFAULT_PAGE_SIZE } = process.env;

const ProductsList = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status') || undefined;

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_PAGE_SIZE! || 25);
  const [quotes, setQuotes] = useState<IQuote[]>([]);
  const [search, setSearch] = useState('');
  const [debounceSearch] = useDebounce(search, 500);
  const [totalsByStatus, setTotalsByStatus] = useState({
    archived: 0,
    pending: 0,
    completed: 0,
  });

  const { isLoading, isFetching } = useQuery(
    ['quotes', page, pageSize, status, debounceSearch],
    () => fetchQuotes(token!, { page, pageSize, sort: ['updatedAt:desc'], status, search: debounceSearch }),
    {
      onSuccess: (data) => {
        setQuotes(data.data);
        setTotal(data.meta?.pagination.total || 0);
        setPageCount(data.meta?.pagination.pageCount || 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      onError: () => {
        setQuotes([]);
        dispatchAlertChange({ open: true });
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setPage(1);
  }, [status]);

  useQuery('quoteCountsByStatus', () => fetchQuoteTotalsByStatus(token), {
    onSuccess: (data) => {
      setTotalsByStatus(data);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <ProductsListPresentational
      quotes={quotes}
      loading={isLoading || isFetching}
      total={total}
      totalsByStatus={totalsByStatus}
      setPage={setPage}
      page={page}
      pageSize={pageSize}
      setPageSize={setPageSize}
      pageCount={pageCount}
      setSearch={setSearch}
    />
  );
};

export default ProductsList;
