import { useQuery, useMutation, useQueryClient } from 'react-query';
import ProductsListDetailPresentational from './ProductsListDetailPresentational';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import fetchQuoteById from '../../lib/fetch/quotes/getOne';
import { useEffect, useState } from 'react';
import { IQuoteAttributes, IQuoteItem } from '../../lib/fetch/quotes';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import updateQuote from '../../lib/fetch/quotes/update';
import deleteQuote from '../../lib/fetch/quotes/delete';
import createQuote from '../../lib/fetch/quotes/create';
import { yupResolver } from '@hookform/resolvers/yup';
import quoteFormValidator from './validator';
import { search as callSearchProducts } from '../../lib/fetch/products';
import { QuoteStatus, WhereToSearch } from '../../lib/enums';
import { SORTING_OPTIONS } from '../../components/ProductsTable/HorizontalFilter';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { transformItems } from '../../lib/utils/productListDetail';

const ProductsListDetail = () => {
  const [{ token }] = useAuth();
  const navigate = useNavigate();
  const [, dispatchAlertChange] = useAlert();
  const { id } = useParams();
  const lang = i18n.languages[0];
  const { t } = useTranslation('SHOPPING_CART');
  const { t: tQuotes } = useTranslation('QUOTE');

  const [loadingSearch, setLoadingSearch] = useState(false);

  const queryClient = useQueryClient();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [addToCartModalOpen, setAddToCartModalOpen] = useState(false);
  const [restoreListFromArchiveModalOpen, setRestoreListFromArchiveModalOpen] = useState(false);

  const toggleArchiveModal = () => setArchiveModalOpen((v) => !v);
  const toggleRestoreFromArchiveModal = () => setRestoreListFromArchiveModalOpen((v) => !v);
  const toggleAddToCartModalOpen = () => setAddToCartModalOpen((v) => !v);
  const toggleDeleteModalOpen = () => setDeleteModalOpen((v) => !v);

  const location = useLocation();
  const isCreatePage = location.pathname === '/products-list/create';

  const [defaultValues, setDefaultValues] = useState<FieldValues>({
    name: '',
    description: '',
    items: [],
    status: QuoteStatus.Pending,
  });

  const { isLoading, isFetching } = useQuery(['quote', id], () => fetchQuoteById(token!, Number(id)), {
    enabled: !!id,
    onSuccess: (data) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setDefaultValues({
        name: data.data.attributes.name,
        description: data.data.attributes.description,
        items: data.data.attributes.items,
        status: data.data.attributes.status,
      });
    },
    onError: () => {
      navigate('/products-list');
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues]);

  const { mutate: deleteItem } = useMutation(() => deleteQuote(token!, id!), {
    onSuccess: () => {
      dispatchAlertChange({ type: AlertType.Success, open: true, message: tQuotes('QUOTE_DELETED_SUCCESSFULLY') });
      navigate('/products-list');
    },
  });

  const formMethods = useForm({
    defaultValues: defaultValues,
    //@ts-ignore
    resolver: yupResolver(quoteFormValidator),
  });

  const items = formMethods.watch('items');

  const { fields, append, update } = useFieldArray({
    control: formMethods.control,
    name: 'items',
  });

  const createQuoteMutation = useMutation((data: IQuoteAttributes) => createQuote(token!, data), {
    onSuccess: () => {
      dispatchAlertChange({ type: AlertType.Success, open: true, message: tQuotes('QUOTE_CREATED_SUCCESSFULLY') });
      navigate('/products-list');
    },
    onError: () => {
      dispatchAlertChange({ type: AlertType.Error, open: true, message: tQuotes('ERROR_DURING_UPDATE_QUOTE') });
    },
  });

  const { mutate: updateMutation } = useMutation((data: IQuoteAttributes) => updateQuote(token!, id!, data), {
    onSuccess: () => {
      dispatchAlertChange({ type: AlertType.Success, open: true, message: tQuotes('QUOTE_UPDATED_SUCCESSFULLY') });
      formMethods.reset(formMethods.getValues());
      resetModals();
      queryClient.invalidateQueries(['quote', id]);
    },
    onError: () => {
      resetModals();
      dispatchAlertChange({ type: AlertType.Error, open: true, message: tQuotes('ERROR_DURING_UPDATE_QUOTE') });
    },
  });

  const resetModals = () => {
    setAddToCartModalOpen(false);
    setDeleteModalOpen(false);
    setArchiveModalOpen(false);
    setRestoreListFromArchiveModalOpen(false);
  };

  const loadingSave = createQuoteMutation.isLoading;

  const onSubmit = (data: FieldValues) => {
    const newData = {
      ...data,
      items: transformItems(data.items),
    } as IQuoteAttributes;

    if (isCreatePage) {
      createQuoteMutation.mutate(newData);
    } else {
      updateMutation(newData);
    }
  };

  const onAddToList = async (search: string) => {
    setLoadingSearch(true);

    if (!search) {
      setLoadingSearch(false);
      return;
    }

    try {
      const { data: axiosData, error } = await callSearchProducts(token!, {
        page: 1,
        pageSize: 2,
        search,
        sort: SORTING_OPTIONS[0].value,
        where: WhereToSearch.Code,
        lang,
      });

      if (error || !axiosData) {
        dispatchAlertChange({ open: true });
        return;
      }

      const { data = [] } = axiosData;

      if (!data.length) {
        dispatchAlertChange({
          open: true,
          type: AlertType.Info,
          message: t('PRODUCTS:NO_RESULT_FOR_CODE'),
        });
        return;
      }

      if (data.length > 1) {
        dispatchAlertChange({
          open: true,
          type: AlertType.Info,
          message: t('PRODUCTS:ADD_TO_CART_MULTIPLE_ERROR'),
        });
        return;
      }

      const [searchProduct] = data;

      // Cerca se il prodotto è già in `items`
      const existingIndex = items.findIndex((item: IQuoteItem) => item.product.data.id === searchProduct.id);

      if (existingIndex !== -1) {
        const updatedQuantity = items[existingIndex].quantity + 1;

        update(existingIndex, {
          ...fields[existingIndex],
          quantity: updatedQuantity,
        });
      } else {
        append({ id: searchProduct.id, quantity: 1, product: { data: searchProduct } });
      }
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <ProductsListDetailPresentational
        loading={isLoading || isFetching}
        onSubmit={onSubmit}
        deleteQuote={deleteItem}
        editQuote={updateMutation}
        isCreatePage={isCreatePage}
        onAddToList={onAddToList}
        loadingSearch={loadingSearch}
        loadingSave={loadingSave}
        toggleAddToCartModalOpen={toggleAddToCartModalOpen}
        toggleArchiveModal={toggleArchiveModal}
        toggleDeleteModalOpen={toggleDeleteModalOpen}
        toggleRestoreFromArchiveModal={toggleRestoreFromArchiveModal}
        archiveModalOpen={archiveModalOpen}
        addToCartModalOpen={addToCartModalOpen}
        deleteModalOpen={deleteModalOpen}
        restoreListFromArchiveModalOpen={restoreListFromArchiveModalOpen}
      />
    </FormProvider>
  );
};

export default ProductsListDetail;
