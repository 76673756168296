import axios from 'axios';

const { REACT_APP_PROMOS_URL } = process.env;

const fetchPromotions = async (token: string | null) => {
  return await axios.get(`${REACT_APP_PROMOS_URL!}`, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export default fetchPromotions;
