import axios from 'axios';
import { IQuoteAttributes } from '.';
import { StrapiSingleEntityResponse } from '../../interfaces/ICommons';
const axiosInstance = axios.create();

const { REACT_APP_BACKEND_URL } = process.env;

const fetchQuoteById = async (token: string, id: number) => {
  const response = await axiosInstance.get<StrapiSingleEntityResponse<IQuoteAttributes>>(
    `${REACT_APP_BACKEND_URL!}/quotes/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        'populate[items][populate][product]': true,
      },
    },
  );

  return response.data;
};

export default fetchQuoteById;
